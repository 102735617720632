import { Collection } from 'fireorm';

export const ROLE_MEMBER = 'Member';
export const ROLE_COMPANY_ADMIN = 'Company Admin';

export const MEMBERSHIP_TYPE_INDIVIDUAL = 'Individual';
export const MEMBERSHIP_TYPE_PARTNER = 'Partner';

export const MEMBERSHIP_TERM_YEAR = 'Yearly';

export const MEMBERSHIP_STATUS_INVITED = '0 - Invited';
export const MEMBERSHIP_STATUS_APP_INCOMPLETE = '1 - Application Incomplete';
export const MEMBERSHIP_STATUS_APP_SUBMITTED = '2 - Application Submitted';
export const MEMBERSHIP_STATUS_ACTIVE = '3 - Active';
export const MEMBERSHIP_STATUS_APP_DENIED = '4 - Denied';
export const MEMBERSHIP_STATUS_ENDED = '5 - Churned';
export const MEMBERSHIP_STATUS_EXISTING_MEMBER = '6 - Existing Member Signup';

export const MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID = 'Individual Paid';
export const MEMBERSHIP_SUBTYPE_INDIVIDUAL_FREE = 'Individual Free';
export const MEMBERSHIP_SUBTYPE_PARTNER_PAID = 'Partner Paid';

export const USER_FIELDS = [
  'first_name',
  'last_name',
  'current_company',
  'current_title',
  'intro',
  'linkedin_url',
  'photo_url',
  'cohort_list',
  'current_location',
  'membership_status',
  'membership_term',
  'membership_type',
  'membership_subtype',
  'membership_startdate',
  'membership_enddate',
  'stripe_customer_id',
  'company_id',
  'company_account_name',
  'completed_product_tour',
  'completed_admin_product_tour',
  'reason_for_joining_wise',
  'how_did_you_hear',
  'membership_upgrade_date',
];

@Collection('users')
export class UserModel {
  id: string;
  first_name: string;
  last_name: string;
  current_company: string;
  current_title: string;
  current_location: string;
  email: string;
  linkedin_url: string;
  cohort_list: Array<string>;
  completed_admin_product_tour: Boolean;
  completed_product_tour: Boolean;
  growsurf_id: string;
  intro: string;
  photo_url: string;
  first_login_time: Date;
  last_login_time: Date;
  membership_status: string;
  membership_type: string;
  membership_subtype : string;

  //fields for individual memberships
  membership_startdate: Date;
  membership_enddate: Date;
  membership_upgrade_date: Date;
  reason_for_joining_wise: string;
  how_did_you_hear: string;
  membership_payment_term: string;
  cb_customer_id: string;
  cb_subscription_status: string;

  //deprecated fields after migrating to ChargeBee
  stripe_customer_id: string;
  stripe_subscription_status: string;

  //fields for company users
  company_id: string;
  company_account_name: string;
  company_licenses: Array<string>;
}

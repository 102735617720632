const INITIAL_STATE = {
  authUser: null,
  adminUser: null,
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
});

const applySetAdminUser = (state, action) => ({
  ...state,
  adminUser: action.adminUser,
});

const applyUpdateAuthUser = (state, action) => ({
  ...state,
  authUser: {
    ...state.authUser,
    ...action.user,
  },
});

function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AUTH_USER_SET': {
      return applySetAuthUser(state, action);
    }
    case 'ADMIN_USER_SET': {
      return applySetAdminUser(state, action);
    }
    case 'UPDATE_AUTH_USER': {
      return applyUpdateAuthUser(state, action);
    }
    default:
      return state;
  }
}

export default sessionReducer;

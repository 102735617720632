import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Widget } from '@uploadcare/react-widget';
import { toast } from 'react-toastify';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { ACCOUNT_TYPE_TEAM, ACCOUNT_TYPE_CORPORATE } from 'models/Company';
import { LoaderIcon } from 'utils/Widgets';

import '../../Account/uploadCareStyles.css';
import ReactTooltip from 'react-tooltip';

function CompanyImagesLoader({ firebase }) {
  const company = useSelector((state) => state.companyState.company);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();
  const widgetApi = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [usedCount, setUsedCount] = useState(company.images_used_count);
  const [maxCount, setMaxCount] = useState(company.images_max_count);

  useEffect(() => {
    if (!company.images_used_count) {
      setUsedCount(0);
    } else {
      setUsedCount(company.images_used_count);
    }

    if (!company.images_max_count) {
      if (company.account_type === ACCOUNT_TYPE_TEAM) setMaxCount(8);
      else if (company.account_type === ACCOUNT_TYPE_CORPORATE) setMaxCount(12);
    }
  }, [company]);

  const openDialog = () => {
    if (widgetApi.current) {
      const dialog = widgetApi.current.openDialog();
      dialog.switchTab("file");
    }
  };

  const onFileSelect = async (file) => {

    if (file) {
      file.progress((info) => {
        setIsLoading(true);
      });

      file.done((info) => {
        fetch(info.cdnUrl).then((res) => res.blob()).then((blob) => {
          // uploading blob to firebase storage
          firebase.storage.ref().child(`companies/${authUser.company_id}/images/${authUser.company_id}_${info.uuid}`).put(blob).then((snapshot) => snapshot.ref.getDownloadURL())
            .then(async (url) => {
              firebase.updateCompanyImages(authUser.company_id, url).then(async () => {
                const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
                dispatch({ type: 'SET_COMPANY', company: companyData });
                // console.log("ADDED ON REDUX", companyData);
                setIsLoading(false);
                toast.success('Success', {
                  autoClose: 5000,
                  className: 'text-sm',
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
            });
        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
      });
    }
  };

  const deleteImage = (image) => {
    firebase.deleteCompanyImage(authUser.company_id, image).then(async () => {
      const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
      dispatch({ type: 'SET_COMPANY', company: companyData });
      toast.success('Success', {
        autoClose: 5000,
        className: 'text-sm',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <div className="mt-5">

      <div className="my-6">
        <button
          disabled={usedCount >= maxCount}
          type="submit"
          className="px-4 py-2 btn"
          onClick={openDialog}
        >
          <div
            className="inline-flex items-center"
            data-tip="You are using the maximum of images allowed"
            data-for="addImage"
          >
            {isLoading ? (<LoaderIcon />) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            )}
            Upload Images
          </div>
        </button>
      </div>
      {usedCount >= maxCount && (
        <ReactTooltip
          id="addImage"
          place="top"
          backgroundColor="gray"
          effect="solid"
          multiline
        />
      )}

      {company.images && company.images.length > 0 && (
      <div className="bg-white shadow sm:rounded-lg gap-x-6 gap-y-4 mb-2 px-5 py-5">
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {company.images.map((image) => (
            <li key={image} className="relative">
              <div className="group block w-full aspect-w-5 aspect-h-5 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <span className="absolute top-0 right-0 hover:text-white cursor-pointer" onClick={() => deleteImage(image)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-300 hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                <div className="h-50 w-full object-cover rounded pointer-events-none">
                  <img src={image} alt="" className="h-full w-full object-cover" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      )}

      <Widget
        publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
        id="imageUpload"
        crop="4:3"
        ref={widgetApi}
        tabs="file url gdrive dropbox instagram "
        imagesOnly
        previewStep
        effects="crop"
        onFileSelect={onFileSelect}
        preloader={null}
      />
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(CompanyImagesLoader);

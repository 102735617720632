import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import * as fireorm from 'fireorm';

import * as USER from 'models/User';
import * as EVENT from 'models/Event';
import { CompanyModel } from 'models/Company';
import { UserModel } from 'models/User';

const config = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    firebase.analytics();

    /* Helper */
    // this.serverValue = firebase.database.ServerValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = firebase.auth();
    // this.db = firebase.database();
    this.firestore = firebase.firestore();
    fireorm.initialize(this.firestore);
    this.storage = firebase.storage();

    this.CompanyCollection = fireorm.getRepository(CompanyModel);
    this.UserCollection = fireorm.getRepository(UserModel);

    /* Social Sign In Method Provider */
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  doSignInWithEmailAndPassword = (email, password, remember_me) => {
    return this.auth
      .setPersistence(remember_me ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return this.auth.signInWithEmailAndPassword(email, password);
      });
  };

  doSignInWithCustomToken = (uid) => {
    return this.auth.signInWithCustomToken(uid);
  };

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  currentUser = () => this.auth.currentUser;

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  doEmailUpdate = (email) => this.auth.currentUser.updateEmail(email);

  doFetchSignInMethodsForEmail = (email) => this.auth.fetchSignInMethodsForEmail(email);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              socialAccountPhotoURL: authUser.photoURL,
              ...dbUser,
            };

            //remove null value in cohort_list
            if (authUser.cohort_list?.length && authUser.cohort_list[0] === null) {
              authUser.cohort_list = [];
            }

            console.log('user authenticated', authUser);
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // User API
  user = (uid) => this.firestore.collection('users').doc(uid);

  // deleteUser = (uid) => this.firestore.collection('users').doc(uid).delete();

  getUserByEmail = (email) => {
    return this.firestore
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        if (snapshot.docs[0]) {
          return Object.assign(snapshot.docs[0].data(), { id: snapshot.docs[0].id });
        }
      });
  };

  updateUser = (user) => {
    //WARNING: not all inputs can be stored correctly because of this processing
    //inputs that calls functions on server side like server timestamp will get converted to string unless we handle it here    
    //remove null, undefined values and other keys
    let userData = JSON.parse(JSON.stringify(user));
    Object.keys(userData).forEach((k) => userData[k] == null && delete userData[k]);
    Object.keys(userData).forEach((k) => !USER.USER_FIELDS.includes(k) && delete userData[k]);

    //handle server timestamp fields 
    if (user.membership_startdate) {
      userData.membership_startdate = user.membership_startdate;
    }
    if (user.membership_upgrade_date) {
      userData.membership_upgrade_date = user.membership_upgrade_date;
    }    
    return this.firestore.collection('users').doc(user.uid).update(userData);
  };

  //WORK IN PROGRESS, NOT YET IN USE
  //Create user record after user authenticate. First check for placeholder user records
  //   createNewUser = (uid, email) => {
  //     return this.firestore.collection('users').where('email', '==', email).get()
  //       .then(snapshot => {
  //         let userData = {};
  //         if (snapshot.docs[0]) {
  //           userData = snapshot.docs[0].data();
  //           userData.membership_status = USER.MEMBERSHIP_STATUS_ACTIVE;
  //           // console.log("create new user from placeholder", userData);
  //           this.firestore.collection('users').doc(snapshot.docs[0].id).delete();
  //           trackUserEvent(this, uid, "existing_user_completed_onboard_form");
  //         } else {
  //           userData = {
  //             membership_status: USER.MEMBERSHIP_STATUS_APP_INCOMPLETE,
  //             membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
  //           }
  //           // console.log("create new user");
  //         }
  //
  //         return this.user(uid).set({
  //           email,
  //           first_login_time: this.currentTime(),
  //           last_login_time: this.currentTime(),
  //           ...userData,
  //         });
  //       });
  //   }

  // Event API
  events = () => this.firestore.collection('events');

  event = (uid) => this.firestore.collection('events').doc(uid);

  getEventUsers = (uids) => {
    return new Promise((res => {
        if (!uids || !uids.length) return res([])
        let batches = [];
        while (uids.length){
          const batch = uids.splice(0,10);
          batches.push(
            new Promise((response)=>{
              this.firestore.collection('users')
                .where(
                  firebase.firestore.FieldPath.documentId(),
                  'in',
                  [...batch]
                ).get().then((results)=> response(results.docs.map((result)=>({...result.data()}) )))
            })
          )
        }
        Promise.all(batches).then(content => {
          res(content.flat());
        })
      })
    )
  }

  registerEvent = (authUser, event_id) =>
    this.firestore
      .collection('events')
      .doc(event_id)
      .update({
        [`registration.${authUser.uid}.status`]: EVENT.EVENT_USER_REGISTERED,
        [`registration.${authUser.uid}.register_time`]: firebase.firestore.FieldValue.serverTimestamp(),
        [`registration.${authUser.uid}.confirmation_sent_time`]: null,
        [`registration.${authUser.uid}.cancel_time`]: null,
        users_registered: firebase.firestore.FieldValue.arrayUnion(authUser.uid),
      });

  unregisterEvent = (authUser, event_id) =>
    this.firestore
      .collection('events')
      .doc(event_id)
      .update({
        [`registration.${authUser.uid}.status`]: EVENT.EVENT_USER_CANCELLED,
        [`registration.${authUser.uid}.cancel_time`]: firebase.firestore.FieldValue.serverTimestamp(),
        users_registered: firebase.firestore.FieldValue.arrayRemove(authUser.uid),
      });

  currentTime = () => firebase.firestore.FieldValue.serverTimestamp();

  // Company API
  company = (companyId) => this.firestore.collection('companies').doc(companyId);

  companyUsers = (companyId) =>{
    return this.firestore
      .collection('users')
      .where('company_id', '==', companyId)
      .where('membership_type', '==', USER.MEMBERSHIP_TYPE_PARTNER)
      .orderBy('email')
      .get().then((querySnapshot)=>{
        return querySnapshot.docs.map((doc) => Object.assign(doc.data(), { id: doc.id }));
      })
  };

  updateCompany = (uid, companyData) => this.firestore.collection('companies').doc(uid).update(companyData);

  getOrCreateCompany = (data) => {
    return this.firestore
      .collection('companies')
      .where('applicant_email', '==', data.applicant_email)
      .get()
      .then((snapshot) => {
        if (snapshot.docs[0]) {
          return Object.assign(snapshot.docs[0].data(), { id: snapshot.docs[0].id });
        } else {
          //Don't need to return the full obj here
          return this.firestore.collection('companies').add(data);
        }
      });
  };

  updateCompanyImages = (company_id, images) => {
    return this.firestore
      .collection('companies')
      .doc(company_id)
      .update({
        images: firebase.firestore.FieldValue.arrayUnion(images),
        images_used_count:firebase.firestore.FieldValue.increment(1)
      });
  }

  deleteCompanyImage = (company_id, image) =>
    this.firestore
      .collection('companies')
      .doc(company_id)
      .update({
        images: firebase.firestore.FieldValue.arrayRemove(image),
        images_used_count:firebase.firestore.FieldValue.increment(-1)
      }).then(()=>{
      this.storage.refFromURL(image).delete()
    })
}


export default Firebase;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import request from 'request';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Widget } from '@uploadcare/react-widget';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { TestimonialModel } from 'models/Company';
import { LoaderIcon } from 'utils/Widgets';

function Testimonial({ firebase, testimonial, setShowForm }) {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();
  const widgetApi = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [count, setCount] = useState({ testimonial_text: null });

  if (!testimonial) {
    testimonial = [];
  }

  useEffect(() => {
    if (testimonial) {
      setImageUrl(testimonial.testimonial_image);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const companySchema = yup.object().shape({
    executive_full_name: yup.string().trim().required('This field is required'),
    testimonial_title: yup.string().trim().required('This field is required'),
    executive_linkedin_url: yup
      .string()
      .trim()
      .required('This field is required')
      .matches(/(linkedin.com\/in)/, 'Please enter your Linkedin URL'),
    testimonial_text: yup.string().trim().required('This field is required'),
    // .max(50, 'Keyword must not exceed 50 characters'),
    testimonial_image: yup.string().required('Please add a headshot for the testimonial'),
  });

  const {
    register, handleSubmit, errors,
  } = useForm({
    defaultValues: {
      executive_full_name: testimonial.executive_full_name,
      testimonial_title: testimonial.testimonial_title,
      executive_linkedin_url: testimonial.executive_linkedin_url,
      testimonial_text: testimonial.testimonial_text,
      testimonial_image: testimonial.testimonial_image,
    },
    resolver: yupResolver(companySchema),
  });

  const openDialog = () => {
    if (widgetApi.current) {
      widgetApi.current.openDialog();
    }
  };

  const onFileSelect = (file) => {
    if (file) {
      file.progress((info) => {
        setIsLoadingImage(true);
      });

      file.done((info) => {
        fetch(info.cdnUrl).then((res) => res.blob()).then((blob) => {
          firebase.storage.ref().child(`companies/${authUser.company_id}/testimonials/${authUser.company_id}_${info.uuid}`).put(blob).then((snapshot) => snapshot.ref.getDownloadURL())
            .then(async (url) => {
              setImageUrl(url);
              setIsLoadingImage(false);
            });
        }).catch((error) => {
          console.error(error);
          setIsLoadingImage(false);
        });
      });
    }
  };

  const handleInputChange = (event) => {
    setCount({
      ...count,
      [event.target.name]: event.target.value.length,
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const testimonialModel = new TestimonialModel();

    testimonialModel.creator_id = authUser.uid;
    testimonialModel.executive_full_name = data.executive_full_name;
    testimonialModel.testimonial_title = data.testimonial_title;
    testimonialModel.executive_linkedin_url = data.executive_linkedin_url;
    testimonialModel.testimonial_text = data.testimonial_text;
    testimonialModel.testimonial_image = data.testimonial_image;

    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyTestimonial`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          testimonial: testimonialModel,
          action: 'ADD',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.success('The testimonial has been created.', {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
          if (setShowForm) {
            setShowForm(false);
          }
        }
      },
    );
  };

  const onUpdate = async (data) => {
    setIsLoading(true);
    const testimonialModel = new TestimonialModel();

    testimonialModel.id = testimonial.id;
    testimonialModel.creator_id = authUser.uid;
    testimonialModel.executive_full_name = data.executive_full_name;
    testimonialModel.testimonial_title = data.testimonial_title;
    testimonialModel.executive_linkedin_url = data.executive_linkedin_url;
    testimonialModel.testimonial_text = data.testimonial_text;
    testimonialModel.testimonial_image = data.testimonial_image;

    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyTestimonial`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          testimonial: testimonialModel,
          action: 'UPDATE',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.success('The testimonial has been updated.', {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
          if (setShowForm) {
            setShowForm(false);
          }
        }
      },
    );
  };

  const onDelete = async (testimonialModel) => {
    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyTestimonial`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          testimonial: testimonialModel,
          action: 'DELETE',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          const message = 'The testimonial has been deleted.';
          toast.success(message, {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
        }
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white rounded-lg grid lg:grid-cols-12 gap-x-6 gap-y-4 px-5 py-5 border-2 my-1">

          <div className="sm:col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2 text-center">
            <div className="grid grid-cols-1">
              <div className="overflow-hidden h-full w-full grid-cols-1 mx-auto my-2">
                { imageUrl ? (
                  <img className="h-20 w-20 mx-auto rounded-full" src={imageUrl} alt="" />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-gray-300 mx-auto rounded-full" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              <div className="col-span-12 ">
                <button
                  className=" w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                  onClick={openDialog}
                >
                  {isLoadingImage && (<LoaderIcon dark />)}
                  Upload Photo
                </button>
                <input name="testimonial_image" ref={register} className="form-input " defaultValue={imageUrl} type="hidden" />
              </div>
            </div>
            {!imageUrl && (
              <div>
                {' '}
                {errors.testimonial_image?.message && (<p className="mt-2 text-sm text-red-700 col-span-12">{errors.testimonial_image?.message}</p>)}
              </div>
            )}
          </div>

          <div className="sm:col-span-12 md:col-span-12 lg:col-span-10 xl:col-span-10">
            <div className="grid grid-cols-12 sm:col-span-12 gap-x-6">

              <div className="col-span-12 md:col-span-12 lg:col-span-6 lg:col-start-1 md:col-start-1 mb-2">
                <label htmlFor="executive_full_name" className="form-text">Full Name</label>
                <input type="text" name="executive_full_name" ref={register} className="form-input" defaultValue={testimonial.executive_full_name} />
                {errors.executive_full_name?.message && (<p className="mt-2 text-sm text-red-700">{errors.executive_full_name?.message}</p>)}
              </div>

              <div className="col-span-12 md:col-span-12 lg:col-span-6 mb-2">
                <label htmlFor="testimonial_title" className="form-text">Title</label>
                <input type="text" name="testimonial_title" ref={register} className="form-input" defaultValue={testimonial.testimonial_title} />
                {errors.testimonial_title?.message && (<p className="mt-2 text-sm text-red-700">{errors.testimonial_title?.message}</p>)}
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
                <label htmlFor="executive_linkedin_url" className="form-text">LinkedIn URL</label>
                <input type="text" name="executive_linkedin_url" ref={register} className="form-input" defaultValue={testimonial.executive_linkedin_url} />
                {errors.executive_linkedin_url?.message && (<p className="mt-2 text-sm text-red-700">{errors.executive_linkedin_url?.message}</p>)}
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 mt-2">
                <label htmlFor="testimonial_text" className="form-text">
                  Testimonial Text
                </label>
                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
                  <textarea
                    name="testimonial_text"
                    rows="2"
                    defaultValue={testimonial.testimonial_text}
                    onChange={(e) => handleInputChange(e)}
                    ref={register}
                    className="form-input"
                    placeholder="I’m so excited to support WISE and elevate female sales leaders. I love what the organization  stands for and am happy to support the next generation of female sales leaders!"
                  />
                  <div className="grid grid-cols-2">
                    <p className="mt-2 text-sm text-red-700 col-span-1">
                      {errors.testimonial_text?.message && (errors.testimonial_text?.message)}
                    </p>
                    {/*                    <p className="mt-2 text-sm text-gray-500 text-right col-span-1">
                      {count.testimonial_text && (`Characters Left: ${50 - count.testimonial_text}`)}
                    </p> */}
                  </div>

                </div>
              </div>
            </div>
            <div className="pt-2 flex">
              {testimonial.id ? (
                <div className="inline-flex">
                  <button
                    onClick={handleSubmit(onUpdate)}
                    className="bg-primary inline border border-transparent rounded-md shadow-sm py-2 px-5 my-2 mr-2 text-sm leading-4 font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {isLoading && (<LoaderIcon />)}
                    Save
                  </button>
                  <button
                    type="button"
                    className=" bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 my-2 mx-2 text-md leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      if (window.confirm('Delete the selected testimonial?')) {
                        onDelete(testimonial);
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>

              )
                : (
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className="mt-2 mx-1 w-100 btn flex-3"
                  >
                    {isLoading && (<LoaderIcon />)}
                    Save
                  </button>
                )}

            </div>
          </div>

        </div>
      </form>
      <Widget
        publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
        id="imageUpload"
        ref={widgetApi}
        tabs="file url gdrive dropbox facebook instagram "
        crop="1:1"
        imagesOnly
        multipleMaxStrict
        multipleMax={1}
        previewStep
        effects="crop"
        onFileSelect={onFileSelect}
        preloader={null}
      />
    </>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(Testimonial);

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Transition } from '@headlessui/react';

import * as ROUTES from 'constants/routes';
import { hasAdminRole, hasMemberRole, isFreemiumIndividual } from 'components/Session/withAuthorization';
import Logo from 'assets/wise-dark-transparent.png';
import ReactTooltip from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const SidebarNav = ({ authUser, isMobileSidebarOn, toggleMobileSidebar }) => (authUser ? (
  <SidebarNavAuth
    authUser={authUser}
    isMobileSidebarOn={isMobileSidebarOn}
    toggleMobileSidebar={toggleMobileSidebar}
  />
) : null);

const iconHome = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
    />
  </svg>
);

const iconJobs = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
);

const iconPods = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
    />
  </svg>
);

const iconMentor = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
);

const iconEvent = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const iconSlack = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
    />
  </svg>
);

const iconManageMembers = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);
const iconManageCompany = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
    />
  </svg>
);

const iconResources = (
  <svg
    className="mr-4 h-6 w-6 text-indigo-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
    />
  </svg>
);

const SidebarNavAuth = ({ authUser, isMobileSidebarOn, toggleMobileSidebar }) => {
  const toggleSidebar = () => {
    toggleMobileSidebar(!isMobileSidebarOn);
  };

  return (
    <div className="bg-primary-dark md:flex md:flex-shrink-0">
      {isMobileSidebarOn && (
        <div className="md:hidden">
          <div className="fixed inset-0 flex z-40">
            <Transition
              show={isMobileSidebarOn}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-600 opacity-75" />
              </div>
            </Transition>

            <Transition
              show={isMobileSidebarOn}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {(ref) => (
                <div ref={ref} className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-dark">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      onClick={toggleSidebar}
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span className="sr-only">Close sidebar</span>
                      <svg
                        className="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4">
                      <Link to={ROUTES.HOME}>
                        <img className="h-8 w-auto" src={Logo} alt="WISE" />
                      </Link>
                    </div>
                    <nav className="mt-5 flex-1 px-2 space-y-1">
                      {hasAdminRole(authUser) && (
                        <h3
                          className="pt-4 px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider"
                          data-tut="reactour_m__companyHeader"
                        >
                          Company
                        </h3>
                      )}

                      {hasAdminRole(authUser) && (
                        <NavLink
                          to={ROUTES.COMPANY_MANAGE_TEAM}
                          className="navlink"
                          activeClassName="navlink-active"
                          data-tut="reactour_m__manageTeam"
                        >
                          {iconManageMembers}
                          {' '}
                          Members
                        </NavLink>
                      )}

                      {hasAdminRole(authUser) && (
                        <NavLink
                          to={ROUTES.COMPANY_MANAGE_PROFILE}
                          className="navlink"
                          activeClassName="navlink-active"
                          data-tut="reactour_m__manageCompany"
                        >
                          {iconManageCompany}
                          {' '}
                          Profile
                        </NavLink>
                      )}

                      {hasAdminRole(authUser) && (
                        <NavLink
                          to={ROUTES.COMPANY_RESOURCES}
                          className="navlink"
                          activeClassName="navlink-active"
                          data-tut="reactour_m__companyresources"
                        >
                          {iconResources}
                          {' '}
                          Resources
                        </NavLink>
                      )}

                      {hasAdminRole(authUser) && (
                        <h3
                          className="pt-4 px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider"
                          data-tut="reactour_m__member"
                        >
                          Member
                        </h3>
                      )}

                      <NavLink to={ROUTES.MEMBER_WELCOME} className="navlink" activeClassName="navlink-active">
                        {iconHome}
                        {' '}
                        Welcome
                      </NavLink>

                      <div data-tut="reactour_m__paid">
                        {hasMemberRole(authUser) ? (
                          <NavLink
                            to={ROUTES.PODS}
                            className="navlink"
                            activeClassName="navlink-active"
                            data-tut="reactour_m__pods"
                          >
                            {iconPods}
                            {' '}
                            Networking
                            {isFreemiumIndividual(authUser) && (
                            <FontAwesomeIcon icon={faLock} className="ml-2 h-4 w-4" color="gray" />
                            )}
                          </NavLink>
                        ) : (
                          <div
                            className="navlink"
                            data-tut="reactour_m__pods"
                            data-tip="This feature is only available to members"
                          >
                            {iconPods}
                            {' '}
                            Networking
                            <ReactTooltip place="right" backgroundColor="gray" effect="solid" multiline />
                          </div>
                        )}

                        {hasMemberRole(authUser) ? (
                          <NavLink
                            to={ROUTES.MENTORSHIP}
                            className="navlink"
                            activeClassName="navlink-active"
                            data-tut="reactour_m__mentorship"
                          >
                            {iconMentor}
                            {' '}
                            Mentorship
                            {isFreemiumIndividual(authUser) && (
                            <FontAwesomeIcon icon={faLock} className="ml-2 h-4 w-4" color="gray" />
                            )}
                          </NavLink>
                        ) : (
                          <div
                            className="navlink"
                            data-tut="reactour_m__mentorship"
                            data-tip="This feature is only available to members"
                          >
                            {iconMentor}
                            {' '}
                            Mentorship
                            <ReactTooltip place="right" backgroundColor="gray" effect="solid" multiline />
                          </div>
                        )}
                      </div>

                      <NavLink
                        to={ROUTES.SLACK}
                        className="navlink"
                        activeClassName="navlink-active"
                        data-tut="reactour_m__slack"
                      >
                        {iconSlack}
                        {' '}
                        Slack
                      </NavLink>

                      <NavLink
                        to={{ pathname: ROUTES.EXTERNAL_PAGE_EVENT }}
                        target="_blank"
                        className="navlink"
                        activeClassName="navlink-active"
                        data-tut="reactour_m__events"
                      >
                        {iconEvent}
                        {' '}
                        Events
                      </NavLink>

                      <NavLink
                        to={{ pathname: ROUTES.EXTERNAL_PAGE_JOBS }}
                        target="_blank"
                        className="navlink"
                        activeClassName="navlink-active"
                        data-tut="reactour_m__jobboard"
                      >
                        {iconJobs}
                        {' '}
                        Jobs
                      </NavLink>

                      <NavLink
                        to={{ pathname: ROUTES.RESOURCES }}
                        className="navlink"
                        activeClassName="navlink-active"
                        data-tut="reactour_m__resources"
                      >
                        {iconResources}
                        {' '}
                        Resources
                      </NavLink>
                    </nav>
                  </div>

                  <div className="flex-shrink-0 flex p-4">
                    <div className="ml-3">
                      <p className="text-sm text-gray-300">WISE &copy; 2021</p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      )}

      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Link to={ROUTES.HOME}>
                  <img className="h-8 w-auto" src={Logo} alt="WISE" />
                </Link>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {hasAdminRole(authUser) && (
                  <h3
                    className="pt-4 px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider"
                    data-tut="reactour__companyHeader"
                  >
                    Company
                  </h3>
                )}

                {hasAdminRole(authUser) && (
                  <NavLink
                    to={ROUTES.COMPANY_MANAGE_TEAM}
                    className="navlink"
                    activeClassName="navlink-active"
                    data-tut="reactour__manageTeam"
                  >
                    {iconManageMembers}
                    {' '}
                    Members
                  </NavLink>
                )}
                {hasAdminRole(authUser) && (
                  <NavLink
                    to={ROUTES.COMPANY_MANAGE_PROFILE}
                    className="navlink"
                    activeClassName="navlink-active"
                    data-tut="reactour__manageCompany"
                  >
                    {iconManageCompany}
                    {' '}
                    Profile
                  </NavLink>
                )}
                {hasAdminRole(authUser) && (
                  <NavLink
                    to={ROUTES.COMPANY_RESOURCES}
                    className="navlink"
                    activeClassName="navlink-active"
                    data-tut="reactour__companyresources"
                  >
                    {iconResources}
                    {' '}
                    Resources
                  </NavLink>
                )}

                {hasAdminRole(authUser) && (
                  <h3
                    className="pt-4 px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider"
                    data-tut="reactour__member"
                  >
                    Member
                  </h3>
                )}
                <NavLink to={ROUTES.MEMBER_WELCOME} className="navlink" activeClassName="navlink-active">
                  {iconHome}
                  {' '}
                  Welcome
                </NavLink>

                <div data-tut="reactour__paid">
                  {hasMemberRole(authUser) ? (
                    <NavLink
                      to={isFreemiumIndividual(authUser) ? ROUTES.PRICING : ROUTES.PODS}
                      className="navlink"
                      activeClassName="navlink-active"
                      data-tut="reactour__pods"
                    >
                      {iconPods}
                      {' '}
                      Networking
                      {isFreemiumIndividual(authUser) && (
                      <FontAwesomeIcon icon={faLock} className="ml-2 h-4 w-4" color="gray" />
                      )}
                    </NavLink>
                  ) : (
                    <div
                      className="navlink"
                      data-tut="reactour__pods"
                      data-tip="This feature is only available to members"
                    >
                      {iconPods}
                      {' '}
                      Networking
                      <ReactTooltip place="right" backgroundColor="gray" effect="solid" multiline />
                    </div>
                  )}

                  {hasMemberRole(authUser) ? (
                    <NavLink
                      to={isFreemiumIndividual(authUser) ? ROUTES.PRICING : ROUTES.MENTORSHIP}
                      className="navlink"
                      activeClassName="navlink-active"
                      data-tut="reactour__mentorship"
                    >
                      {iconMentor}
                      {' '}
                      Mentorship
                      {isFreemiumIndividual(authUser) && (
                      <FontAwesomeIcon icon={faLock} className="ml-2 h-4 w-4" color="gray" />
                      )}
                    </NavLink>
                  ) : (
                    <div
                      className="navlink"
                      data-tut="reactour__pods"
                      data-tip="This feature is only available to members"
                    >
                      {iconMentor}
                      {' '}
                      Mentorship
                      <ReactTooltip place="right" backgroundColor="gray" effect="solid" multiline />
                    </div>
                  )}
                </div>

                <NavLink
                  to={ROUTES.SLACK}
                  className="navlink"
                  activeClassName="navlink-active"
                  data-tut="reactour__slack"
                >
                  {iconSlack}
                  {' '}
                  Slack
                </NavLink>

                <NavLink
                  to={{ pathname: ROUTES.EXTERNAL_PAGE_EVENT }}
                  target="_blank"
                  className="navlink"
                  activeClassName="navlink-active"
                  data-tut="reactour__events"
                >
                  {iconEvent}
                  {' '}
                  Events
                </NavLink>

                <NavLink
                  to={{ pathname: ROUTES.EXTERNAL_PAGE_JOBS }}
                  target="_blank"
                  className="navlink"
                  activeClassName="navlink-active"
                  data-tut="reactour__jobboard"
                >
                  {iconJobs}
                  {' '}
                  Jobs
                </NavLink>

                <NavLink
                  to={ROUTES.RESOURCES}
                  className="navlink"
                  activeClassName="navlink-active"
                  data-tut="reactour__resources"
                >
                  {iconResources}
                  {' '}
                  Resources
                </NavLink>
              </nav>
            </div>

            <div className="flex-shrink-0 flex p-4">
              <div className="ml-3">
                <p className="text-sm text-gray-300">WISE &copy; 2021</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(SidebarNav);

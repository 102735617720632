import React, { useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { createBrowserHistory } from 'history';
import {
  faClock, faUser, faUsers, faCheckCircle, faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Moment from 'react-moment';
import { compose } from 'recompose';
import preval from 'preval.macro';

import * as ROUTES from 'constants/routes';
import { withAuthentication, withAuthorization, isFreemiumIndividual } from 'components/Session';

import { BreadcrumbsProvider, BreadcrumbsItem } from 'components/Breadcrumb';
import SidebarNav from 'components/SidebarNav';
import TopNav from 'components/TopNav';
import { SignOutPage } from 'components/SignOut';
import history from 'components/History';

import SignUpPage from 'pages/SignUp';
import SignInPage from 'pages/SignIn';
import OnboardingPage from 'pages/Onboarding';
import PasswordForgetPage from 'pages/PasswordForget';
import MemberWelcomePage from 'pages/Welcome';
import MentorshipPage from 'pages/Mentorship';
import SlackInstructionPage from 'pages/SlackInstruction';
import AccountPage from 'pages/Account';
import PodPage from 'pages/PodList';
import Error403Page from 'pages/Error/403';
import BillingPage from 'pages/Billing';
import UpgradeCompletePage from 'pages/Checkout/Individual/MemberUpgradeComplete';
import CheckoutCompletePage from './pages/Checkout/Individual/MemberCheckoutComplete';
import MemberApplicationPage from './pages/Checkout/Individual/MemberApplication';
import PricingPage from './pages/Checkout/Individual/MemberCheckout';
import MembershipDeniedPage from './pages/Checkout/Individual/MembershipDenied';
import AdminResourcesPage from './pages/AdminResources';
import MemberResourcesPage from './pages/MemberResources';

import CompanyApplicationPage from './pages/Checkout/Company/CompanyApplication';
import CompanyCheckoutPage from './pages/Checkout/Company/CompanyCheckout';
import CompanyCheckoutCompletePage from './pages/Checkout/Company/CompanyCheckoutComplete';

import ManageTeamPage from './pages/Company/ManageTeam';
import ManageCompanyPage from './pages/Company/ManageCompany';
import CompanyPublicPage from './pages/Company/Public';
import ImpersonationBanner from './components/ImpersonationBanner';

const moment = require('moment');

library.add(fab, faClock, faUser, faUsers, faCheckCircle, faCalendar);

// const history = createBrowserHistory();
let prevPath = null;

console.log('function base url', process.env.REACT_APP_FUNCTION_BASE_URL);

// listen and notify Segment of client-side page updates
history.listen((location) => {
  console.log('new url', location.pathname);
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

// Sets default moment settings
Moment.globalMoment = moment;
Moment.globalLocale = 'en';
Moment.globalFormat = 'D MMM YYYY';
Moment.globalTimezone = moment.tz.guess();
Moment.globalLocal = false;

const withCentered = (WrappedComponent, showVersion) => class extends React.Component {
  render() {
    return (
      <div className="h-screen flex bg-white">
        <div className="flex flex-col w-0 flex-1">
          <WrappedComponent />
        </div>
      </div>
    );
  }
};

const withNav = (WrappedComponent) => (props) => {
  const [isMobileSidebarOn, toggleMobileSidebar] = useState(false);

  return (
    <div className="h-screen">
      <div className="h-full overflow-hidden">
        {(props.authUser.cohort_list?.includes('WISE Admin') || props.adminUser) && <ImpersonationBanner />}

        <div className="h-full flex overflow-hidden bg-gray-50">
          <SidebarNav isMobileSidebarOn={isMobileSidebarOn} toggleMobileSidebar={toggleMobileSidebar} />
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <TopNav isMobileSidebarOn={isMobileSidebarOn} toggleMobileSidebar={toggleMobileSidebar} />
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none py-6">
              <div className="py-6 px-6">
                <WrappedComponent {...props} />
              </div>
            </main>
          </div>

          <p className="fixed bottom-1 right-1 text-xs text-gray-50">
            Version:
            {' '}
            {preval`module.exports = new Date().toLocaleString();`}
          </p>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;
const withAuthNav = (component) => compose(withAuthorization(condition), withNav)(component);
const withAuth = (component) => compose(withAuthorization(condition))(component);

const App = () => (
  <Router history={history}>
    <BreadcrumbsProvider>
      <div>
        <BreadcrumbsItem to={ROUTES.HOME} name="Home" />

        <Switch>
          <Route exact path={ROUTES.SIGN_IN} component={withCentered(SignInPage, true)} />
          <Route exact path={ROUTES.SIGN_UP} component={withCentered(SignUpPage, true)} />
          <Route exact path={ROUTES.PASSWORD_FORGET} component={withCentered(PasswordForgetPage, true)} />

          <Route exact path={ROUTES.COMPANY_MANAGE_TEAM} component={withAuthNav(ManageTeamPage)} />
          <Route exact path={ROUTES.COMPANY_MANAGE_PROFILE} component={withAuthNav(ManageCompanyPage)} />
          <Route exact path={ROUTES.COMPANY_RESOURCES} component={withAuthNav(AdminResourcesPage)} />

          <Route exact path={ROUTES.COMPANY_CORPORATE_JOIN} component={withCentered(CompanyApplicationPage, true)} />
          <Route exact path={ROUTES.COMPANY_TEAM_APPLY} component={withCentered(CompanyApplicationPage, true)} />
          <Route exact path={ROUTES.COMPANY_CHECKOUT} component={withCentered(CompanyCheckoutPage, false)} />
          <Route
            exact
            path={ROUTES.COMPANY_CHECKOUT_COMPLETE}
            component={withCentered(CompanyCheckoutCompletePage, false)}
          />
          <Route path={ROUTES.COMPANY_PUBLIC_PAGE} component={withCentered(CompanyPublicPage, true)} />

          <Route exact path={ROUTES.ONBOARDING} component={withAuth(withCentered(OnboardingPage, true))} />
          <Route exact path={ROUTES.APPLY} component={withAuth(withCentered(MemberApplicationPage, false))} />
          <Route exact path={ROUTES.PRICING} component={withAuth(withCentered(PricingPage, false))} />
          <Route
            exact
            path={ROUTES.CHECKOUT_COMPLETE}
            component={withAuth(withCentered(CheckoutCompletePage, false))}
          />
          <Route exact path={ROUTES.UPGRADE_COMPLETE} component={withAuth(withCentered(UpgradeCompletePage, false))} />

          <Route
            exact
            path={ROUTES.MEMBERSHIP_DENIED}
            component={withAuth(withCentered(MembershipDeniedPage, false))}
          />

          <Route exact path={ROUTES.ACCOUNT} component={withAuthNav(AccountPage)} />
          <Route exact path={ROUTES.BILLING} component={withAuthNav(BillingPage)} />
          <Route exact path={ROUTES.MENTORSHIP} component={withAuthNav(MentorshipPage)} />
          <Route exact path={ROUTES.SLACK} component={withAuthNav(SlackInstructionPage)} />
          <Route exact path={ROUTES.RESOURCES} component={withAuthNav(MemberResourcesPage)} />
          <Route exact path={ROUTES.MEMBER_WELCOME} component={withAuthNav(MemberWelcomePage)} />
          <Route exact path={ROUTES.SIGN_OUT} component={withAuthNav(SignOutPage)} />
          <Route path={ROUTES.PODS} component={withAuthNav(PodPage)} />
          <Route exact path={ROUTES.HOME} component={withAuthNav(MemberWelcomePage)} />

          {/* keep referral url for backward compat */}
          <Route exact path={ROUTES.REFERRAL} component={withAuthNav(MemberWelcomePage)} />
          <Route component={Error403Page} />
        </Switch>

      </div>
    </BreadcrumbsProvider>
  </Router>
);

export default withAuthentication(App);

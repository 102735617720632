import React from 'react';
import Logo from 'assets/wise-light-transparent.png';
import {EXTERNAL_PAGE_HOME} from "../constants/routes";

export default function PublicHeader() {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center border-gray-100 py-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href={EXTERNAL_PAGE_HOME}>
            <img
              className="h-8 w-auto sm:h-10"
              src={Logo}
              alt="Logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

import * as ROUTES from 'constants/routes';
import { useParams } from 'react-router-dom';
import CompanyBenefitsSection from './CompanyBenefits';

const CompanyCheckoutCompletePage = () => {
  const { companyPlan } = useParams();
  return (
    <div className="relative bg-primary">
      <main className="lg:relative">
        <div className="bg-white relative w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
          <div className="py-16 px-4 sm:px-6 lg:px-8 lg:py-16 overflow-x-hidden">
            <div className="relative max-w-xl mx-auto">
              {companyPlan === 'team' && (
                <div>
                  <h2 className="mt-10 text-2xl font-semibold">
                    Thanks for submitting your application to partner with WISE!!
                  </h2>
                  <p className="mt-8 text-xl">
                    You're all set! Your application for partnership was submitted successfully. Be on the lookout in your
                    inbox where you'll also receive an email confirming your submission and additional context on next steps.
                    {' '}
                    <a
                      href={ROUTES.EXTERNAL_PAGE_ZENDESK_PARTNERSHIP_FAQ}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary underline"
                    >
                      Partnership FAQs
                    </a>
                  </p>
                </div>
              )}
              {companyPlan === 'corporate' && (
                <div>
                  <h2 className="mt-10 text-2xl font-semibold">
                    Thanks - you're all set
                  </h2>
                  <p className="mt-8 text-xl">
                    Your payment was successfully submitted! You will hear from us soon with more information, but in
                    the meantime feel free to reference our
                    {' '}
                    <a
                      href={ROUTES.EXTERNAL_PAGE_ZENDESK_PARTNERSHIP_FAQ}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary underline"
                    >
                      FAQs
                    </a>.
                  </p>
                  <p className="mt-8 text-xl">
                    Thanks for your interest in WISE!
                  </p>
                </div>
              )}

            </div>
          </div>
        </div>

        <div className="lg:min-h-screen mx-auto w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
          <CompanyBenefitsSection />
        </div>
      </main>
    </div>
  );
};

export default CompanyCheckoutCompletePage;

import React from 'react';

import { withFirebase } from 'components/Firebase';

import * as ROUTES from 'constants/routes';
import * as USER from 'models/User';
import { trackUserEvent, identifyUser } from 'utils/Segment';
import history from 'components/History';

import { compose } from 'recompose';
import { connect } from 'react-redux';

const ContinueWithBasic = ({ firebase, authUser }) => {
  const updateBasicUser = () => {
    // console.log(authUser);

    if (authUser && authUser.membership_status === USER.MEMBERSHIP_STATUS_ACTIVE) {
      history.push(ROUTES.HOME);
    } else {
      firebase
        .updateUser({
          uid: firebase.auth.currentUser.uid,
          membership_status: USER.MEMBERSHIP_STATUS_APP_SUBMITTED,
          membership_startdate: firebase.currentTime(),
          membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
          membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_FREE,
          company_id: '',
          company_account_name: '',
        })
        .then(() => {
          identifyUser(firebase, firebase.auth.currentUser.uid, {
            membership_status: USER.MEMBERSHIP_STATUS_APP_SUBMITTED,
          });
          trackUserEvent(firebase, firebase.auth.currentUser.uid, 'user_membership_apply_completed');
          history.push(ROUTES.CHECKOUT_COMPLETE);
        });
    }
  };

  return (
    <div className="mt-8 py-2 text-xs text-center">
      <a
        id="basic-suscription"
        className="cursor-pointer text-primary underline font-semibold"
        onClick={(e) => updateBasicUser()}
      >
        Continue with Basic
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps), withFirebase)(ContinueWithBasic);

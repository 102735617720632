export const SUBSCRIPTION_DETAILS = 'sub_details';
export const ACCOUNT_DETAILS = 'account_details';
export const ADDRESS = 'portal_address';
export const PAYMENT_SOURCES = 'portal_payment_methods';
export const BILLING_HISTORY = 'portal_billing_history';

export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_TRIAL = 'in_trial';
export const SUBSCRIPTION_STATUS_CANCELLED = 'cancelled';
export const SUBSCRIPTION_STATUS_FUTURE = 'future';

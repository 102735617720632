import React from 'react';
import Tour from 'reactour';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from 'components/Firebase';
import { IsScreenMobile } from 'utils/Widgets';

const AdminProductTour = ({
  authUser, onUpdateAuthUser, firebase, isTourOpen, setTourOpen, setStep, conditionalStep,
}) => {
  const accentColor = '#002266';

  const closeTour = () => {
    if (!authUser.completed_admin_product_tour) {
      firebase.updateUser({ uid: authUser.uid, completed_admin_product_tour: true });
      onUpdateAuthUser({ completed_admin_product_tour: true });
    }
    setTourOpen(false);
    localStorage.removeItem('savedStep');
  };

  return (
    <div>
      <Tour
        onRequestClose={closeTour}
        steps={getTourConfig()}
        isOpen={isTourOpen}
        className="helper"
        rounded={5}
        accentColor={accentColor}
        showCloseButton={!!authUser.completed_admin_product_tour}
        closeWithMask={!!authUser.completed_admin_product_tour}
        showNavigationNumber
        lastStepNextButton={<span className="btn">Done</span>}
        getCurrentStep={setStep}
        goToStep={conditionalStep}
      />
    </div>
  );
};

export const tourStyle = {
  badge: (base) => ({ ...base, color: 'white', backgroundColor: '#00143D' }),
};

export const getTourConfig = () => {
  // support desktop vs mobile versions of the sidebar
  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';

  return [
    {
      position: 'center',
      content: () => (
        <div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Fevent_product_tour_01.jpeg?alt=media&token=b6c68a24-2a56-4537-a290-1ebe68216c4a"
              alt="WISE Product Tour"
            />
          </div>
          <div className="mt-4 mb-4 text-md">
            Welcome to WISE! We are thrilled to have your company joining the community as a partner.
          </div>
          <div className="mt-4 mb-4 text-md">
            Please continue for a brief tour of the WISE Portal so you can start taking advantage of all your partnership
            benefits!
          </div>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__companyHeader"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900 place-self-center">For Companies</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Here you'll find all your partner-specific features. Only admins at our partners have access to this
            information!
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__member"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">For Members</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Here you can see all the benefits available to individual members. Though only admins who are also in sales
            positions can participate in all programming listed here, we want you to be able to see what your team will
            have access to with membership.
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__membershipManagement"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Manage Members</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Here you'll manage your team's memberships - invite new members, know how many unused memberships you have
            available, invite other colleagues as admins, and more.
          </p>
        </div>
      ),
      action: () => { localStorage.setItem('savedStep', JSON.stringify(4)); },
    },

    {
      selector: `[data-tut="${tourClassPrefix}__slack"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Slack Group </p>
          </div>

          <p className="mt-8 text-md leading-6">
            Understand the purpose of each of our Slack channels - as an admin, you should mostly focus on the "jobs"
            channel!
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__manageCompany"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Manage Profile</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Upload and edit all of your company's information here - your public partner profile page pulls from here!
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__companyProfile"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Company Basic Information</p>
          </div>

          <p className="mt-8 text-md leading-6">
            The "basics" section - through you aren't required to include a long description, we highly recommend that you do.
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__testimonials"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Testimonials</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Showcase your executive team's support of WISE and diversity with executive quotes!
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__companyImages"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Company Images</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Here you'll upload all relevant logos and images for your team. If at any point you rebrand or want to adjust
            anything, to adjust anything you can always do so.
          </p>
        </div>
      ),
    },

    {
      selector: '[data-tut="reactour__account"]',
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Profile Settings</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Instantly review your membership details, change your password, update your bio, review our Community
            Standards, and more!
          </p>
        </div>
      ),
    },
  ];
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(AdminProductTour);

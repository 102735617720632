import React, { useEffect } from 'react';

import * as ROUTES from 'constants/routes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFirebase } from 'components/Firebase';

import * as USER from 'models/User';
import * as COMPANY from 'models/Company';
import { trackUserEvent, identifyUser, syncCompany } from 'utils/Segment';
import history from 'components/History';

const createCheckoutCallback = (firebase, company, companyPlan, authUser) => ({ cart }) => ({
  loaded() {
    // console.log('checkout opened');
  },
  close() {
    // console.log('checkout closed');
  },
  success(hostedPageId) {
    if (authUser && authUser.membership_status === USER.MEMBERSHIP_STATUS_ACTIVE) {
      firebase
        .updateUser({
          uid: firebase.auth.currentUser.uid,
          membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
          membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID,
          membership_upgrade_date: firebase.currentTime(),
          completed_product_tour: false,
          company_id: '',
          company_account_name: '',
        })
        .then(() => {
          identifyUser(firebase, firebase.auth.currentUser.uid, {
            membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
            membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID,
            completed_product_tour: false,
          });
          trackUserEvent(firebase, firebase.auth.currentUser.uid, 'user_membership_upgrade_completed');
          history.push(ROUTES.UPGRADE_COMPLETE);
        });
      history.push(ROUTES.CHECKOUT_COMPLETE);
    } else if (firebase.auth.currentUser) {
      firebase
        .updateUser({
          uid: firebase.auth.currentUser.uid,
          membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
          membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID,
          membership_status: USER.MEMBERSHIP_STATUS_APP_SUBMITTED,
          completed_product_tour: false,
          company_id: '',
          company_account_name: '',
        })
        .then(() => {
          identifyUser(firebase, firebase.auth.currentUser.uid, {
            membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
            membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID,
            membership_status: USER.MEMBERSHIP_STATUS_APP_SUBMITTED,
            completed_product_tour: false,
          });
          trackUserEvent(firebase, firebase.auth.currentUser.uid, 'user_membership_apply_completed');
          history.push(ROUTES.CHECKOUT_COMPLETE);
        });
    } else if (company) {
      const companyData = {
        account_status: COMPANY.ACCOUNT_STATUS_SUBMITTED,
      };
      firebase.updateCompany(company.id, companyData).then(() => {
        syncCompany(company.id, 'company_sponsorship_apply_completed', companyData);
        const route = ROUTES.COMPANY_CHECKOUT_COMPLETE.replace(':companyPlan', companyPlan);
        history.push(route);
      });
    }
  },
  step(value) {
    // value -> which step in checkout
  },
});

const CBCheckoutButton = ({
  firebase, authUser, planId, company, companyPlan,
}) => {
  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {};
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openCheckout = () => {
    const cbInstance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE_ID,
      isItemsModel: true,
    });
    const cart = cbInstance.getCart();
    const planPriceQuantity = 1;
    const product = cbInstance.initializeProduct(planId, planPriceQuantity);

    window.Chargebee.registerAgain();
    cbInstance.setCheckoutCallbacks(createCheckoutCallback(firebase, company, companyPlan, authUser));
    let customer = {};
    if (authUser) {
      console.log('Start member checkout', authUser);
      customer = {
        first_name: encodeURIComponent(authUser.first_name),
        last_name: encodeURIComponent(authUser.last_name),
        email: encodeURIComponent(authUser.email),
        company: encodeURIComponent(authUser.current_company),
      };
      customer.cf_app_email = encodeURIComponent(authUser.email);
      customer.cf_app_model_id = encodeURIComponent(authUser.id);
    } else if (company) {
      console.log('Start company checkout', company);
      customer = {
        first_name: encodeURIComponent(company.applicant_firstname),
        last_name: encodeURIComponent(company.applicant_lastname),
        email: encodeURIComponent(company.applicant_email),
        company: encodeURIComponent(company.company_name),
      };
      customer.cf_app_email = encodeURIComponent(company.applicant_email);
      customer.cf_app_model_id = encodeURIComponent(company.id);
      const currentDate = new Date();
      const subscriptionStartDate = currentDate.setDate(currentDate.getDate() + 7);
      product.data.start_date = parseInt(subscriptionStartDate / 1000);
    }
    console.log('customer', customer);
    cart.setCustomer(customer);
    cart.replaceProduct(product);
    cart.proceedToCheckout();
  };
  return (
    <span
      id="subscribe"
      className="cursor-pointer mt-8 block w-full bg-primary border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-primary-dark"
      onClick={openCheckout}
    >
      Continue
    </span>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps), withFirebase)(CBCheckoutButton);

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from 'components/Firebase';
import ReactTooltip from 'react-tooltip';

const memberLicenseTooltipText = 'Membership licenses are our standard license  type for anyone <br>in a sales-focused role within the broader revenue organization.';
const adminLicenseTooltipText = 'Admin licenses are exclusively for stakeholders at our sponsor companies<br>'
  + " to manage their sponsorship benefits and ensure the broader team is doing the same. <br>Admins who aren't also in sales positions are able to view, <br>but not participate in, all the programming offered to Members. ";

function TeamLicenseStats({ company }) {
  return (
    <div>
      {company && (
      <div className="mb-4">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl mb-2">
          <span className="block">Manage Memberships</span>
        </h2>

        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
          <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <div className="ml-16 text-sm font-medium text-gray-500 truncate ">
                Memberships
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 inline-block ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  data-tip={memberLicenseTooltipText}
                  data-for="memberTooltip"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
                <ReactTooltip
                  id="memberTooltip"
                  place="top"
                  backgroundColor="gray"
                  effect="solid"
                  multiline
                />
              </div>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{company.membership_license_used_count}</p>
              <span className="ml-1 text-sm font-medium text-gray-500">Assigned</span>
              <p className="text-2xl font-semibold text-gray-900">
                    &nbsp;&nbsp;&#47;&nbsp;&nbsp;
                {company.membership_license_max_count}
              </p>
              <span className="ml-1 text-sm font-medium text-gray-500">Total</span>
            </dd>
          </div>

          <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <div className="ml-16 text-sm font-medium text-gray-500 truncate">
                Admin Memberships
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 inline-block ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  data-tip={adminLicenseTooltipText}
                  data-for="adminTooltip"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
                <ReactTooltip
                  id="adminTooltip"
                  place="top"
                  backgroundColor="gray"
                  effect="solid"
                  className="!important text-left"
                  multiline
                />
              </div>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{company.admin_license_used_count}</p>
              <span className="ml-1 text-sm font-medium text-gray-500">Assigned</span>
              <p className="text-2xl font-semibold text-gray-900">
                    &nbsp;&nbsp;&#47;&nbsp;&nbsp;
                {company.admin_license_max_count}
              </p>
              <span className="ml-1 text-sm font-medium text-gray-500">Total</span>
            </dd>
          </div>
        </dl>
      </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  company: state.companyState.company,
});

export default compose(connect(mapStateToProps), withFirebase)(TeamLicenseStats);

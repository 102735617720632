import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import request from 'request';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Widget } from '@uploadcare/react-widget';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { ArticleModel } from 'models/Company';
import { LoaderIcon } from 'utils/Widgets';

function Article({ firebase, article, setShowForm }) {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();
  const widgetApi = useRef();

  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [count, setCount] = useState({ article_description: null });

  if (!article) {
    article = [];
  }

  useEffect(() => {
    if (article) {
      setImageUrl(article.article_image);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const companySchema = yup.object().shape({
    article_title: yup.string().trim().required('This field is required'),
    article_url: yup
      .string()
      .trim()
      .required('This field is required')
      .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Please enter a valid URL'),
    article_description: yup.string().trim().required('This field is required'),
    article_image: yup.string().required('Please add a image for the article'),
  });

  const {
    register, handleSubmit, errors,
  } = useForm({
    defaultValues: {
      article_title: article.article_title,
      article_url: article.article_url,
      article_description: article.article_description,
      article_image: article.article_image,
    },
    resolver: yupResolver(companySchema),
  });

  const openDialog = () => {
    if (widgetApi.current) {
      widgetApi.current.openDialog();
    }
  };

  const onFileSelect = (file) => {
    if (file) {
      file.progress((info) => {
        setIsLoadingImage(true);
      });

      file.done((info) => {
        fetch(info.cdnUrl).then((res) => res.blob()).then((blob) => {
          firebase.storage.ref().child(`companies/${authUser.company_id}/articles/${authUser.company_id}_${info.uuid}`).put(blob).then((snapshot) => snapshot.ref.getDownloadURL())
            .then(async (url) => {
              setImageUrl(url);
              setIsLoadingImage(false);
            });
        }).catch((error) => {
          console.error(error);
          setIsLoadingImage(false);
        });
      });
    }
  };

  const handleInputChange = (event) => {
    setCount({
      ...count,
      [event.target.name]: event.target.value.length,
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const articleModel = new ArticleModel();

    articleModel.creator_id = authUser.uid;
    articleModel.article_title = data.article_title;
    articleModel.article_url = data.article_url;
    articleModel.article_description = data.article_description;
    articleModel.article_image = data.article_image;

    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyArticle`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          article: articleModel,
          action: 'ADD',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          let message;
          message = 'The article has been created.';
          toast.success(message, {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
          if (setShowForm) {
            setShowForm(false);
          }
        }
      },
    );
  };

  const onUpdate = async (data) => {
    setIsLoading(true);
    const articleModel = new ArticleModel();

    articleModel.id = article.id;
    articleModel.creator_id = authUser.uid;
    articleModel.article_title = data.article_title;
    articleModel.article_url = data.article_url;
    articleModel.article_description = data.article_description;
    articleModel.article_image = data.article_image;

    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyArticle`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          article: articleModel,
          action: 'UPDATE',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.success('The article has been updated.', {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
          if (setShowForm) {
            setShowForm(false);
          }
        }
      },
    );
  };

  const onDelete = async (articleModel) => {
    const token = await firebase.auth.currentUser.getIdToken();
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/generateCompanyArticle`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          article: articleModel,
          action: 'DELETE',
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          toast.error(body, {
            autoClose: 10000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          const message = 'The article has been deleted.';
          toast.success(message, {
            autoClose: 5000,
            className: 'text-sm',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
          dispatch({ type: 'SET_COMPANY', company: companyData });
        }
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white rounded-lg grid lg:grid-cols-12 gap-x-6 gap-y-4 px-5 py-5 border-2 my-1">
          <div className="sm:col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-3 text-center">
            <div className="grid grid-cols-1">
              <div className=" overflow-hidden h-full w-full grid-cols-1 mx-auto mt-2 rounded-md">
                { imageUrl ? (
                  <div className="h-50 w-full mx-auto max-w-sm rounded-md">
                    <img className="h-full w-full object-cover rounded-md" src={imageUrl} alt="" />
                  </div>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-gray-300 mx-auto rounded-full" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>

              <div className="col-span-12 ">
                <button
                  className=" w-full mt-2 max-w-xs bg-white border border-gray-300 rounded-md shadow-sm py-2 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                  onClick={openDialog}
                >
                  {isLoadingImage && (<LoaderIcon dark />)}
                  Upload Photo
                </button>
                <input name="article_image" ref={register} className="form-input " defaultValue={imageUrl} type="hidden" />
              </div>
            </div>
            {!imageUrl && (
              <div>
                {' '}
                {errors.article_image?.message && (<p className="mt-2 text-sm text-red-700 col-span-12">{errors.article_image?.message}</p>)}
              </div>
            )}
          </div>

          <div className="sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9">
            <div className="grid grid-cols-12 sm:col-span-12 gap-x-6">

              <div className="col-span-12 md:col-span-12 lg:col-span-12">
                <label htmlFor="article_title" className="form-text">Title</label>
                <input type="text" name="article_title" ref={register} className="form-input" defaultValue={article.article_title} />
                {errors.article_title?.message && (<p className="mt-2 text-sm text-red-700">{errors.article_title?.message}</p>)}
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 mt-2">
                <label htmlFor="article_url" className="form-text">URL</label>
                <input type="text" name="article_url" ref={register} className="form-input" defaultValue={article.article_url} />
                {errors.article_url?.message && (<p className="mt-2 text-sm text-red-700">{errors.article_url?.message}</p>)}
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 mt-2">
                <label htmlFor="article_description" className="form-text">
                  Description
                </label>
                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
                  <textarea
                    name="article_description"
                    rows="2"
                    defaultValue={article.article_description}
                    onChange={(e) => handleInputChange(e)}
                    ref={register}
                    className="form-input"
                    placeholder=""
                  />
                  <div className="grid grid-cols-2">
                    <p className="mt-2 text-sm text-red-700 col-span-1">
                      {errors.article_description?.message && (errors.article_description?.message)}
                    </p>
                    {/*                    <p className="mt-2 text-sm text-gray-500 text-right col-span-1">
                      {count.article_description && (`Characters Left: ${50 - count.article_description}`)}
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-1 flex">
              {article.id ? (
                <div className="inline-flex">
                  <button
                    onClick={handleSubmit(onUpdate)}
                    className="bg-primary inline border border-transparent rounded-md shadow-sm px-5 px-3 my-2 mr-2 text-sm leading-4 font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {isLoading && (<LoaderIcon />)}
                    Save
                  </button>
                  <button
                    type="button"
                    className=" bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 my-2 mx-2 text-md leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      if (window.confirm('Delete the selected article?')) {
                        onDelete(article);
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>

              )
                : (
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className="mt-2 mx-1 w-100 btn flex-3"
                  >
                    {isLoading && (<LoaderIcon />)}
                    Save
                  </button>
                )}

            </div>
          </div>

        </div>
      </form>
      <Widget
        publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
        id="imageUpload"
        ref={widgetApi}
        tabs="file url gdrive dropbox facebook instagram"
        crop="16:9"
        imagesOnly
        multipleMaxStrict
        multipleMax={1}
        previewStep
        effects="crop"
        onFileSelect={onFileSelect}
        preloader={null}
      />
    </>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(Article);

import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { IsScreenMobile } from 'utils/Widgets';

import CompanyLogoLoader from './CompanyLogoLoader';
import CompanyImagesLoader from './CompanyImagesLoader';

function CompanyImagesSection() {
  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';

  return (
    <div className="pt-6">

      <div className="grid grid-cols-12">
        <h3 className="col-span-5 text-lg leading-6 font-medium text-gray-900" data-tut={`${tourClassPrefix}__companyImages`}>
          Visual Assets
        </h3>
      </div>

      <p className="mt-3 text-sm text-gray-500 italic">
        Share photos of your team, office, or whatever else you'd like! Note that the order in which you upload them is
        how they'll appear on your profile, so keep that in mind.
      </p>

      <div id="square_logo" className="mt-6 gap-x-6 gap-y-4">
        <p className="block text-sm font-medium text-gray-700 mb-3">
          Logo
        </p>
        <div className="flex items-center mb-2">
          <CompanyLogoLoader company />
        </div>
      </div>

      <div id="images" className="mt-6 gap-x-6 gap-y-4">
        <p className="block text-sm font-medium text-gray-700 mb-3">
          Images
        </p>
        <CompanyImagesLoader company />
      </div>
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(CompanyImagesSection);

const INITIAL_STATE = {
  events: null,
};

const applySetEvent = (state, action) => ({
  ...state,
  events: {
    ...state.events,
    [action.uid]: action.event,
  },
});

const applySetEventStatus = (state, action) => ({
  ...state,
  events: {
    ...state.events,
    [action.eventId]: {
      ...state.events[action.eventId],
      registration: {
        ...state.events[action.eventId].registration,
        [action.userId]: {
          ...state.events[action.eventId].registration[action.userId],
          status: action.status,
        }
      }
    },
  },
})

function eventReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EVENT_SET': {
      return applySetEvent(state, action);
    }
    case 'EVENT_STATUS_SET': {
      return applySetEventStatus(state, action);
    }
    default:
      return state;
  }
}

export default eventReducer;

import React, { useState } from 'react';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { LoaderIcon } from 'utils/Widgets';

const request = require('request');


function EmailChangeForm({ authUser, firebase }) {

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
  });

  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      email: authUser.email,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    data.email = data.email.trim().toLowerCase();

    firebase.doEmailUpdate(data.email)
      .then(() => {
        return firebase.user(authUser.uid).update({
          email: data.email,
        });
      })
      .then(() => {
        if (authUser.cb_customer_id) {
          firebase.auth.currentUser.getIdToken().then(function(token) {
            request({
              url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/updateCBCustomer`,
              method: 'POST',
              json: true,
              headers: { 'Authorization': `Bearer ${token}` },
              body: { customer_id: authUser.cb_customer_id, email: data.email },
            },
              (err, res, body) => {
              if (err) {
                console.log(err);
              } else {
                // console.log("updated email on stripe account", authUser.cb_customer_id);
              }
            });
          });
        }
      })
      .then(() => {
        setErrorMessage('');
        setSuccessMessage('Your email has been updated.');
        setIsLoading(false);
      })
      .catch(error => {
        // console.log("Error on EmailChangeForm", error);
        setSuccessMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  }

  return (

    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 grid grid-cols-12 gap-x-6 gap-y-4">
          <div className="col-span-12 md:col-span-6">
            <label htmlFor="email" className="form-text">Email</label>
            <input type="email" name="email" ref={register} className="form-input" />
            {errors.email?.message && (<p className="form-error">{errors.email?.message}</p>)}
          </div>
        </div>

        <div className="pt-2">
          {!formState.isSubmitting && formState.isSubmitted && (
            <div className="form-success">{ successMessage }</div>
          )}

          {!formState.isSubmitting && formState.isSubmitted && (
            <div className="form-error">{ errorMessage }</div>
          )}

          <button type="submit" className="mt-2 w-40 btn">
            {isLoading && (<LoaderIcon />)}
            Save
          </button>
        </div>
    </form>
  );
}


const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  )(EmailChangeForm);

import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

const SlackInstructionPage = () => (
  <div className="px-4 md:px-6 lg:px-8">
    <div className="grid grid-cols-10 mb-8">
      <div className="col-span-10 lg:col-span-9 xl:col-span-8 gap-y-6">
        <BreadcrumbsItem to={ROUTES.SLACK} name="Slack" showSeparator />

        <div className="col-span-2">
          <div className="space-y-3 text-sm text-gray-600">
            <div>
              The WISE Slack Group is a convenient place to connect with and get to know other members in the WISE
              Community. Below are some guidelines to help you get the most out of using it!
            </div>
            {/* <div>
                You should have been added to the WISE Slack Group when your membership was approved, but if not reach
                out to our team at{' '}
                <a
                  className="text-primary underline"
                  href={`mailto:${ROUTES.EMAIL_ADDR_SUPPORT}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {ROUTES.EMAIL_ADDR_SUPPORT}
                </a>
                .
              </div> */}

            <h2 className="mt-6 text-2xl font-extrabold tracking-tight text-gray-900">
              <span className="block">Get Started</span>
            </h2>

            <div>
              Use the Invite link below to join our Slack. For help, reach out to our team at
              {' '}
              <a
                className="text-primary underline"
                href={`mailto:${ROUTES.EMAIL_ADDR_SUPPORT}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {ROUTES.EMAIL_ADDR_SUPPORT}
              </a>
            </div>
            <div>
              <a
                href={ROUTES.EXTERNAL_PAGE_SLACK_INVITE}
                className="text-primary font-semibold underline text-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://joinwise.co/slack
              </a>
            </div>
          </div>

          <h2 className="mt-6 text-2xl font-extrabold tracking-tight text-gray-900">
            <span className="block">Global Channels</span>
          </h2>

          <div className="mt-3 text-sm text-gray-600">Global Channels are available to all WISE Members.</div>

          <ul className="mt-3 text-sm list-disc text-gray-600 pl-8 space-y-2">
            <li>
              <span className="font-bold">#1-welcome-intro</span>
              <span>
                {' '}
                - Our “Welcome to WISE!” channel! This is where you should introduce yourself after you sign up and
                continue to meet other new members as they join.
              </span>
            </li>
            <li>
              <span className="font-bold">#2-jobs</span>
              <span>
                {' '}
                - If you’re looking for a new job, here’s where to go! WISE partner companies will share info on new
                and exciting roles, some of which may not even be officially live yet! Note that only WISE partners
                are able to post about roles they're for in this channel.
              </span>
            </li>
            <li>
              <span className="font-bold">#3-upcoming-events</span>
              <span>
                {' '}
                - Where you’ll hear from the WISE Team! We’ll post reminders about upcoming events, pod sessions,
                member-only opportunities, etc. You'll also hear from other members about events they may be tuning in
                to or hosting.
              </span>
            </li>
            <li>
              <span className="font-bold">#4-watercooler</span>
              <span>
                {' '}
                - A place to share non-sales related information - local recommendations, uplifting or helpful
                articles, personal side hustles and more!
              </span>
            </li>
            <li>
              <span className="font-bold">#5-highfives</span>
              <span>
                {' '}
                - Exclusively for the WISE community to share good news, promotions, pat themselves on the back and
                celebrate other members and coworkers!
              </span>
            </li>
            <li>
              <span className="font-bold">#6-wisedom</span>
              <span>
                {' '}
                - A place to share and discuss industry-related news, participate in quick polls, dive into
                community-interest topics and connect with the WISE community on a deeper level.
              </span>
            </li>
            <li>
              <span className="font-bold">#7-motherhood</span>
              <span>
                {' '}
                - The joys of motherhood can bring with it hurdles that are deeply felt by other moms in the workplace. Use this channel to encourage, vent, and share.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(SlackInstructionPage);

/*
Copied from https://github.com/oklas/react-breadcrumbs-dynamic
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { truncateWords } from 'utils/Format';
// import PropTypes from 'prop-types'

import { ThroughProvider, throughContainer, throughAgent, createAdvAgent, throughInterface } from 'react-through';

export const breadcrumbsThroughArea = 'breadcrumbs';

export const breadcrumbsBearingKey = 'to';

export const withBreadcrumbs = throughInterface(breadcrumbsThroughArea);

export const withBreadcrumbsItem = throughAgent(breadcrumbsThroughArea, breadcrumbsBearingKey);

export const withBreadcrumbsContainer = throughContainer(breadcrumbsThroughArea);

export const Dummy = () => null;

export const Item = () => null;

export const BreadcrumbsProvider = ThroughProvider;

export const BreadcrumbsItemWrapper = createAdvAgent(breadcrumbsThroughArea, breadcrumbsBearingKey);

export const BreadcrumbsItem = ({ to, name, showSeparator }) => (
  <BreadcrumbsItemWrapper to={to} name={name}>
    <div className="flex items-center">
      {showSeparator ? (
        <svg
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      ) : null}
      <Link to={to} className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">
        {truncateWords(name, 25)}
      </Link>
    </div>
  </BreadcrumbsItemWrapper>
);

function prepareProps(props, rename, duplicate, remove) {
  const p = Object.assign({}, props);
  Object.keys(duplicate).forEach((k) => {
    p[duplicate[k]] = p[k];
  });
  Object.keys(rename).forEach((k) => {
    p[rename[k]] = p[k];
    delete p[k];
  });
  Object.keys(remove).forEach((k) => {
    delete p[k];
  });
  return p;
}

const defaultCompare = (a, b) => a[breadcrumbsBearingKey].length - b[breadcrumbsBearingKey].length;

const Breadcrumbs_ = (props) => {
  const {
    container: Container = 'span',
    containerProps,
    hideIfEmpty = false,
    item: Item = 'a',
    finalItem: FinalItem = Item,
    finalProps = {},
    duplicateProps: duplicate = {},
    removeProps: remove = {},
    renameProps: rename = Item === 'a' ? { to: 'href' } : {},
    compare,
  } = props;
  const data = props[breadcrumbsThroughArea];
  const itemsValue = Object.keys(data)
    .map((k) => data[k])
    .sort(compare || defaultCompare);
  const count = itemsValue.length;

  if (hideIfEmpty && count === 0) {
    return null;
  }

  return (
    <Container {...containerProps}>
      {itemsValue.map((itemValue, i) => {
        return i + 1 < count ? (
          <Item key={i} {...prepareProps(itemValue, rename, duplicate, remove)} />
        ) : (
          <FinalItem key={i} {...prepareProps(itemValue, rename, duplicate, remove)} {...finalProps} />
        );
      })}
    </Container>
  );
};

const Breadcrumbs = withBreadcrumbsContainer(Breadcrumbs_);
export default Breadcrumbs;

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from 'components/Firebase';
import * as USER from 'models/User';
import * as ROUTES from 'constants/routes';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import LegacyBillingSection from './LegacyBillingSection';
import ChargebeeBillingSection from './ChargebeeBillingSection';

const BillingPage = ({ authUser, company, firebase }) => (
  <div className="px-4 sm:px-6 lg:px-8">
    <BreadcrumbsItem to={ROUTES.BILLING} name="Billing" showSeparator />
    <div className="grid grid-cols-12 gap-6 lg:gap-8">
      <div className="sm:text-center md:max-w-2xl col-span-12 lg:text-left">
        {authUser && authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL && authUser.cb_customer_id && <ChargebeeBillingSection />}
        {authUser && authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL && !authUser.cb_customer_id && <LegacyBillingSection user={authUser} />}
        {company && authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && company.cb_customer_id && <ChargebeeBillingSection />}
        {company && authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && !company.cb_customer_id && <LegacyBillingSection user={authUser} />}
      </div>
    </div>
  </div>
);
const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  company: state.companyState.company,
});

export default compose(connect(mapStateToProps), withFirebase)(BillingPage);

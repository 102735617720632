import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { IsScreenMobile } from 'utils/Widgets';

import ReactTooltip from 'react-tooltip';
import Article from './Article';

function ArticleSection() {
  const company = useSelector((state) => state.companyState.company);

  const [showForm, setShowForm] = useState(false);
  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';
  const [usedCount, setUsedCount] = useState(company.articles_used_count);
  const [maxCount, setMaxCount] = useState(company.articles_max_count);

  useEffect(() => {
    if (!company.articles_used_count) {
      setUsedCount(0);
    } else { setUsedCount(company.articles_used_count); }

    if (!company.articles_max_count) {
      setMaxCount(3);
    } else { setMaxCount(company.articles_max_count); }
  }, [company]);

  return (
    <div className="pt-6">

      <div id="articles" className="grid grid-cols-12">
        <h3 className="col-span-5 text-lg leading-6 font-medium text-gray-900" data-tut={`${tourClassPrefix}__articles`}>
          What’s exciting with us right now
        </h3>
      </div>
      <p className="mt-3 text-sm text-gray-500 italic">
        Here you can add anything current that you want to highlight - an upcoming event, a recent article the company
        was featured in, news about recent funding, a blog post, etc.
      </p>

      <button
        disabled={usedCount >= maxCount}
        type="submit"
        className="mt-3 w-40 btn mb-6"
        onClick={() => {
          setShowForm(!showForm);
        }}
      >
        <div
          data-tip="You are using the maximum of articles allowed"
          data-for="addArticle"
        >
          Add Article
        </div>
      </button>
      {usedCount >= maxCount && (
        <ReactTooltip
          id="addArticle"
          place="top"
          backgroundColor="gray"
          effect="solid"
          multiline
        />
      )}

        {showForm && (
          <Article setShowForm={setShowForm} />
        )}

        {company.articles && (company.articles.map((article) => (<Article key={article.id} article={article} />
        )))}
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(ArticleSection);

import React, { Component } from 'react';
import { node, number, bool } from 'prop-types';

const styleConfig = {
  success: {
    bg: 'bg-green-50',
    icon: 'text-green-300',
    text: 'text-green-700',
    iconVector:
      'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z',
  },
  error: {
    bg: 'bg-red-50',
    icon: 'text-red-300',
    text: 'text-red-700',
    iconVector:
      'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z',
  },
};

class FlashMessage extends Component {
  constructor(props) {
    super(props);

    this.state = { isVisible: true };

    this.hide = this.hide.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
  }

  componentDidMount() {
    const { duration } = this.props;
    if (duration) {
      this.remaining = duration;
      this.resumeTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  hide() {
    this.setState({ isVisible: false });
  }

  resumeTimer() {
    window.clearTimeout(this.timer);

    this.start = new Date();
    this.timer = setTimeout(this.hide, this.remaining);
  }

  pauseTimer() {
    const { persistOnHover } = this.props;
    if (persistOnHover) {
      clearTimeout(this.timer);

      this.remaining -= new Date() - this.start;
    }
  }

  render() {
    const { isVisible } = this.state;
    const { children } = this.props;

    return isVisible && children ? (
      <div
        className={'rounded-md p-4 ' + styleConfig[this.props.styleName]['bg']}
        onMouseEnter={this.pauseTimer}
        onMouseLeave={this.resumeTimer}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className={'h-5 w-5 ' + styleConfig[this.props.styleName]['icon']}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fillRule="evenodd" d={styleConfig[this.props.styleName]['iconVector']} clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className={'text-sm font-medium ' + styleConfig[this.props.styleName]['text']}>{children}</h3>
          </div>
        </div>
      </div>
    ) : null;
  }
}

FlashMessage.defaultProps = {
  duration: null,
  children: null,
  persistOnHover: true,
  styleName: 'success',
};

FlashMessage.propTypes = {
  children: node,
  duration: number,
  persistOnHover: bool,
};

export default FlashMessage;

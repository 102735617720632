import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import {
  isUserRegistered,
  eventStatusIsPreSignup,
  eventStatusIsSignupOpen,
  eventStatusIsSignupClosed,
  eventIsFull,
} from 'utils/Event';

const request = require('request');

class PodRegisterBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  triggerRegister = () => {
    // console.log('register user for event', this.props);
    this.props.firebase.registerEvent(this.props.authUser, this.props.event.id);
    this.props.onSetEventStatus(this.props.event.id, this.props.authUser.uid, 'registered');
    this.setShowModal(true);

    const requestBody = {
      user_id: this.props.authUser.uid,
      event_id: this.props.event.id,
    };

    this.props.firebase.auth.currentUser.getIdToken().then(function (token) {
      request(
        {
          url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/processUserEventSignup`,
          method: 'POST',
          json: true,
          headers: { Authorization: `Bearer ${token}` },
          body: requestBody,
        },
        (err, res, body) => {
          if (err) {
            return console.log(err);
          }
          console.log(body);
        }
      );
    });
  };

  setShowModal = (show) => {
    this.setState({ showModal: show });
  };

  render() {
    return (
      <>
        {!isUserRegistered(this.props.event, this.props.authUser) && eventStatusIsPreSignup(this.props.event) && (
          <button type="button" className="btn cursor-default" disabled>
            Sign up not open
          </button>
        )}

        {!isUserRegistered(this.props.event, this.props.authUser) &&
          eventStatusIsSignupOpen(this.props.event) &&
          !eventIsFull(this.props.event) && (
            <button type="button" onClick={this.triggerRegister} className="btn">
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>Join</span>
            </button>
          )}

        {!isUserRegistered(this.props.event, this.props.authUser) &&
          eventStatusIsSignupOpen(this.props.event) &&
          eventIsFull(this.props.event) && (
            <button type="button" className="btn cursor-default" disabled>
              Pod full
            </button>
          )}

        {!isUserRegistered(this.props.event, this.props.authUser) && eventStatusIsSignupClosed(this.props.event) && (
          <button type="button" className="btn cursor-default" disabled>
            Sign up closed
          </button>
        )}

        {this.state.showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-5 flex-auto">
                    <h2 className="text-2xl font-semibold">Thanks for signing up for a WISE Pod!</h2>
                    <p className="my-4 text-gray-600 text-sm leading-relaxed">
                      You’ll receive a calendar invitation shortly so the time is blocked on your calendar.
                    </p>
                    <p className="my-4 text-gray-600 text-sm leading-relaxed">
                      Once signups close, you’ll receive a second calendar invitation and a confirmation email with
                      information on the others in your Pod, a meeting link, and more.
                    </p>
                    <p className="my-4 text-gray-600 text-sm leading-relaxed">
                      Based on signup volume, we may occasionally have to cancel certain Pods. If that’s the case, we’ll
                      notify you.
                    </p>
                    <p className="my-4 text-gray-600 text-sm leading-relaxed">
                      If you have any questions, please reach out to{' '}
                      <a href={`mailto:${ROUTES.EMAIL_ADDR_SUPPORT}`}>{ROUTES.EMAIL_ADDR_SUPPORT}</a>.
                    </p>
                  </div>

                  <div className="flex items-center justify-end p-5 border-t border-solid border-gray-300 rounded-b">
                    <button
                      className="text-primary background-transparent font-bold uppercase p-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => this.setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  event: (state.eventState.events || {})[props.eventId],
});

const mapDispatchToProps = (dispatch) => ({
  onSetEventStatus: (eventId, userId, status) => dispatch({ type: 'EVENT_STATUS_SET', eventId, userId, status }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(PodRegisterBtn);

import React from 'react';

import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';
import { ToastContainer } from 'react-toastify';
import TeamTable from './TeamTable';
import TeamLicenseStats from './TeamLicenseStats';
import TeamInviteForm from './TeamInviteForm';

const ManageTeamPage = () => (
  <div className="px-4 sm:px-6 lg:px-8">
    <BreadcrumbsItem to={ROUTES.COMPANY_MANAGE_TEAM} name="Members" showSeparator />

    <div className="grid grid-cols-10 mb-8">
      <div className="col-span-10 lg:col-span-9 xl:col-span-8">
        <TeamLicenseStats />
        <TeamInviteForm />
        <TeamTable />
      </div>
    </div>

    <ToastContainer
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className="fixed top-20 right-5"
    />
  </div>
);

export default ManageTeamPage;

import React from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAuthorization } from 'components/Session';
import * as ROUTES from 'constants/routes';
import { isFreemiumIndividual } from 'components/Session/withAuthorization';

const TodoLink = ({ text, iconVector, url }) => (
  <div>
    <Link className="flex items-center hover:bg-gray-200" to={url}>
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-8 w-8 rounded-md bg-indigo-500 text-white">
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={iconVector} />
          </svg>
        </div>
      </div>
      <div className="ml-4 align-middle">
        <dt className="text-lg leading-6 font-medium text-gray-900">{text}</dt>
      </div>
    </Link>
  </div>
);

const TodoExternalLink = ({ text, iconVector, url }) => (
  <div>
    <Link className="flex items-center hover:bg-gray-200" to={{ pathname: url }} target="_blank">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-8 w-8 rounded-md bg-indigo-500 text-white">
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={iconVector} />
          </svg>
        </div>
      </div>
      <div className="ml-4 align-middle">
        <dt className="text-lg leading-6 font-medium text-gray-900">{text}</dt>
      </div>
    </Link>
  </div>
);

const MemberWelcomePage = ({ authUser }) => (
  <div className="px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-12 gap-6 lg:gap-8">
      <div className="sm:text-center md:max-w-2xl col-span-12 lg:text-left">
        <h1>
          <span className="mt-1 block text-3xl tracking-tight font-bold sm:text-3xl xl:text-3xl">
            <span className="step1 block text-gray-900">Let's get started!</span>
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
          Welcome to the WISE Portal - your home base for everything you’ll need to make the most out of your membership.
          From RSVPing for upcoming events, joining a networking Pod or finding a mentor, you’ll find it all here to
          help you take your sales career to the next level - and have fun along the way.
        </p>
      </div>

      <div className="col-span-12 lg:col-span-4 xl:col-span-4 2xl:col-span-3 mx-auto sm:text-center lg:text-left px-10 lg:px-0">
        <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <img
              className="w-full"
              src="https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Fevent_photo_bw002.jpg?alt=media&token=7f64914b-59cd-4490-ac65-e3dc71d00cb3"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="hidden lg:block lg:col-span-4 xl:col-span-4 2xl:col-span-3 sm:text-center lg:text-left">
        <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <img
              className="w-full"
              src="https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Fevent_photo_bw001.jpg?alt=media&token=cfdabd40-9f10-4040-b58b-d49d57fd35b5"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="sm:text-center md:max-w-2xl col-span-12 lg:text-left">
        <div className="mt-4 sm:max-w-lg text-left lg:mx-0">
          <dl className="space-y-8">
            <TodoLink
              url={ROUTES.SLACK}
              text="Join our Slack Channel and say hello"
              iconVector="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />

            {!isFreemiumIndividual(authUser) && (
            <TodoLink
              url={ROUTES.PODS}
              text="Review all information on Pods and sign-up"
              iconVector="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            />
            )}

            <TodoExternalLink
              url={ROUTES.EXTERNAL_PAGE_EVENT}
              text="Check out our upcoming events and library of past events"
              iconVector="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />

            <TodoExternalLink
              url={ROUTES.EXTERNAL_PAGE_COMMUNITY_STANDARD}
              text="Review our Community Standards"
              iconVector="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
            />
          </dl>
        </div>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition),
)(MemberWelcomePage);

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';

import { withFirebase } from 'components/Firebase';
import PodRegisterBtn from './PodRegisterBtn';
import PodCancelBtn from './PodCancelBtn';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';
import { isUserRegistered, eventStatusIsSignupClosed } from 'utils/Event';

const PodParticipants = ({ user, event, eventUsers }) => {
  return (
    <div>
      <BreadcrumbsItem to={ROUTES.PODS + '/' + event.id} name={event.title} showSeparator={true} />

      <div className="flex items-center space-x-2">
        <h2 className="text-md font-bold leading-7 text-gray-900 sm:text-md">Participants</h2>

        {(!isUserRegistered(event, user) || !eventStatusIsSignupClosed(event)) && (
          <svg
            className="h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>

      {(!isUserRegistered(event, user) || !eventStatusIsSignupClosed(event)) && (
        <div className="text-sm text-gray-400">Participants are visible after the pod sign up period</div>
      )}

      {isUserRegistered(event, user) && eventStatusIsSignupClosed(event) && (
        <div className="space-y-2 mt-4">
          {eventUsers.map((eventUser) => (
            <div key={eventUser.id} className="flex items-center">
              <div className="flex-shrink-0 rounded-full overflow-hidden h-14 w-14">
                {eventUser.photo_url && (
                  <img className="h-full w-full" src={eventUser.photo_url} alt={eventUser.first_name} />
                )}

                {!eventUser.photo_url && (
                  <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                )}
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900 flex">
                  {eventUser.first_name} {eventUser.last_name}
                  <a target="_blank" href={eventUser.linkedin_url} rel="noopener noreferrer">
                    <FontAwesomeIcon className="ml-2" icon={['fab', 'linkedin-in']} />
                  </a>
                </div>
                <div className="text-sm text-gray-500">
                  {eventUser.current_title} @ {eventUser.current_company}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

class PodDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      eventUsers: [],
    };
  }

  componentDidMount() {
    // console.log("mounting pod detail", this.props.match)

    if (this.props.match) {
      this.props.firebase
        .event(this.props.match.params.id)
        .get()
        .then((doc) => {
          this.props.onSetEvent(Object.assign(doc.data(), { id: doc.id }), doc.id);
        })
        .then(async () => {
          if (!this.props.event.users_registered || this.props.event.users_registered.length === 0) {
            this.setState({ eventUsers: [], loading: false });
          } else {
            const eventUsers = await this.props.firebase.getEventUsers(this.props.event.users_registered);
            this.setState({ eventUsers, loading: false });
          }
        });
    }
  }

  componentWillUnmount() {}

  render() {
    const { authUser, event } = this.props;
    const { eventUsers } = this.state;

    return (
      <div className="flex-1 relative overflow-y-auto focus:outline-none">
        {event && (
          <div>
            <div className="max-w-3xl xl:max-w-5xl xl:grid xl:grid-cols-3">
              <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
                <div>
                  <div>
                    <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6 border-b pb-6">
                      <div>
                        <h1 className="text-2xl font-bold text-gray-900">{event.title}</h1>
                        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                          <Moment format="MMM D, h:mm A zz" unix>
                            {event.event_start_time.seconds}
                          </Moment>
                        </div>
                      </div>
                      <div className="mt-4 flex md:mt-0">
                        <PodRegisterBtn eventId={event.id} />
                        <PodCancelBtn eventId={event.id} />
                      </div>
                    </div>

                    <div className="py-3 xl:pt-6 xl:pb-0 space-y-3 pt-6">
                      <h2 className="text-md font-bold leading-7 text-gray-900 sm:text-md mb-4">Details</h2>

                      <div className="flex items-center space-x-2">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="text-gray-900 text-sm font-medium">
                          Pod size {event.min_group_size} - {event.max_group_size}
                        </span>
                      </div>

                      <div className="flex items-center space-x-2">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                        </svg>
                        <span className="text-gray-900 text-sm font-medium">
                          Cohort: {event.cohort_list.join(', ')}
                        </span>
                      </div>

                      <div className="flex items-center space-x-2">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                          />
                        </svg>

                        <span className="text-gray-900 text-sm font-medium">
                          <Moment format="ddd, MMMM D, YYYY, h:mm A zz" unix>
                            {event.event_start_time.seconds}
                          </Moment>{' '}
                          -{' '}
                          <Moment format="h:mm A zz" add={{ minutes: event.event_duration }} unix>
                            {event.event_start_time.seconds}
                          </Moment>
                        </span>
                      </div>

                      <div className="flex items-center space-x-2">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="text-gray-900 text-sm font-medium">
                          Sign up ends{' '}
                          <Moment format="ddd, MMMM D, YYYY, h:mm A zz" unix>
                            {event.signup_end_time.seconds}
                          </Moment>
                        </span>
                      </div>

                      <div className="prose max-w-none">
                        <div className="mt-8">
                          <ReactMarkdown>{event.description}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <aside className="xl:block xl:pl-8 mt-4 pt-6 border-t border-gray-200 xl:border-none xl:mt-0 xl:pt-0">
                <div className="space-y-5">
                  <PodParticipants user={authUser} event={event} eventUsers={eventUsers} />
                </div>
              </aside>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  event: props.match ? (state.eventState.events || {})[props.match.params.id] : null,
});

const mapDispatchToProps = (dispatch) => ({
  onSetEvent: (event, uid) => dispatch({ type: 'EVENT_SET', event, uid }),
});

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(PodDetailPage);

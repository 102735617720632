import React from 'react';
import { Route } from 'react-router-dom';

import { MyEventList, OpenEventList } from 'pages/PodList/EventList';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';
import PodDetailPage from 'pages/PodDetail';

const PodList = () => (
  <>
    <div className="grid grid-cols-10 mb-8">
      <div className="col-span-10 lg:col-span-9 xl:col-span-8">
        {/* <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
          <span className="block">WISE Pods</span>
        </h2> */}
        <p className="mt-3 text-md text-gray-600">
          WISE offers multiple ways to grow your network and knowledge!
          {' '}
          <b>Pods</b>
          {' '}
          and
          {' '}
          <b>Workshops</b>
          {' '}
          are a good place to start, so what are you waiting for?
        </p>

        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl mt-6">
          <span className="block">Pods</span>
        </h2>
        <p className="mt-3 text-md text-gray-600">
          Cultivate deeper relationships and explore topics that you care about in a supportive setting. Pod cohorts are assigned at the start of each quarter and meet once a month. Regardless of role or phase of life, there's a group with your name on it.
        </p>
        <p className="mt-3 text-md text-gray-600">
          <a href="https://joinwise.co/pod-signup" className="text-primary underline font-bold" target="_blank" rel="noopener noreferrer">➡️ Sign up to join an upcoming Pod!</a>
        </p>

        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl mt-6">
          <span className="block">Workshops</span>
        </h2>
        <p className="mt-3 text-md text-gray-600">
          Expand your knowledge by exploring a variety of topics led by industry experts. From developing mental fitness to the neuroscience of curiosity and beyond, the subject matter changes but the intentionality never does. Workshops meet once a month and group size is capped at 20 attendees.
        </p>
        <p className="mt-3 text-md text-gray-600">
          ⬇️ Explore upcoming Workshops below!
        </p>
      </div>
    </div>

    <MyEventList title="My Sessions" />
    <OpenEventList title="Upcoming Sessions" />
  </>
);

const PodPage = () => (
  <div className="px-4 sm:px-6 lg:px-8">
    <BreadcrumbsItem to={ROUTES.PODS} name="Networking" showSeparator />
    <Route exact path={ROUTES.PODS} component={PodList} />
    <Route exact path={ROUTES.POD_DETAILS} component={PodDetailPage} />
  </div>
);

export default PodPage;

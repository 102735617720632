import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Widget } from '@uploadcare/react-widget';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import '../../Account/uploadCareStyles.css';
import { LoaderIcon } from 'utils/Widgets';

const fetch = require('node-fetch');

const CompanyLogoLoader = ({ firebase }) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const company = useSelector((state) => state.companyState.company);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const widgetApi = useRef();

  const openDialog = () => {
    if (widgetApi.current) {
      widgetApi.current.openDialog();
    }
  };

  const onFileSelect = (file) => {
    if (file) {
      file.progress((info) => {
        setIsLoading(true);
      });

      file.done((info) => {
        fetch(info.cdnUrl).then((res) => res.blob()).then((blob) => {
          firebase.storage.ref().child(`companies/${authUser.company_id}/${authUser.company_id}_${info.uuid}`).put(blob).then((snapshot) => snapshot.ref.getDownloadURL())
            .then(async (url) => {
              firebase.updateCompany(authUser.company_id, { square_logo: url });
              setIsLoading(false);
              const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
              dispatch({ type: 'SET_COMPANY', company: companyData });
            });
        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
      });
    }
  };

  return (
    <>
      <span className="inline-block rounded-full overflow-hidden h-14 w-14 text-center">
        { company.square_logo && (
        <img className="h-full w-full" src={company.square_logo} alt="" />
        )}

        { !company.square_logo && (
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
          />
        </svg>
        )}
      </span>

      <button
        className="ml-5 btn"
        onClick={openDialog}
      >
        {isLoading && (<LoaderIcon />)}
        Upload Logo
      </button>

      <Widget
        publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
        id="imageUpload"
        ref={widgetApi}
        tabs="file url gdrive dropbox facebook instagram "
        crop="1:1"
        imagesOnly
        multipleMaxStrict
        multipleMax={1}
        previewStep
        effects="crop"
        onFileSelect={onFileSelect}
        preloader={null}
      />
    </>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(CompanyLogoLoader);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import * as USER from 'models/User';

export const hasAdminRole = (user) => user && user.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && user.company_licenses && user.company_licenses.includes(USER.ROLE_COMPANY_ADMIN);

// Is true if user is individual member or has partner member license
// Support comapany_licenses field and also backward compat with individual/corporate users without company_id
export const hasMemberRole = (user) => (user && user.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && user.company_licenses && user.company_licenses.includes(USER.ROLE_MEMBER)) || (user && user.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL);

export const isFreemiumIndividual = (user) => user && user.membership_subtype === USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_FREE;
export const isPaidIndividual = (user) => user && user.membership_subtype === USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID;
export const isPaidPartner = (user) => user && user.membership_subtype === USER.MEMBERSHIP_SUBTYPE_PARTNER_PAID;

const checkRedirect = (user, location) => {
  let redirectUrl = null;
  console.log('redirecting', user);

  if (
    !user.membership_status
    || user.membership_status === USER.MEMBERSHIP_STATUS_APP_INCOMPLETE
    || user.membership_status === USER.MEMBERSHIP_STATUS_EXISTING_MEMBER
  ) {
    if (!location.pathname.includes('/join')) {
      redirectUrl = ROUTES.APPLY;
    }
  } else if (user.membership_status === USER.MEMBERSHIP_STATUS_APP_SUBMITTED) {
    redirectUrl = ROUTES.CHECKOUT_COMPLETE;
  } else if (user.membership_status === USER.MEMBERSHIP_STATUS_APP_DENIED) {
    redirectUrl = ROUTES.MEMBERSHIP_DENIED;
  } else if (user.membership_status === USER.MEMBERSHIP_STATUS_ENDED) {
    // allow user to access signup and payment workflow again
    if (!location.pathname.includes('/join')) {
      redirectUrl = ROUTES.PRICING;
    }
  } else if (user.membership_status === USER.MEMBERSHIP_STATUS_ACTIVE) {
    if (hasMemberRole(user) && (!user.cohort_list?.length || !user.intro)) {
      redirectUrl = ROUTES.ONBOARDING;
    } else if (!hasMemberRole(user) && (!user.current_company || !user.first_name || !user.current_title)) {
      redirectUrl = ROUTES.ONBOARDING;
    } else if (location.pathname.includes('/join') && !isFreemiumIndividual(user)) {
      if (location.pathname != ROUTES.UPGRADE_COMPLETE) {
        redirectUrl = ROUTES.HOME;
      }
    } else if (location.pathname.includes('/join') && isFreemiumIndividual(user)) {
      if (location.pathname == ROUTES.CHECKOUT_COMPLETE) {
        redirectUrl = ROUTES.HOME;
      }
    }
  }

  // restrict pages based on admin or member licenses
  if (ROUTES.ADMIN_ONLY_PAGES.includes(location.pathname) && !hasAdminRole(user)) {
    redirectUrl = ROUTES.HOME;
  }
  if (ROUTES.MEMBER_ONLY_PAGES.includes(location.pathname) && !hasMemberRole(user)) {
    redirectUrl = ROUTES.HOME;
  }
  if (ROUTES.PAID_MEMBER_ONLY_PAGES.includes(location.pathname) && isFreemiumIndividual(user)) {
    redirectUrl = ROUTES.PRICING;
  }

  // redirect based on user roles (either admin or member)
  if (location.pathname === '/' || redirectUrl === ROUTES.HOME) {
    if (hasAdminRole(user)) {
      redirectUrl = ROUTES.COMPANY_MANAGE_TEAM;
    } else {
      redirectUrl = ROUTES.MEMBER_WELCOME;
    }
  }

  return redirectUrl;
};

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      this.props.onSetAdminUser(JSON.parse(localStorage.getItem('adminUser')));
      this.state = {
        ready: false,
      };
    }

    componentDidMount() {
      const { location } = this.props;
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          console.log('withAuthorization got auth', location, authUser);
          let redirectUrl = null;
          const adminUser = JSON.parse(localStorage.getItem('adminUser'));
          if (adminUser) {
            this.props.onSetAdminUser(adminUser);
          }

          if (!condition(authUser)) {
            redirectUrl = ROUTES.SIGN_IN;
            // console.log("no auth, redirecting to login");
          } else if ([ROUTES.SIGN_OUT].includes(location.pathname)) {
            // console.log("handle logout");
          } else {
            redirectUrl = checkRedirect(authUser, location);
          }

          if (redirectUrl && redirectUrl !== location.pathname) {
            this.props.history.push(redirectUrl);
          } else {
            this.setState({ ready: true });
          }
        },
        () => this.props.history.push(ROUTES.SIGN_IN),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return condition(this.props.authUser) && this.state.ready ? <Component {...this.props} /> : null;
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    adminUser: state.sessionState.adminUser,
  });

  const mapDispatchToProps = (dispatch) => ({
    onSetAdminUser: (adminUser) => dispatch({
      type: 'ADMIN_USER_SET',
      adminUser,
    }),
  });

  return compose(withRouter, withFirebase, connect(mapStateToProps, mapDispatchToProps))(WithAuthorization);
};

export default withAuthorization;

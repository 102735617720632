import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useParams, withRouter } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import DefaultLogo from 'assets/wise-sq-light.png';
import { withFirebase } from 'components/Firebase';
import PublicHeader from 'components/PublicHeader';
import PublicFooter from 'components/PublicFooter';

const CompanyPublicPage = ({ firebase, history }) => {
  const [company, setCompany] = useState({});
  const { publicPageId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const companyData = () => {
      firebase.firestore
        .collection('companies')
        .where('public_page_id', '==', publicPageId)
        .onSnapshot((docSnapshot) => {
          if (!docSnapshot.empty) {
            setCompany(docSnapshot.docs[0].data());
            setIsLoading(false);
          } else {
            history.push(ROUTES.HOME);
          }
        }, (err) => {
          console.log('error', err);
        });
    };
    companyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const CompanyDescriptionSection = ({ company }) => (
    <div className=" py-12 text-center mx-auto max-w-lg px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl rounded">

      <div className="text-center mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl bg-primary-dark rounded grid py-5">
        <div className="flex flex-wrap overflow-hidden">

          <div className="overflow-hidden w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 p-6">
            {company ? (
              <img
                className="rounded-full w-full h-full sm:h-60 sm:w-60 md:w-50 md:h-75 lg:h-60 lg:w-60 xl:h-60 xl:w-60 md:mx-auto"
                src={company.square_logo}
                alt=""
              />
            ) : (
              <img
                className="rounded-full w-75 h-75 sm:h-75 sm:w-75 md:w-75 md:h-75 lg:h-75 lg:w-75 xl:h-75 xl:w-75"
                src={DefaultLogo}
                alt=""
              />
            )}
          </div>

          <div className=" overflow-hidden sm:w-full md:w-full lg:w-2/3 xl:w-2/3 mt-10">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center w-full md:mx-auto sm:mx-auto lg:col-span-12 lg:text-left py-4">
                <h1>
                  <span className="mb-6 block text-3xl tracking-tight font-bold sm:text-4xl xl:text-4xl">
                    <span className="block text-white">
                      {company ? company.company_name : 'TODO: text when the company name doesnt exist'}
                    </span>
                  </span>
                </h1>
                <p className="pb-6 text-base text-white sm:mt-5">
                  {company ? company.short_description : 'TODO: text when there isnt short_description'}
                </p>
                <button
                  className="w-50 mb-2 mx-2 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-primary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => { window.open(company.company_url, '_blank').focus(); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Company Page
                </button>
                <button
                  className="w-50 mx-2 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-primary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => { window.open(company.career_url, '_blank').focus(); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline pr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  Careers Page
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );

  const CompanyTestimony = ({ testimony }) => (
    <div className="bg-primary-dark overflow-hidden relative my-2">
      <div className="relative lg:flex lg:items-center pt-1">
        <div className="hidden lg:block lg:flex-shrink-0">
          <img
            className="h-40 w-40 rounded-full mx-auto xl:h-40 xl:w-40 mb-2 cursor-pointer pt-1"
            src={testimony.testimonial_image}
            onClick={() => { window.open(testimony.executive_linkedin_url, '_blank').focus(); }}
            alt=""
          />
          <div className="w-64 cursor-pointer" onClick={() => { window.open(testimony.executive_linkedin_url, '_blank').focus(); }}>
            <div className="text-base font-bold text-white">{testimony.executive_full_name}</div>
            <div className="text-base font-bold text-white">
              {testimony.testimonial_title}
              ,
              {' '}
              {company.company_name}
            </div>
          </div>
        </div>

        <div className="relative w-full h-full">
          <svg
            className="mt-6 absolute top-5 left-0 transform -translate-x-8 -translate-y-24 h-10 w-20 text-indigo-200 opacity-50 text-white"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 144 144"
            aria-hidden="true"
          >
            <path
              strokeWidth={2}
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
            />
          </svg>
          <blockquote className="relative">
            <div className="text-base font-medium text-white italic text-justify">
              <p>
                {testimony.testimonial_text}
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="flex-shrink-0 lg:hidden">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={testimony.testimonial_image}
                    alt=""
                  />
                </div>
                <div className="ml-4 lg:ml-0">
                  <div className="text-base font-bold text-white lg:hidden">{testimony.executive_full_name}</div>
                  <div className="text-base font-bold text-white lg:hidden">
                    {testimony.testimonial_title}
                    ,
                    {' '}
                    {company.company_name}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  );

  const CompanyTestimonialsSection = ({ company }) => (
    <>
      {company.testimonials?.length > 0 && (
        <div className="relative bg-primary-dark py-12">
          <div className="text-center mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl">
            <p className="pb-3 text-4xl font-bold tracking-tight sm:text-4xl text-white">
              Testimonials
            </p>

            {company.testimonials && (
              company.testimonials.map((testimony) => (
                <CompanyTestimony key={testimony.id} testimony={testimony} />
              ))
            )}
          </div>
        </div>
      )}
    </>

  );

  const CompanyImagesSection = ({ company }) => (
    <div className={`relative ${company.images?.length > 0 ? 'py-12' : 'py-6'}`}>
      <div className="text-center mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl rounded-lg">
        <ul className="grid gap-y-5 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 sm:gap-6 lg:grid-cols-4 xl:grid-cols-4 xl:gap-6">
          {company.images && (
            company.images.map((image) => (
              <li key={image} className="relative">
                <div className="flex-shrink-0 rounded h-50 w-full">
                  <img className="h-full w-full object-cover rounded" src={image} alt="" />
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>

  );

  const AboutCompanySection = ({ company }) => (
    <div className="relative bg-primary-dark py-12">
      <div className="text-center mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl py-8">

        <p className="text-3xl font-bold tracking-tight sm:text-4xl text-white">
          {company ? `About ${company.company_name}` : 'TODO: text when the company name doesnt exist'}
        </p>
        <p className="pt-8 mx-auto text-base text-white max-w-6xl text-justify">
          {company ? company.long_description : 'TODO: text when there isnt long_description'}
        </p>
      </div>

    </div>
  );

  const Article = ({ article }) => (
    <div className="flex flex-col rounded shadow-lg overflow-hidden">
      <div className="flex-shrink-0 h-50 w-full">
        <img className="object-cover" src={article.article_image} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <a
            href={article.article_url}
            rel="noopener noreferrer"
            target="_blank"
            className="block mt-2"
          >
            <p className="text-lg font-semibold text-gray-900">{article.article_title}</p>
            <p className="mt-3 text-base text-gray-500">{article.article_description}</p>
          </a>
        </div>
      </div>
    </div>
  );

  const ArticlesSection = ({ company }) => (
    <div className={`relative ${company.articles?.length > 0 ? 'py-12' : 'py-3'} mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-8 lg:max-w-6xl`}>
      <div className="relative max-w-6xl mx-auto">
        {company.articles?.length > 0 && (
        <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900">
          What's exciting with us right now
        </h2>
        )}
        <div className="mt-6 mx-auto grid gap-5 lg:grid-cols-3">
          {company.articles && (
            company.articles.map((article) => (
              <Article key={article.id} article={article} />
            ))
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">

        {isLoading ? (
          <div className="h-screen">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <svg className="text center animate-spin -ml-1 mr-3 h-12 w-12 text-primary-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
          </div>
        ) : (
          <>
            <PublicHeader />
            <CompanyDescriptionSection company={company} />
            <CompanyTestimonialsSection company={company} />
            <CompanyImagesSection company={company} />
            <AboutCompanySection company={company} />
            <ArticlesSection company={company} />
            <PublicFooter />
          </>

        )}

      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(CompanyPublicPage);

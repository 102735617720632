import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from 'components/Firebase';
import { eventIsPublished } from 'utils/Event';
import * as EVENT from 'models/Event';
import Event from './Event';

const currentDate = new Date();

class EventList extends Component {
  constructor(props) {
    super(props);
    this.showUpcomingEvents = this.showUpcomingEvents.bind(this);
    this.showLessEvents = this.showLessEvents.bind(this);
    this.state = {
      upcomingEvents: [],
      pastEvents: [],
      showEvents: 2,
    };
  }

  componentWillUnmount() {}

  showUpcomingEvents() {
    const { pastEvents, showEvents } = this.state;
    let newShowEvents = showEvents + 10;
    if (newShowEvents > pastEvents.length) {
      newShowEvents = pastEvents.length;
    }
    this.setState({ showEvents: newShowEvents });
  }

  showLessEvents() {
    this.setState({ showEvents: 2 });
  }

  render() {
    const { upcomingEvents, pastEvents, showEvents } = this.state;
    const { title } = this.props;

    return (
      <div className="mb-6">
        {upcomingEvents.length > 0 && (
          <div className="grid grid-cols-10">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 col-span-10 2xl:col-span-8 lg:col-span-10">
              <h2
                className="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl mb-4"
                data-tut="reactour__upcomingPods"
              >
                {title}
              </h2>
            </div>
          </div>
        )}

        <div className="grid grid-cols-10">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 col-span-10 2xl:col-span-8 lg:col-span-10">
            {[...upcomingEvents, ...pastEvents.slice(0, showEvents)].map((event) => (
              <Event key={event.id} event={event} />
            ))}
          </div>
          {(pastEvents.length > 2 && pastEvents.length - showEvents > 0) && (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 col-span-10 2xl:col-span-8 lg:col-span-10  my-2 ">
              <a
                className="hover:underline cursor-pointer italic"
                onClick={() => { this.showUpcomingEvents(); }}
              >
                Show past Pods (
                {pastEvents.length - showEvents}
                )
              </a>
            </div>
          )}
          {(pastEvents.length > 2 && pastEvents.length - showEvents === 0) && (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 col-span-10 2xl:col-span-8 lg:col-span-10  my-2 ">
            <a
              className="hover:underline cursor-pointer italic"
              onClick={() => { this.showLessEvents(); }}
            >
              Show less
            </a>
          </div>
          )}
        </div>
      </div>
    );
  }
}

class MyEventListBase extends EventList {
  componentDidMount() {
    if (!this.state.upcomingEvents.length) {
      this.setState({ loading: true });
    }
    this.props.firebase
      .events()
      .where('users_registered', 'array-contains', this.props.authUser.uid)
      .orderBy('event_start_time', 'desc')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => Object.assign(doc.data(), { id: doc.id }));
        const upcomingEvents = data.filter(
          (event) => event.event_start_time.toDate() > currentDate,
        );
        const pastEvents = data.filter(
          (event) => event.event_start_time.toDate() < currentDate,
        );
        this.setState({
          upcomingEvents, pastEvents, loading: false,
        });
      });
  }
}

class OpenEventListBase extends EventList {
  componentDidMount() {
    if (!this.state.upcomingEvents.length) {
      this.setState({ loading: true });
    }

    this.props.firebase
      .events()
      .where('event_start_time', '>=', currentDate)
      .where('status', 'in', [
        EVENT.EVENT_STATUS_PRESIGNUP,
        EVENT.EVENT_STATUS_SIGNUP_OPEN,
        EVENT.EVENT_STATUS_SIGNUP_CLOSED,
      ])
      .orderBy('event_start_time', 'asc')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => Object.assign(doc.data(), { id: doc.id }));
        // filter by cohort and status
        const upcomingEvents = data
          .filter(
            (event) => event.cohort_list.filter((cohort) => this.props.authUser.cohort_list.includes(cohort)).length > 0,
          )
          .filter((event) => eventIsPublished(event));

        this.setState({ upcomingEvents, loading: false });
      });
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const MyEventList = compose(connect(mapStateToProps), withFirebase)(MyEventListBase);

const OpenEventList = compose(connect(mapStateToProps), withFirebase)(OpenEventListBase);

export { MyEventList, OpenEventList };

import React from 'react';

import * as ROUTES from 'constants/routes';
import BenefitsSection from './MemberBenefits';

const CheckoutCompletePage = () => (
  <div className="relative bg-primary">
    <main className="lg:relative">
      <div className="bg-white relative w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
        <div className="py-16 px-4 sm:px-6 lg:px-8 lg:py-16 overflow-x-hidden">
          <div className="relative max-w-xl mx-auto">
            <h2 className="mt-10 text-2xl font-semibold">Thanks for applying to join the WISE community!</h2>
            <p className="mt-8 text-xl">
              Our team will review your application and get back to you within a few business days. If you have
              questions in the meantime, check out our{' '}
              <a
                href={ROUTES.EXTERNAL_PAGE_MEMBERSHIP_FAQ}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                Membership Application FAQs
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="lg:min-h-screen mx-auto w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
        <BenefitsSection />
      </div>
    </main>
  </div>
);

export default CheckoutCompletePage;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import request from 'request';
import { withFirebase } from './Firebase';
import FlashMessage from './FlashMessage';
import { LoaderIcon } from '../utils/Widgets';

const ImpersonationBanner = ({ firebase }) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const adminUser = useSelector((state) => state.sessionState.adminUser);

  const [impersonateStatus, setImpersonateStatus] = useState(!!adminUser);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const accountSchema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address').required('This field is required'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(accountSchema),
  });

  const onSubmit = async (data) => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    const token = await firebase.auth.currentUser.getIdToken();

    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/createCustomToken`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          adminUserId: authUser.uid,
          impersonateUserEmail: data.email,
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          setErrorMessage(body);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          localStorage.setItem('adminUser', JSON.stringify(authUser));
          await firebase.doSignInWithCustomToken(body);
          setImpersonateStatus(true);
          setSuccessMessage(
            `Logged as:${data.email}`,
          );
        }
      },
    );
  };

  const onLogOut = async () => {
    const token = await firebase.auth.currentUser.getIdToken();
    const adminUser = JSON.parse(localStorage.getItem('adminUser'));
    setErrorMessage('');
    setSuccessMessage('');
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/createCustomToken`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          adminUserId: adminUser.uid,
          impersonateUserEmail: adminUser.email,
        },
      },
      async (err, res, body) => {
        await firebase.doSignInWithCustomToken(body).then(() => {
          localStorage.removeItem('adminUser');
          setImpersonateStatus(false);
        });
      },
    );
  };

  return (
    <div className="relative bg-yellow-500">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="text-center">
          {impersonateStatus ? (
            <div className="inline-flex">
              <span className="flex rounded-lg inline">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              <div className="ml-3 font-medium text-white truncate">
                <span>
                  {`Logged as: ${authUser.first_name} ${authUser.last_name} (${authUser.email})`}
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  <span className="text-white font-bold text-sm underline cursor-pointer" onClick={onLogOut}>
                    Log out
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="sm:flex items-center justify-center">
              <p className="text-white pr-1"> [Admin feature] Enter email to log in as another user</p>
              <div className="w-100 sm:max-w-xs">
                <input
                  ref={register}
                  type="email"
                  name="email"
                  id="email"
                  className="w-full shadow-sm text-sm mr-2 focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 rounded-md font-medium"
                  placeholder="Email Address"
                />
                <p className="form-error">{errors.email?.message}</p>
                {!formState.isSubmitting && formState.isSubmitted && (
                <FlashMessage duration={30000} styleName="error">
                  {errorMessage}
                </FlashMessage>
                )}
                {!formState.isSubmitting && formState.isSubmitted && (
                <FlashMessage duration={30000} styleName="success">
                  {successMessage}
                </FlashMessage>
                )}
              </div>
              <button
                type="submit"
                className="inline-flex content-center items-center px-4 py-2 mb-1 ml-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isLoading && <LoaderIcon dark />}
                <p>Log in as</p>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(ImpersonationBanner);

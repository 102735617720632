const capitalize = (s) => s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const capitalizeName = (s) => {
  if (s.length >= 3 && s.toUpperCase() === s) {
    return capitalize(s);
  }
  return s;
};

const truncateWords = (s, maxLength) => {
  // trim the string to the maximum length
  let trimmedString = s.substr(0, maxLength);

  // re-trim if we are in the middle of a word
  if (trimmedString.lastIndexOf(' ') > 0) {
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
  }

  if (trimmedString !== s) {
    trimmedString += '...';
  }

  return trimmedString;
};

export { capitalize, capitalizeName, truncateWords };

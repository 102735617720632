import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import request from 'request';

import * as USER from 'models/User';
import * as COMPANY from 'models/Company';
import { LoaderIcon } from 'utils/Widgets';
import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import FlashMessage from 'components/FlashMessage';
import { refreshCompanyData } from 'components/RefreshCompanyData';

function TeamInviteForm({
  firebase, authUser, setCompanyUsers, setCompany,
}) {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const licenseSchema = yup.object().shape({
    email: yup.string().trim().email('Please enter a valid email address').required('This field is required'),
    license: yup.string().required('Please select a license type'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(licenseSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    const email = data.email.trim().toLowerCase();

    // TODO: Create function that simplifies & promisifies this kind of request.
    //  current duplicated in: TeamTableActions
    const token = await firebase.auth.currentUser.getIdToken();

    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/inviteCompanyUser`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: {
          company_id: authUser.company_id,
          referrer_id: authUser.uid,
          email,
          license_type: data.license,
          action: COMPANY.LICENSE_ACTION_ADD,
        },
      },
      async (err, res, body) => {
        if (res.statusCode === 500) {
          setErrorMessage(body);
        } else {
          setSuccessMessage(
            "All set! You successfully sent an invitation to your colleague, and they'll see an email in their inbox soon with details on how to sign up.",
          );
        }

        refreshCompanyData({
          firebase, authUser, setCompanyUsers, setCompany,
        });
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="grid grid-cols-1 gap-5">
      <div className="col-span-1 bg-white shadow sm:rounded-lg" data-tut="reactour__membershipManagement">
        <div className="px-4 py-5 sm:p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="sm:flex sm:items-center">
            <div className="grid grid-cols-1 gap-4">
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    ref={register}
                    autoComplete="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="name@company.com"
                  />
                </div>
                <p className="form-error">{errors.email?.message}</p>
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Membership Type
                </label>
                <div className="mt-1">
                  <select
                    id="license"
                    name="license"
                    ref={register}
                    className="block w-full pl-3 pr-10 py-2  text-gray-700 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value={USER.ROLE_MEMBER} defaultValue>
                      {USER.ROLE_MEMBER}
                    </option>
                    <option value={USER.ROLE_COMPANY_ADMIN}>{USER.ROLE_COMPANY_ADMIN}</option>
                  </select>
                </div>
                <p className="form-error">{errors.license?.message}</p>
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-3">
                <button type="submit" className="btn w-40 lg:mt-6 xl:mt-6">
                  {isLoading && <LoaderIcon />}
                  Invite
                </button>
              </div>

              <div className="col-span-12 sm:col-span-12">
                {!formState.isSubmitting && formState.isSubmitted && (
                  <FlashMessage duration={30000} styleName="error">
                    {errorMessage}
                  </FlashMessage>
                )}
                {!formState.isSubmitting && formState.isSubmitted && (
                  <FlashMessage duration={30000} styleName="success">
                    {successMessage}
                  </FlashMessage>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyUsers: (companyUsers) => dispatch({
    type: 'SET_COMPANY_USERS',
    companyUsers,
  }),
  setCompany: (company) => dispatch({
    type: 'SET_COMPANY',
    company,
  }),
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withFirebase,
)(TeamInviteForm);

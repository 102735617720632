import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Widget } from '@uploadcare/react-widget';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import './uploadCareStyles.css';
import { LoaderIcon } from 'utils/Widgets';

const fetch = require('node-fetch');

const ProfilePhotoLoader = ({ authUser, onUpdateAuthUser, firebase }) => {
  const widgetApi = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const openDialog = () => {
    if (widgetApi.current) {
      widgetApi.current.openDialog();
    }
  };

  const onFileSelect = (file) => {
    if (file) {
      file.progress((info) => {
        setIsLoading(true);
      });

      file.done((info) => {
        fetch(info.cdnUrl).then((res) => res.blob()).then((blob) => {
          // uploading blob to firebase storage
          firebase.storage.ref().child(`users/${authUser.uid}_${info.name}`).put(blob).then((snapshot) => snapshot.ref.getDownloadURL())
            .then((url) => {
              // console.log('Firebase storage image uploaded : ', url);
              firebase.updateUser({ uid: authUser.uid, photo_url: url });
              onUpdateAuthUser({ photo_url: url });
              setIsLoading(false);
            });
        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
      });
    }
  };

  return (
    <>
      <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-14 w-14">
        { authUser.photo_url && (
        <img className="h-full w-full" src={authUser.photo_url} alt="" />
        )}

        { !authUser.photo_url && (
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        )}
      </span>

      <button className="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={openDialog}>
        {isLoading && (<LoaderIcon dark />)}
        Upload an Image
      </button>

      <Widget
        publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
        id="imageUpload"
        ref={widgetApi}
        tabs="file url camera facebook instagram"
        crop="1:1"
        imagesOnly
        previewStep
        effects="crop"
        onFileSelect={onFileSelect}
        preloader={null}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withFirebase,
)(ProfilePhotoLoader);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Moment from 'react-moment';

import { withFirebase } from 'components/Firebase';
import * as USER from 'models/User';
import * as CHARGEBEE from 'constants/chargebee';

const request = require('request');

const getPortalSession = (firebase, chargebeeId) => () => new Promise((resolve, reject) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    request(
      {
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/getCBPortalSession`,
        method: 'POST',
        json: true,
        headers: { Authorization: `Bearer ${token}` },
        body: { customer_id: chargebeeId },
      },
      (err, res, body) => {
        resolve(body);
      },
    );
  });
});

const ChargebeeBillingSection = ({
  firebase, authUser, company,
}) => {
  // console.log('COMPANY', company);
  const [isLoaded, setIsLoaded] = useState(false);
  const [chargebeeData, setChargebeeData] = useState({});

  useEffect(() => {
    async function renderEmbed() {
      const el = document.createElement('script');
      el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
      document.body.appendChild(el);
      setIsLoaded(true);
    }

    let chargebeeId;
    if (authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL) {
      chargebeeId = authUser.cb_customer_id;
    } else {
      chargebeeId = company.cb_customer_id;
    }

    firebase.auth.currentUser.getIdToken().then((token) => {
      request(
        {
          url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/getCBCustomer`,
          method: 'POST',
          json: true,
          headers: { Authorization: `Bearer ${token}` },
          body: { customer_id: chargebeeId },
        },
        (err, res, body) => {
          setChargebeeData(body);
          renderEmbed();
        },
      );
    });
  }, []); // e
  // slint-disable-line react-hooks/exhaustive-deps

  const openBillingCard = (cardName) => () => {
    let chargebeeId;
    if (authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL) {
      chargebeeId = authUser.cb_customer_id;
    } else {
      chargebeeId = company.cb_customer_id;
    }

    const cbInstance = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE_ID,
    });
    window.Chargebee.registerAgain();
    cbInstance.setPortalSession(getPortalSession(firebase, chargebeeId));
    const cbPortal = cbInstance.createChargebeePortal();
    cbPortal.open({
      close() {
        // close callbacks
      },
    }, {
      sectionType: cardName,
      params: {
        subscriptionId: 'active_direct',
      },
    });
  };

  return (

    <div className="divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Membership</h3>
        <p className="max-w-2xl text-sm text-gray-500">
          {authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL
            && (
              <span className="mt-1 max-w-2xl text-sm text-gray-500">
                You're currently on the annual WISE membership. Please contact your support@womeninsaleseverywhere.com if you need to make
                any changes to your membership.
              </span>
            )}
          {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER
            && (
              <span className="mt-1 max-w-2xl text-sm text-gray-500">
                You're currently on your company's corporate membership. Please contact your company Admin if you need to make
                any changes to your membership.
              </span>
            )}
        </p>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Upcoming Renewal Date</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && company.membership_enddate && (
                  <Moment format="MMM D, YYYY">
                    {company.membership_enddate}
                  </Moment>
                )}
                {authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL && authUser.membership_enddate && (
                  <Moment format="MMM D, YYYY" unix>
                    {authUser.membership_enddate.seconds}
                  </Moment>
                )}
              </span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={openBillingCard(CHARGEBEE.SUBSCRIPTION_DETAILS)}
                >
                  Update
                </button>
              </span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Payment method</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">

                {isLoaded && chargebeeData && chargebeeData.card && (
                  <span>
                    {chargebeeData.card.card_type}
                    {' '}
                    ending in
                    {' '}
                    {chargebeeData.card.last4}
                  </span>
                )}

                {isLoaded && chargebeeData && !chargebeeData.card && (
                  <span>No card on file</span>
                )}

              </span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={openBillingCard(CHARGEBEE.PAYMENT_SOURCES)}
                >
                  Update
                </button>
              </span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Billing History</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow" />
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={openBillingCard(CHARGEBEE.BILLING_HISTORY)}
                >
                  View Invoices
                </button>
              </span>
            </dd>
          </div>

        </dl>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  company: state.companyState.company,
});

export default compose(connect(mapStateToProps), withFirebase)(ChargebeeBillingSection);

import React from 'react';

const TestimonialQuote = (testimonial) => (
  <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
    <div className="relative text-lg font-medium text-white md:flex-grow">
      <svg
        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600"
        fill="currentColor"
        viewBox="0 0 32 32"
      >
        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
      </svg>
      <p className="relative">
        {testimonial.quote}
      </p>
    </div>
    <footer className="mt-6">
      <div className="flex">
        <div className="h-12 w-12 flex-shrink-0 inline-flex rounded-full border-2 border-white">
          <img
            className="h-12 w-12 rounded-full"
            src={testimonial.profileUrl}
            alt=""
          />
        </div>
        <div className="ml-4 text-left">
          <div className="text-base font-medium text-white">{testimonial.author}</div>
          <div className="text-base font-medium text-indigo-200">{testimonial.title}</div>
        </div>
      </div>
    </footer>
  </blockquote>
);

export const TestimonialCaroline = () => {
  const data = {
    quote: 'I found that going to panel events and hearing from other women who are more advanced in their sales careers gave me more perspective. Sales isn’t easy, but I’ve always found that connection with others makes the tough parts of the job feel lighter. WISE is a really special community for that.',
    author: 'Caroline Jones',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_headshot_caroline.jpg?alt=media&token=61a92eb3-a787-45d9-ab37-3c118cff5e1a',
    title: 'Sales Onboarding Program Manager, Electric',
  };

  return TestimonialQuote(data);
};

export const TestimonialMariah = () => {
  const data = {
    quote: 'I join the Leadership Pod once a month and every time I have fruitful conversations. I connect with amazing women and just chat. It truly is organic, it’s fun, and as a leader it makes me feel like I’m not the only one. So I appreciate the experience and the opportunity to connect with women everywhere ',
    author: 'Mariah Pinckney',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_headshot_mariah.jpg?alt=media&token=fb520e30-1d0b-4641-b25a-ec27073259be',
    title: 'Director of Account Management, Attune',
  };

  return TestimonialQuote(data);
};

export const TestimonialJennifer = () => {
  const data = {
    quote: 'WISE Pods and mentorship matching have allowed me to connect with all different types of women in '
      + 'sales, where I am able to bounce ideas outside off of them and talk through specific scenarios. I love '
      + 'being a part of this amazing network!',
    author: 'Jennifer Maizlish',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_headshot_jennifer_maizlish.png?alt=media&token=466eabbc-c0ec-4702-8cbc-3622bbff689b',
    title: 'Account Executive, Gladly',
  };

  return TestimonialQuote(data);
};

export const TestimonialTori = () => {
  const data = {
    quote: 'The Pods are phenomenal for networking - great people, great content. The group sizes are just right '
    + 'for a productive hour-long conversation.',
    author: 'Tori Belkin',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_headshot_tori_belkin.png?alt=media&token=79cf16ae-dfae-400a-8741-2ac7f6b09e31',
    title: 'Head of Revenue Enablement, Ceros',
  };

  return TestimonialQuote(data);
};

export const TestimonialCymantha = () => {
  const data = {
    quote: 'Since I joined WISE I have been fortunate to meet so many incredible women. These women have advised '
    + 'me on how to overcome tough negotiations with clients, provided support in one of the toughest years '
    + 'for sales, and mentored me for an internal interview at my current company which I ended up getting!',
    author: 'Cymantha Rogers',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_headshot_cymantha_rogers.png?alt=media&token=9bbf31eb-d3cf-406e-ad61-997e1f5fe32e',
    title: 'Customer Success Manager, Salsify',
  };

  return TestimonialQuote(data);
};

import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { connect } from 'react-redux';

import * as ROUTES from 'constants/routes';
import CBCheckoutButton from 'components/Checkout';
import ContinueWithBasic from 'components/ContinueWithBasic';
import { TestimonialCaroline, TestimonialMariah } from './MemberTestimonials';

const benefits = [
  {
    title: 'Peer-To-Peer Networking',
    description: 'Unlimited access to WISE Pods, an easy way to network with other members.',
    // logo: 'M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z',
  },
  {
    title: 'Mentorship Matching',
    description: 'Get paired with a mentor or mentee in our community based on your goals.',
    // logo: 'M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z',
  },
  {
    title: 'Slack Group',
    description: 'Ask questions, share knowledge, and cultivate relationships with other members.',
    // logo: 'M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z',
  },
  {
    title: 'Live Events',
    description: 'Attend member-only events and enjoy free admission to all public WISE events.',
    // logo: 'M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z',
  },
  {
    title: 'Job Board',
    description: 'Explore and apply for open roles at our partner companies.',
    // logo: 'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
  },
  {
    title: 'Expert Advice',
    description:
      'From curated resources to our dedicated advice email, our goal is to equip you at every step of your career journey.',
    // logo: 'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
  },
];

const Testimonials = () => (
  <div>
    <div className="">
      <TestimonialCaroline />
    </div>

    <div className="hidden lg:block py-8">
      <TestimonialMariah />
    </div>
  </div>
);

const AnnualPlan = ({ authUser }) => (
  <div className="h-full">
    <div className="space-y-4 sm:space-y-0 sm:grid sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-1">
      <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
        <div className="p-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">WISE Annual Membership Plan</h2>
          <p className="mt-4 text-sm text-gray-500" />
          <p className="mt-8">
            <span className="text-4xl font-extrabold text-gray-900">$120</span>
            <span className="text-base font-medium text-gray-500">&nbsp;/year</span>
          </p>

          <p className="mt-2 text-sm text-gray-500">With a 30-day free trial</p>
          <p className="mt-6 text-gray-600">
            At $10/month, our annual membership plan includes an initial 30-day free trial to get a sense of what
            membership is all about.
          </p>

        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Member benefits</h3>
          <ul className="mt-6 space-y-4">
            {benefits.map((feature) => (
              <li key={feature.title} className="flex space-x-3">
                <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                <span className="text-sm text-gray-500">
                  <b>
                    {feature.title}
                    :
                  </b>
                  {' '}
                  {feature.description}
                </span>
              </li>
            ))}
          </ul>

          <CBCheckoutButton planId="WISE-Individual-Membership-Annual-USD-Yearly" />
          <ContinueWithBasic />
        </div>
      </div>
    </div>

    <div className="mt-8 bg-gray-50 text-gray-600 rounded-lg shadow-sm sm:col-span-2 px-6 py-8">
      <div>
        Is your company a WISE partner?
        {' '}
        <a
          href={ROUTES.EXTERNAL_PAGE_ZENDESK_ACTIVATE_MEMBERSHIP}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary underline font-semibold"
        >
          Learn about how to activate your free membership through your company.
        </a>
      </div>
      <div className="mt-2">
        Learn more about how your current employer may
        {' '}
        <a
          href={ROUTES.EXTERNAL_PAGE_REIMBURSEMENT_FAQ}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary underline font-semibold"
        >
          cover your membership for you
        </a>
        .
      </div>
    </div>
  </div>
);

const PricingPage = ({ authUser }) => (
  <div className="relative">
    <div className="w-full lg:absolute lg:inset-0 lg:min-h-screen mx-auto">
      <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-5/12 bg-primary px-4 sm:px-8 xl:px-24 2xl:pl-44 2xl:pr-24 pt-16 pb-8 sm:pt-8 sm:pb-16 md:pt-8 md:pb-16 lg:pt-16 lg:pb-8 xl:pt-16 xl:pb-8">
        <Testimonials />
      </div>
    </div>
    <div className="relative h-full pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
      <div className="lg:col-start-2">
        <div className="max-w-xl mx-auto lg:max-w-lg lg:ml-auto">
          <AnnualPlan authUser={authUser} />
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(PricingPage);

import React from 'react';
import request from 'request';
import { Menu } from '@headlessui/react';
import { useSelector, connect } from 'react-redux';
import { compose } from 'recompose';

import * as USER from 'models/User';
import * as COMPANY from 'models/Company';
import { withFirebase } from 'components/Firebase';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const updateLicense = async (firebase, authUser, companyId, email, licenseType, action, onUserChanged) => {
  const token = await firebase.auth.currentUser.getIdToken();

  request(
    {
      url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/inviteCompanyUser`,
      method: 'POST',
      json: true,
      headers: { Authorization: `Bearer ${token}` },
      body: {
        company_id: companyId,
        referrer_id: authUser.uid,
        license_type: licenseType,
        email,
        action,
      },
    },
    (err, res, body) => {
      if (res.statusCode === 500) {
        toast.error(body, {
          autoClose: 10000,
          className: 'text-sm',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        let msg;
        if (action === COMPANY.LICENSE_ACTION_ADD) {
          msg = `${licenseType} license has been added and the user will receive an email notification.`;
        } else if (action === COMPANY.LICENSE_ACTION_REMOVE) {
          msg = `${licenseType} license has been removed.`;
        } else {
          msg = `The invitation to sign up has been resent.`;
        }

        toast.success(msg, {
          autoClose: 5000,
          className: 'text-sm',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      onUserChanged();
    }
  );
};

function TeamTableActions({ firebase, authUser, user, onUserChanged }) {
  const company = useSelector((state) => state.companyState.company);

  const makeUpdateLicense = (licenseType, action) => () =>
    updateLicense(firebase, authUser, company.id, user.email, licenseType, action, onUserChanged);

  const baseMenuItems = [
    {
      label: 'Resend Invitation',
      onClick: makeUpdateLicense(null, null),
      enableAction: () =>
        user.membership_status === USER.MEMBERSHIP_STATUS_INVITED ||
        user.membership_status === USER.MEMBERSHIP_STATUS_APP_INCOMPLETE,
    },
    {
      label: 'Add as admin',
      onClick: makeUpdateLicense(USER.ROLE_COMPANY_ADMIN, COMPANY.LICENSE_ACTION_ADD),
      enableAction: () => !user.company_licenses || !user.company_licenses.includes(USER.ROLE_COMPANY_ADMIN),
    },
    {
      label: 'Remove as admin',
      onClick: makeUpdateLicense(USER.ROLE_COMPANY_ADMIN, COMPANY.LICENSE_ACTION_REMOVE),
      enableAction: () => user.company_licenses && user.company_licenses.includes(USER.ROLE_COMPANY_ADMIN),
    },
    {
      label: 'Add as member',
      onClick: makeUpdateLicense(USER.ROLE_MEMBER, COMPANY.LICENSE_ACTION_ADD),
      enableAction: () => !user.company_licenses || !user.company_licenses.includes(USER.ROLE_MEMBER),
    },
    {
      label: 'Remove as member',
      onClick: makeUpdateLicense(USER.ROLE_MEMBER, COMPANY.LICENSE_ACTION_REMOVE),
      enableAction: () => user.company_licenses && user.company_licenses.includes(USER.ROLE_MEMBER),
    },
  ];

  const getMenuItems = () =>
    baseMenuItems.map((item) => (
      <Menu.Item key={item.label}>
        {({ active }) => (
          <button
            disabled={!item.enableAction()}
            onClick={item.onClick}
            className={classNames(
              active ? 'bg-gray-200 text-gray-900 ' : 'text-gray-700',
              'block w-full text-left px-4 py-2 text-sm',
              !item.enableAction() ? 'disabled:opacity-50 bg-gray-100' : ''
            )}
          >
            {item.label}
          </button>
        )}
      </Menu.Item>
    ));

  return (
    <>
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button
            className="mr-2 rounded-full flex items-center text-gray-400 hover:text-gray-600
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            <span className="sr-only">Open options</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
              />
            </svg>
          </Menu.Button>
        </div>
        <Menu.Items
          className="absolute mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
        focus:outline-none"
        >
          <div className="py-1">{getMenuItems()}</div>
        </Menu.Items>
      </Menu>
    </>
  );
}

// export default withFirebase(TeamTableActions);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps), withFirebase)(TeamTableActions);

import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

const MemberResourcesPage = () => (
  <div className="px-4 md:px-6 lg:px-8">
    <div className="grid grid-cols-10 mb-8">
      <div className="col-span-10 lg:col-span-9 xl:col-span-8 gap-y-6">
        <BreadcrumbsItem to={ROUTES.COMPANY_RESOURCES} name="Resources" showSeparator />

        <div className="col-span-2">
          <div className="space-y-3 text-sm text-gray-600">
            <div>
              Our resources page is where you can access additional WISE content!
            </div>
            <div>
              Whether you want to find a podcast episode recommended
              by a recent panelist, learn how to make the most out of your relationship with your mentor, and more, you’ll find everything
              that you need below.
            </div>
          </div>

          <ul className="mt-3 text-sm list-disc text-gray-600 pl-8 space-y-4">
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_MEMBERSHIP_TOOLKIT}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Membership Toolkit
                </a>
              </span>
              <span>
                {' '}
                - Your go-to guide for understanding and utilizing all of the benefits that your WISE membership has
                to offer.
              </span>
            </li>
            <li>
              <span className="font-bold">Mentorship Program Guidelines</span>
              <span>
                {' '}
                - Whether you’re a mentee, mentor, or both, we’ve outlined a few best practices to make the most out of the relationship.
              </span>
              <ul className="mt-3 text-sm list-disc text-gray-600 pl-8 space-y-2">
                <li>
                  <a
                    href={ROUTES.EXTERNAL_PAGE_MENTEE_INFO}
                    className="text-primary font-semibold underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                      Information for Mentees
                  </a>
                </li>
                <li>
                  <a
                    href={ROUTES.EXTERNAL_PAGE_MENTOR_INFO}
                    className="text-primary font-semibold underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                      Information for Mentors
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_MEMBERS_BENEFITS}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Membership Benefits One-Pager
                </a>
              </span>
              <span>
                {' '}
                - Know someone who might benefit from joining WISE? Share this with them!
              </span>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_GOODREADS}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Goodreads
                </a>
              </span>
              <span>
                {' '}
                - Books recommended by our former panelists, women in our community, and the WISE Team.
              </span>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_SPOTIFY}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spotify
                </a>
              </span>
              <span>
                {' '}
                - Check out our Spotify playlist for every podcast that has been recommended by one of our panelists during an event!
              </span>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_YOUTUBE}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </span>
              <span>
                {' '}
                - Watch videos by topic from all our previous events!
              </span>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_WISE_BLOG}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WISE Blog
                </a>
              </span>
              <span>
                {' '}
                - Tactical takeaways from our events, interviews with members, career path resources, and more.
              </span>
            </li>
            <li>
              <span className="font-bold">
                <a
                  href={ROUTES.EXTERNAL_PAGE_CIQ_BLOG}
                  className="text-primary font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Formative Blog
                </a>
              </span>
              <span>
                {' '}
                - Our parent company’s blog on everything from hiring great talent, improving your resume, scaling a team with company growth, and so much more.
              </span>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(MemberResourcesPage);

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { withFirebase } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import { LoaderIcon } from 'utils/Widgets';
import { identifyUser, trackUserEvent } from 'utils/Segment';
import { capitalizeName } from 'utils/Format';
import { hasMemberRole } from 'components/Session/withAuthorization';

const OnboardingPage = () => (
  <div className="bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24 overflow-x-hidden">
    <div className="relative max-w-xl mx-auto">
      <svg
        className="absolute left-full transform translate-x-1/2"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg
        className="absolute right-full bottom-0 transform -translate-x-1/2"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div className="text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Complete Your WISE Account</h2>
        {/* <p className="mt-4 text-lg leading-6 text-gray-500"> */}
        {/*   TODO EDIT: Your profile information will be shared with other WISE members you connect with. */}
        {/* </p> */}
      </div>
      <div className="mt-12">
        <OnboardingForm />
      </div>
    </div>
  </div>
);

function OnboardingFormBase({ authUser, onUpdateAuthUser, firebase, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const schemaJson = {
    first_name: yup.string().trim().required('This field is required'),
    last_name: yup.string().trim().required('This field is required'),
    current_company: yup.string().trim().required('This field is required'),
    current_title: yup.string().trim().required('This field is required'),
    linkedin_url: yup
      .string()
      .trim()
      .required('This field is required')
      .matches(/(linkedin.com\/in)/, 'Please enter your Linkedin URL'),
  };

  if (hasMemberRole(authUser)) {
    schemaJson.intro = yup.string().required('This field is required');
  }

  if (!authUser.cohort_list?.length && hasMemberRole(authUser)) {
    schemaJson.cohort = yup.string().required('This field is required');
  }

  const accountSchema = yup.object().shape(schemaJson);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      first_name: authUser.first_name,
      last_name: authUser.last_name,
      current_company: authUser.current_company,
      current_title: authUser.current_title,
      linkedin_url: authUser.linkedin_url,
      intro: authUser.intro,
    },
    resolver: yupResolver(accountSchema),
  });

  const onSubmit = (data) => {
    // console.log('Submit onboarding', data);
    setIsLoading(true);
    //cohort is stored in array
    if (!authUser.cohort_list?.length) {
      data.cohort_list = [data.cohort];
    }
    //fix linkedin url
    if (!data.linkedin_url.startsWith('http')) {
      data.linkedin_url = `https://${data.linkedin_url}`;
    }
    //save profile photo from google auth
    if (!authUser.photo_url && authUser.socialAccountPhotoURL) {
      data.photo_url = authUser.socialAccountPhotoURL;
    }
    data.first_name = capitalizeName(data.first_name);
    data.last_name = capitalizeName(data.last_name);

    onUpdateAuthUser(data);
    firebase
      .updateUser({ uid: authUser.uid, ...data })
      .then(() => {
        identifyUser(firebase, authUser.uid);
        trackUserEvent(firebase, authUser.uid, 'completed_onboard_form', data);
      })
      .then(() => {
        history.push(ROUTES.HOME);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      <div className="col-span-1">
        <label htmlFor="first_name" className="form-text">
          First name
        </label>
        <input type="text" name="first_name" ref={register} className="form-input" />
        <p className="form-error">{errors.first_name?.message}</p>
      </div>

      <div className="col-span-1">
        <label htmlFor="last_name" className="form-text">
          Last name
        </label>
        <input type="text" name="last_name" ref={register} className="form-input" />
        <p className="form-error">{errors.last_name?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="current_company" className="form-text">
          Company
        </label>
        <input type="text" name="current_company" ref={register} className="form-input" />
        <p className="form-error">{errors.current_company?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="current_title" className="form-text">
          Title
        </label>
        <input type="text" name="current_title" ref={register} className="form-input" />
        <p className="form-error">{errors.current_title?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="linkedin_url" className="form-text">
          LinkedIn URL
        </label>
        <input type="text" name="linkedin_url" ref={register} className="form-input" />
        <p className="form-error">{errors.linkedin_url?.message}</p>
      </div>

      {schemaJson.intro && (
        <div className="col-span-1 sm:col-span-2">
          <label htmlFor="intro" className="form-text">
            About
          </label>
          <textarea
            name="intro"
            rows="3"
            ref={register}
            className="form-input"
            maxLength="250"
            placeholder="Tell us about yourself, and feel free to mix in some personal details! We’ll use this your intro for Pods, Mentorship, and more."
          ></textarea>
          <p className="form-error">{errors.intro?.message}</p>
        </div>
      )}

      {schemaJson.cohort && (
        <div className="col-span-1 sm:col-span-2">
          <label htmlFor="cohort" className="form-text">
            Cohort
          </label>
          <select name="cohort" ref={register} className="form-input">
            <option value="Associate">Associate</option>
            <option value="Leadership">Leadership</option>
            <option value="Executive">Executive</option>
          </select>
          <p className="form-error">{errors.cohort?.message}</p>
          <p className="mt-2 text-sm text-gray-500">
            Please{' '}
            <a
              className="text-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_COHORT_FAQ}
            >
              check here
            </a>{' '}
            to decide which Cohort is best for you!
          </p>
        </div>
      )}

      <div className="col-span-1 sm:col-span-2">
        <button type="submit" className="btn w-full">
          {isLoading && <LoaderIcon />}
          Complete my account
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

const OnboardingForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(OnboardingFormBase);

export default OnboardingPage;

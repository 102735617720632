import React, { useEffect } from 'react';

import { withFirebase } from 'components/Firebase';

const SignOutButtonBase = ({ firebase }) => (
  <button
    onClick={firebase.doSignOut}
    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    role="menuitem"
  >
    Log out
  </button>
);

const SignOutPageBase = ({ firebase }) => {
  useEffect(() => {
    firebase.doSignOut();
  });

  return <div></div>;
};

const SignOutButton = withFirebase(SignOutButtonBase);
const SignOutPage = withFirebase(SignOutPageBase);
export { SignOutPage, SignOutButton };

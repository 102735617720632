import React, { useState } from 'react';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { withFirebase } from 'components/Firebase';
import { LoaderIcon } from 'utils/Widgets';

function PasswordChangeForm({ firebase }) {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('This field is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Must Contain at least 8 characters, one uppercase, one lowercase, and one number',
      ),
    passwordConfirm: yup
      .string()
      .required('This field is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log('Submit PasswordChangeForm', data);
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    firebase
      .doPasswordUpdate(data.password)
      .then(() => {
        setErrorMessage('');
        setSuccessMessage('Your password has been updated.');
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error on PasswordChangeForm', error);
        setSuccessMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-6 grid grid-cols-12 gap-x-6 gap-y-4">
        <div className="col-span-12 md:col-span-6">
          <label htmlFor="password" className="form-text">
            New Password
          </label>
          <input type="password" name="password" ref={register} className="form-input" />
          {errors.password?.message && <p className="form-error">{errors.password?.message}</p>}
        </div>

        <div className="col-span-12 md:col-span-6">
          <label htmlFor="passwordConfirm" className="form-text">
            Confirm New Password
          </label>
          <input type="password" name="passwordConfirm" ref={register} className="form-input" />
          {errors.passwordConfirm?.message && <p className="form-error">{errors.passwordConfirm?.message}</p>}
        </div>
      </div>

      <div className="pt-2">
        {!formState.isSubmitting && formState.isSubmitted && <div className="form-success">{successMessage}</div>}

        {!formState.isSubmitting && formState.isSubmitted && <div className="form-error">{errorMessage}</div>}

        <button type="submit" className="mt-2 w-40 btn">
          {isLoading && <LoaderIcon />}
          Reset Password
        </button>
      </div>
    </form>
  );
}

export default compose(
  withFirebase,
)(PasswordChangeForm);

import { Collection } from 'fireorm';

export const ACCOUNT_STATUS_INCOMPLETE = '1 - Application Incomplete';
export const ACCOUNT_STATUS_SUBMITTED = '2 - Application Submitted';
export const ACCOUNT_STATUS_ACTIVE = '3 - Active';
export const ACCOUNT_STATUS_APP_DENIED = '4 - Denied';
export const ACCOUNT_STATUS_ENDED = '5 - Churned';

export const DEFAULT_TEAM_ADMIN_LICENSE_COUNT = 2;
export const DEFAULT_TEAM_MEMBER_LICENSE_COUNT = 10;
export const DEFAULT_TEAM_IMAGES_COUNT = 4;
export const DEFAULT_TEAM_TESTIMONIALS_COUNT = 1;
export const DEFAULT_TEAM_ARTICLES_COUNT = 3;

export const DEFAULT_CORPORATE_ADMIN_LICENSE_COUNT = 5;
export const DEFAULT_CORPORATE_MEMBER_LICENSE_COUNT = 25;
export const DEFAULT_CORPORATE_IMAGES_COUNT = 12;
export const DEFAULT_CORPORATE_TESTIMONIALS_COUNT = 3;
export const DEFAULT_CORPORATE_ARTICLES_COUNT = 3;

export const LICENSE_ACTION_ADD = 'add';
export const LICENSE_ACTION_REMOVE = 'remove';

export const ACCOUNT_TYPE_TEAM = 'Team';
export const ACCOUNT_TYPE_CORPORATE = 'Corporate';


export class TestimonialModel {
  id:string;
  company_id:string;
  creator_id:string;
  executive_full_name:string;
  executive_linkedin_url:string;
  testimonial_title:string;
  testimonial_image:string;
  testimonial_text:string;
  creation_time:string;
  update_time: string;
}

export class ArticleModel {
  id:string;
  company_id:string;
  creator_id:string;
  article_url:string;
  article_image:string;
  article_title:string;
  article_description:string;
  creation_time:string;
  update_time: string;
}

@Collection('companies')
export class CompanyModel {
  id: string;
  company_name: string;
  account_status: string;
  admin_license_used_count: number;
  admin_license_max_count: number;
  membership_license_used_count: number;
  membership_license_max_count: number;

  cb_customer_id: string;
  cb_subscription_status: string;
  membership_startdate: Date;
  membership_enddate: Date;
  account_type: string;

  //fields added duration application
  applicant_email: string;
  applicant_firstname: string;
  applicant_lastname: string;
  applicant_name: string;
  applicant_title: string;
  location: string;
  company_url: string;
  signup_time: Date;
  total_headcount: string;

  //fields for company page
  profile_progress:number;
  career_url:string;
  public_page_id:string;
  short_description:string;
  long_description: string;
  square_logo: string;
  images: Array<string>;
  images_used_count:number;
  images_max_count:number;

  testimonials: Array<TestimonialModel>;
  testimonials_used_count:number;
  testimonials_max_count:number;

  articles: Array<ArticleModel>;
  articles_used_count:number;
  articles_max_count:number;
}

export const EVENT_STATUS_DRAFT = '1 - Draft';
export const EVENT_STATUS_PRESIGNUP = '2 - Published / Pre-signup';
export const EVENT_STATUS_SIGNUP_OPEN = '3 - Published / Signup open';
export const EVENT_STATUS_SIGNUP_CLOSED = '4 - Published / Signup closed';
export const EVENT_STATUS_COMPLETED = '5 - Ended / Event completed';
export const EVENT_STATUS_CANCELLED = '6 - Ended / Event cancelled';
export const EVENT_STATUS_SPLIT = '7 - Deleted / Split into subgroups';

export const EVENT_USER_REGISTERED = 'registered';
export const EVENT_USER_CANCELLED = 'cancelled';

//TODO: add model for event collection
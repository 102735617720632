import React from 'react';

import * as ROUTES from 'constants/routes';
import BenefitsSection from './MemberBenefits';

const UpgradeCompletePage = () => (
  <div className="relative bg-primary">
    <main className="lg:relative">
      <div className="bg-white relative w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
        <div className="py-16 px-4 sm:px-6 lg:px-8 lg:py-16 overflow-x-hidden">
          <div className="relative max-w-xl mx-auto">
            <h2 className="mt-10 text-2xl font-semibold">Thank you for upgrading to a premium membership</h2>
            <p className="mt-8 text-xl">
              You can now access all of the premium content on the WISE platform. Get ready to bring your career to the
              next level!{' '}
              <a href={ROUTES.HOME} className="text-primary underline">
                Go back to the homepage
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="lg:min-h-screen mx-auto w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
        <BenefitsSection />
      </div>
    </main>
  </div>
);

export default UpgradeCompletePage;

import React, { useRef, useEffect } from 'react';
import { compose } from 'recompose';
import * as typeformEmbed from '@typeform/embed';

import { withAuthorization } from 'components/Session';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

const MentorshipPage = () => {
  const typeformRef = useRef(null);
  useEffect(() => {
    typeformEmbed.makeWidget(typeformRef.current, ROUTES.EXTERNAL_PAGE_MENTOR_TYPEFORM, {
      hideFooter: false,
      hideHeaders: false,
      opacity: 50,
    });
  }, [typeformRef]);

  return (
    <div className="px-4 md:px-6 lg:px-8">
      <div className="grid grid-cols-10 mb-8">
        <div className="col-span-10 lg:col-span-9 xl:col-span-8 gap-y-6">
          <BreadcrumbsItem to={ROUTES.MENTORSHIP} name="Mentorship" showSeparator={true} />

          <div className="col-span-2">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              <span className="block">Mentorship</span>
            </h2>

            <div className="mt-3 text-md text-gray-600">
              Whether you’re interested in finding a mentor or giving back and being a mentor, this is the place to do
              it.
            </div>
            <div className="mt-3 text-md text-gray-600">
              Our mentors and mentees are WISE members with all different levels and types of sales experience, and
              we’ll pair you with someone based on what each person is seeking.
            </div>
            <div className="mt-3 text-md text-gray-600">
              Simply fill out this form and we’ll start working on finding a match for you! You can expect to hear from
              a WISE team member a few weeks after your submission.
            </div>
          </div>

          <div ref={typeformRef} className="h-screen col-span-2 lg:col-span-1" style={{ height: '50vh' }}></div>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(MentorshipPage);

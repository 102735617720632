import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { Widget } from "@uploadcare/react-widget";

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import ProfilePhotoLoader from 'pages/Account/ProfilePhotoLoader';
import FlashMessage from 'components/FlashMessage';
import { LoaderIcon } from 'utils/Widgets';
import * as ROUTES from 'constants/routes';
import { identifyUser } from 'utils/Segment';
import { capitalizeName } from 'utils/Format';

function UserProfileForm({ authUser, onUpdateAuthUser, firebase }) {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const accountSchema = yup.object().shape({
    first_name: yup.string().trim().required('This field is required'),
    last_name: yup.string().trim().required('This field is required'),
    current_company: yup.string().trim(),
    current_title: yup.string().trim(),
    linkedin_url: yup.string().trim().required('This field is required')
      .matches(
        /(https:\/\/)(.*)(linkedin.com\/in)/,
        'Please enter a valid Linkedin URL starting with https://',
      ),
    intro: yup.string().trim(),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    defaultValues: {
      first_name: authUser.first_name,
      last_name: authUser.last_name,
      current_company: authUser.current_company,
      current_title: authUser.current_title,
      intro: authUser.intro,
      linkedin_url: authUser.linkedin_url,
    },
    resolver: yupResolver(accountSchema),
  });

  const onSubmit = (data) => {
    // console.log('Submit UserProfileForm', data);
    setIsLoading(true);

    data.first_name = capitalizeName(data.first_name);
    data.last_name = capitalizeName(data.last_name);

    firebase.updateUser({ uid: authUser.uid, ...data }).then(() => {
      onUpdateAuthUser(data);
      setErrorMessage('');
      setSuccessMessage('Your profile has been updated.');
      identifyUser(firebase, authUser.uid);
      setIsLoading(false);
    })
      .catch((error) => {
        console.log('Error on UserProfileForm', error);
        setSuccessMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (

    <div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Member Profile</h3>
        <p className="mt-1 text-sm text-gray-500">Your profile is visible to other members you connect with in the WISE Portal.</p>
      </div>

      <div className="mt-6 gap-x-6 gap-y-4">
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Profile photo
          </label>
          <div className="flex items-center">
            <ProfilePhotoLoader />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="mt-6 grid grid-cols-12 gap-x-6 gap-y-4">
          <div className="col-span-12 md:col-span-12 lg:col-span-6 lg:col-start-1 md:col-start-1">
            <label htmlFor="first_name" className="form-text">First name</label>
            <input type="text" name="first_name" ref={register} className="form-input" />
            {errors.first_name?.message && (<p className="form-error">{errors.first_name?.message}</p>)}
          </div>

          <div className="col-span-12 md:col-span-12 lg:col-span-6">
            <label htmlFor="first_name" className="form-text">Last name</label>
            <input type="text" name="last_name" ref={register} className="form-input" />
            {errors.last_name?.message && (<p className="form-error">{errors.last_name?.message}</p>)}
          </div>

          <div className="col-span-12 md:col-span-12 lg:col-span-6 lg:col-start-1 md:col-start-1">
            <label htmlFor="current_company" className="form-text">
              Current Company (or most recent)
            </label>
            <input type="text" name="current_company" ref={register} maxLength="50" className="form-input" />
            {errors.current_company?.message && (<p className="mt-1 text-sm text-red-700">{errors.current_company?.message}</p>)}
          </div>

          <div className="col-span-12 md:col-span-12 lg:col-span-6">
            <label htmlFor="current_title" className="form-text">
              Current title (or most recent)
            </label>
            <input type="text" name="current_title" ref={register} maxLength="50" className="form-input" />
            {errors.current_title?.message && (<p className="mt-2 text-sm text-red-700">{errors.current_title?.message}</p>)}
          </div>

          <div className="col-span-12">
            <label htmlFor="linkedin_url" className="form-text">LinkedIn URL</label>
            <input type="text" name="linkedin_url" ref={register} className="form-input" />
            {errors.linkedin_url?.message && (<p className="mt-2 text-sm text-red-700">{errors.linkedin_url?.message}</p>)}
          </div>

          <div className="col-span-12">
            <label htmlFor="intro" className="form-text">About</label>
            <div className="mt-1">
              <textarea
                name="intro"
                rows="3"
                ref={register}
                className="form-input"
                maxLength="250"
                placeholder="Tell us about yourself, and feel free to mix in some personal details! We’ll use this your intro for Pods, Mentorship, and more."
              />
              {/* <p className="mt-2 text-sm text-gray-500"> */}
              {/*   Characters Left: 0 */}
              {/* </p> */}
            </div>
          </div>

          <div className="col-span-12">
            <label htmlFor="cohort" className="form-text">Cohort</label>
            <input type="text" name="cohort" value={authUser.cohort_list} disabled className="disabled:opacity-50 form-input" />
            <p className="mt-2 text-sm text-gray-500">
              Your Cohort is determined based on your membership application, and you can reference everything you need to know about Cohorts
              {' '}
              <a className="text-primary underline" target="_blank" rel="noopener noreferrer" href={ROUTES.EXTERNAL_PAGE_COHORT_FAQ}>here</a>
              .
            </p>
          </div>

        </div>

        <div className="pt-2">
          {!formState.isSubmitting && formState.isSubmitted && (
          <FlashMessage duration={5000} styleName="success">{ successMessage }</FlashMessage>
          )}

          {!formState.isSubmitting && formState.isSubmitted && (
          <FlashMessage duration={5000} styleName="error">{ errorMessage }</FlashMessage>
          )}

          <button type="submit" className="mt-2 w-40 btn">

            {isLoading && (<LoaderIcon />)}

            Save
          </button>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withFirebase,
)(UserProfileForm);

import React from 'react';

import BenefitsSection from './MemberBenefits';

const MembershipDeniedPage = () => (
  <div className="relative bg-primary">
    <main className="lg:relative">
      <div className="lg:min-h-screen mx-auto w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
        <BenefitsSection />
      </div>

      <div className="bg-white relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
        <div className="py-16 px-4 sm:px-6 lg:px-8 lg:py-16 overflow-x-hidden">
          <div className="relative max-w-xl mx-auto">
            <h2 className="mt-10 text-2xl font-semibold">
              Whoops! Unfortunately it looks like this email is not associated with an active WISE membership.
            </h2>
            <p className="mt-8 text-xl">
              Please reference our
              {' '}
              <a
                href="https://womeninsaleseverywhere.zendesk.com/hc/en-us/categories/360003978472-Membership-FAQ"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                Membership Application FAQs
              </a>
              {' '}
              to learn more!
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default MembershipDeniedPage;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from 'components/Firebase';
import { isUserRegistered, isBeforeEvent } from 'utils/Event';
import * as EVENT from 'models/Event';
const request = require('request');

class PodCancelBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  triggerUnregister = () => {
    console.log('unregister user for event', this.props);
    this.props.firebase.unregisterEvent(this.props.authUser, this.props.event.id);
    this.props.onSetEventStatus(this.props.event.id, this.props.authUser.uid, 'cancelled');
    this.setShowModal(false);

    const requestBody = {
      user_id: this.props.authUser.uid,
      event_id: this.props.event.id,
    };

    this.props.firebase.auth.currentUser.getIdToken().then(function (token) {
      request(
        {
          url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/processUserCancelSignup`,
          method: 'POST',
          json: true,
          headers: { Authorization: `Bearer ${token}` },
          body: requestBody,
        },
        (err, res, body) => {
          if (err) {
            return console.log(err);
          }
          console.log(body);
        }
      );
    });
  };

  setShowModal = (show) => {
    this.setState({ showModal: show });
  };

  render() {
    const { event, authUser } = this.props;

    return (
      <>
        {isUserRegistered(event, authUser) && isBeforeEvent(event) && (
          <button type="button" onClick={() => this.setShowModal(true)} className="btn">
            <svg
              className="h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>Cancel</span>
          </button>
        )}

        {this.state.showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-5 flex-auto">
                    {event.status === EVENT.EVENT_STATUS_SIGNUP_CLOSED && (
                      <>
                        <h2 className="text-2xl font-semibold">Are you sure you want to cancel?</h2>
                        <p className="my-4 text-gray-600 text-sm leading-relaxed">
                          This Pod is already confirmed - canceling may impact your ability to participate in future Pod
                          sessions.
                        </p>
                      </>
                    )}

                    {event.status !== EVENT.EVENT_STATUS_SIGNUP_CLOSED && (
                      <p className="my-4 text-gray-600 text-lg leading-relaxed">
                        <h2 className="text-2xl font-semibold">Are you sure you want to cancel?</h2>
                        <p className="my-4 text-gray-600 text-sm leading-relaxed">
                          We understand - hopefully you’ll be able to sign up for another one soon!
                        </p>
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-end p-5 border-t border-solid border-gray-300 rounded-b">
                    <button
                      type="button"
                      className="mr-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => this.setShowModal(false)}
                    >
                      No, Nevermind
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-red-300 text-sm leading-4 font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => this.triggerUnregister()}
                    >
                      Yes, Cancel Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-auto"></div>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  event: (state.eventState.events || {})[props.eventId],
});

const mapDispatchToProps = (dispatch) => ({
  onSetEventStatus: (eventId, userId, status) => dispatch({ type: 'EVENT_STATUS_SET', eventId, userId, status }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(PodCancelBtn);

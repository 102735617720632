import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import FlashMessage from 'components/FlashMessage';
import { LoaderIcon, IsScreenMobile } from 'utils/Widgets';
import ReactTooltip from 'react-tooltip';
import RequirementsSection from "./RequirementsSection";

const CompanyBasicInformationForm = ({ firebase, company }) => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState({ short_description: null, long_description: null });

  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';

  const companySchema = yup.object().shape({
    company_name: yup.string().trim().required('This field is required'),
    company_url: yup.string().trim().required('This field is required')
      .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Please enter a valid URL'),
    career_url: yup.string().trim().required('This field is required')
      .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Please enter a valid URL'),
    short_description: yup.string().trim().required('This field is required'),
      // .max(200, 'Keyword must not exceed 200 characters'),
    long_description: yup.string().trim(),
      // .max(650, 'Keyword must not exceed 650 characters'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    defaultValues: {
      company_name: company.company_name,
      company_url: company.company_url,
      career_url: company.career_url,
      short_description: company.short_description,
      long_description: company.long_description,
    },
    resolver: yupResolver(companySchema),
  });

  const handleInputChange = (event) => {
    setCount({
      ...count,
      [event.target.name]: event.target.value.length,
    });
  };

  const onSubmit = (data) => {
    data.public_page_id = data.company_name.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    // console.log('Submit CompanyBasicInformationForm', data);
    setIsLoading(true);
    firebase.updateCompany(authUser.company_id, data).then(async () => {
      setErrorMessage('');
      setSuccessMessage('The company has been updated.');
      setIsLoading(false);
      const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
      dispatch({ type: 'SET_COMPANY', company: companyData });
    })
      .catch((error) => {
        // console.log('Error on CompanyBasicInformationForm', error);
        setSuccessMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (

    <div className="pb-6">
      <div className="grid grid-cols-12">
        <h3 className="col-span-5 text-lg leading-6 font-medium text-gray-900" data-tut={`${tourClassPrefix}__companyProfile`}>
          Edit Profile Information
        </h3>
      </div>
      <div className="grid grid-cols-12">

        <p className="mt-3 text-sm text-gray-500 italic col-span-12">
          This is where you upload all of your company information that will be used on your spotlight page on our website.
        </p>
        <p className="mt-1 mb-3 text-sm text-gray-500 italic col-span-12">
          If you change information at any time, your company's page will automatically reflect what you've updated, so we
          encourage you to check your page in real-time after you've made an update to ensure it looks how you'd like.
        </p>
        <div className="col-span-12 pt-2">
          <RequirementsSection />
        </div>
        <div className="col-span-12 text-right max-w-xl py-2">

          <p className="form-text text-left">
            This is the link to your spotlight page
          </p>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                type="text"
                id="company_public_url"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder={company.profile_progress >= 100 ? `app.womeninsaleseverywhere.com/companies/${company.public_page_id}` : 'app.womeninsaleseverywhere.com/companies/yourcompany'}
                disabled
              />
            </div>
            <div
              data-tip="Go to spotlight page"
              data-for="goToPublicPage"
            >
              <button
                type="button"
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 disabled:opacity-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                disabled={company.profile_progress < 100}
                onClick={() => {
                  const win = window.open(`/companies/${!company.public_page_id ? '' : company.public_page_id}`, '_blank');
                  win.focus();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </button>
            </div>
            <ReactTooltip
              id="goToPublicPage"
              place="top"
              backgroundColor="gray"
              effect="solid"
              multiline
            />
            <div
              data-tip="Copy to Clipboard"
              data-for="copyToClipboard"
            >
              <button
                type="button"
                disabled={company.profile_progress < 100}
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 disabled:opacity-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                onClick={() => {
                  navigator.clipboard.writeText(`https://app.womeninsaleseverywhere.com/companies/${company.public_page_id}`);
                  toast.success('Text copied to clipboard!', {
                    autoClose: 5000,
                    className: 'text-sm',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
                  <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
                </svg>
              </button>
            </div>
            <ReactTooltip
              id="copyToClipboard"
              place="top"
              backgroundColor="gray"
              effect="solid"
              multiline
            />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="mt-2 grid grid-cols-12 gap-x-6 gap-y-4">

          <div id="company_name" className="col-span-12">
            <label htmlFor="company_name" className="form-text">
              Company Name
              <p className="text-red-700 inline">*</p>
            </label>
            <input type="text" name="company_name" ref={register} className="form-input" />
            {errors.company_name?.message && (<p className="mt-2 text-sm text-red-700">{errors.company_name?.message}</p>)}
          </div>

          <div id="company_url" className="col-span-12">
            <label htmlFor="company_url" className="form-text">
              Website URL
              <p className="text-red-700 inline">*</p>
            </label>
            <input type="text" name="company_url" ref={register} className="form-input" />
            {errors.company_url?.message && (<p className="mt-2 text-sm text-red-700">{errors.company_url?.message}</p>)}
          </div>

          <div id="career_url" className="col-span-12">
            <label htmlFor="career_url" className="form-text">
              Career Page URL
              <p className="text-red-700 inline">*</p>
            </label>
            <input type="text" name="career_url" ref={register} className="form-input" />
            {errors.career_url?.message && (<p className="mt-2 text-sm text-red-700">{errors.career_url?.message}</p>)}
          </div>

          <div id="short_description" className="col-span-12">
            <label htmlFor="short_description" className="form-text">
              Short Description
              <p className="text-red-700 inline">*</p>
            </label>
            <div className="mt-1">
              <textarea
                name="short_description"
                rows="2"
                onChange={(e) => handleInputChange(e)}
                ref={register}
                className="form-input"
                placeholder="A very high-level description of your company and what it does."
              />
              <div className="grid grid-cols-2">
                <p className="mt-2 text-sm text-red-700 col-span-1">
                  {errors.short_description?.message && (errors.short_description?.message)}
                </p>
                {/*                <p className="mt-2 text-sm text-gray-500 text-right col-span-1">
                  {count.short_description && (`Characters Left: ${50 - count.short_description}`)}
                </p> */}
              </div>
            </div>
          </div>

          <div id="long_description" className="col-span-12">
            <label htmlFor="long_description" className="form-text">
              Long Description
              <p className="text-red-700 inline">*</p>
            </label>
            <div className="mt-1">
              <textarea
                name="long_description"
                rows="5"
                onChange={(e) => handleInputChange(e)}
                ref={register}
                className="form-input"
                placeholder="A more detailed description of your company with context on the problem you're solving, who you're helping, etc."
              />
              <div className="grid grid-cols-2">
                <p className="mt-2 text-sm text-red-700 col-span-1">
                  {errors.long_description?.message && (errors.long_description?.message)}
                </p>
                {/*                <p className="mt-2 text-sm text-gray-500 text-right col-span-1">
                  {count.long_description && (`Characters Left: ${200 - count.long_description}`)}
                </p> */}
              </div>
            </div>
          </div>

        </div>

        <div className="pt-2">
          {!formState.isSubmitting && formState.isSubmitted && (
            <FlashMessage duration={5000} styleName="success">{ successMessage }</FlashMessage>
          )}

          {!formState.isSubmitting && formState.isSubmitted && (
            <FlashMessage duration={5000} styleName="error">{ errorMessage }</FlashMessage>
          )}

          <button type="submit" className="mt-2 w-40 btn">

            {isLoading && (<LoaderIcon />)}

            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(CompanyBasicInformationForm);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import history from 'components/History';
import { withFirebase } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import { LoaderIcon } from 'utils/Widgets';
import { identifyUser } from 'utils/Segment';
import { capitalizeName } from 'utils/Format';
import BenefitsSection from './MemberBenefits';

const MemberApplicationPage = () => (
  <div className="relative bg-primary">
    <main className="lg:relative">
      <div className="lg:min-h-screen mx-auto w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
        <BenefitsSection />
      </div>
      <div className="bg-white relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
        <div className="py-16 px-4 sm:px-6 lg:px-8 lg:py-24 overflow-x-hidden">
          <div className="relative max-w-xl mx-auto">
            <div className="max-w-xl mx-auto lg:max-w-lg lg:ml-auto">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-extrabold tracking-tight text-primary-dark sm:text-4xl">
                  Apply for WISE Membership
                </h2>
                <p className="mt-4 text-lg leading-6 text-gray-500" />
              </div>
              <MemberApplicationForm />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);
const reasonsOptions = [
  { label: 'To find or become a mentor' },
  { label: 'General networking and growing my professional community' },
  { label: 'Sales training and career development' },
  { label: 'Find my next opportunity' },
];

const informationOptions = [
  { label: 'Online search' },
  { label: 'Social media' },
  { label: 'Personal referral' },
  { label: 'From a member of the WISE Team' },
  { label: 'Formative Search Partners (fka CloserIQ)' },
  { label: 'WISE Event' },
  { label: 'WISE Job Board' },
  { label: 'Other' },
];

function MemberApplicationFormBase({
  authUser, onUpdateAuthUser, firebase,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const accountSchema = yup.object().shape({
    first_name: yup.string().trim().required('This field is required'),
    last_name: yup.string().trim().required('This field is required'),
    current_company: yup.string().trim().required('This field is required'),
    current_location: yup.string().trim().required('This field is required'),
    current_title: yup.string().trim().required('This field is required'),
    reason_for_joining_wise: yup.string().trim().required('This field is required'),
    how_did_you_hear: yup.string().trim().required('This field is required'),
    linkedin_url: yup
      .string()
      .trim()
      .required('This field is required')
      .matches(/(linkedin.com\/in)/, 'Please enter your Linkedin URL'),
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      first_name: authUser.first_name,
      last_name: authUser.last_name,
      current_company: authUser.current_company,
      current_title: authUser.current_title,
      current_location: authUser.current_location,
      linkedin_url: authUser.linkedin_url,
      reason_for_joining_wise: authUser.reason_for_joining_wise,
      how_did_you_hear: authUser.how_did_you_hear,
    },
    resolver: yupResolver(accountSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    data.first_name = capitalizeName(data.first_name);
    data.last_name = capitalizeName(data.last_name);

    // fix linkedin url
    if (!data.linkedin_url.startsWith('http')) {
      data.linkedin_url = `https://${data.linkedin_url}`;
    }

    onUpdateAuthUser(data);
    firebase
      .updateUser({ uid: authUser.uid, ...data })
      .then(() => {
        identifyUser(firebase, authUser.uid);
      })
      .then(() => {
        history.push(ROUTES.PRICING);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      <div className="col-span-1">
        <label htmlFor="first_name" className="form-text">
          First name
        </label>
        <input type="text" name="first_name" ref={register} className="form-input" />
        <p className="form-error">{errors.first_name?.message}</p>
      </div>

      <div className="col-span-1">
        <label htmlFor="last_name" className="form-text">
          Last name
        </label>
        <input type="text" name="last_name" ref={register} className="form-input" />
        <p className="form-error">{errors.last_name?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="current_company" className="form-text">
          Current Company (or most recent)
        </label>
        <input type="text" name="current_company" ref={register} className="form-input" />
        <p className="form-error">{errors.current_company?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="current_title" className="form-text">
          Current title (or most recent)
        </label>
        <input type="text" name="current_title" ref={register} className="form-input" />
        <p className="form-error">{errors.current_title?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="linkedin_url" className="form-text">
          LinkedIn URL
        </label>
        <input type="text" name="linkedin_url" ref={register} className="form-input" />
        <p className="form-error">{errors.linkedin_url?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="current_location" className="form-text">
          City
        </label>
        <input type="text" name="current_location" ref={register} className="form-input" />
        <p className="form-error">{errors.current_location?.message}</p>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="reason_for_joining_wise" className="form-text">
          What is your top reason for joining WISE?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select id="reason_for_joining_wise" name="reason_for_joining_wise" ref={register} className="form-input">
            <option hidden value="">
              Please select an option
            </option>
            {reasonsOptions.map((option) => (
              <option value={option.label} key={option.label}>
                {option.label}
              </option>
            ))}
          </select>
          <p className="form-error">{errors.reason_for_joining_wise?.message}</p>
        </div>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="how_did_you_hear" className="form-text">
          How did you hear about WISE?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select id="how_did_you_hear" name="how_did_you_hear" ref={register} className="form-input">
            <option hidden value="">
              Please select an option
            </option>
            {informationOptions.map((option) => (
              <option value={option.label} key={option.label}>
                {option.label}
              </option>
            ))}
          </select>
          <p className="form-error">{errors.how_did_you_hear?.message}</p>
        </div>
      </div>

      <div className="col-span-1 sm:col-span-2">
        <button type="submit" className="btn w-full">
          {isLoading && <LoaderIcon />}
          Continue
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

const MemberApplicationForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
)(MemberApplicationFormBase);

export default MemberApplicationPage;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import request from 'request';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as ROUTES from 'constants/routes';
import { withFirebase } from 'components/Firebase';
import { SignInGoogle } from 'pages/SignIn';
import Logo from 'assets/wise-light-transparent.png';
import FlashMessage from 'components/FlashMessage';
import { LoaderIcon } from 'utils/Widgets';
import { identifyUser, trackUserEvent } from 'utils/Segment';
import history from 'components/History';

const SignUpPage = ({ firebase, onUpdateAuthUser }) => {
  const query = new URLSearchParams(useLocation().search);
  const companyId = query.get('company_id');
  const userEmail = query.get('email');

  const [company, setCompany] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getCompany = async (companyId) => {
    if (companyId) {
      let companyData;
      const queryCompanySnapshot = await firebase.company(companyId).get();
      companyData = Object.assign(queryCompanySnapshot.data(), { id: queryCompanySnapshot.id });
      setCompany(companyData);
    }
  };

  const checkExistingUser = async (userEmail) => {
    let existingSignIn = [];
    if (userEmail) {
      existingSignIn = await firebase.doFetchSignInMethodsForEmail(userEmail);
      if (existingSignIn.length > 0) {
        history.push(ROUTES.SIGN_IN, { email: userEmail, userAlreadyExists: true });
      }
    } else if (existingSignIn.length === 0) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const asyncEffects = async () => {
      await getCompany(companyId);
      await checkExistingUser(userEmail);
    };
    asyncEffects();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href={ROUTES.EXTERNAL_PAGE_HOME}><img className="mx-auto h-12 w-auto" src={Logo} alt="WISE" /></a>
        <h3 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {!isLoading && !company && 'Elevate your network and career'}
          {!isLoading && company && `Create an account to join your ${company.company_name} team on WISE.`}
        </h3>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Create an account to access the WISE community, job opportunities, mentorship matches & more.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {!isLoading && !companyId && (
            <>
              <SignInGoogle label="Get started with google" />
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">OR</span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="mt-6 grid grid-cols-1 gap-3">
            <SignUpForm company={company} firebase={firebase} onUpdateAuthUser={onUpdateAuthUser} />
          </div>

          <div className="mt-4 text-sm text-gray-700">
            Already have an account?&nbsp;
            <Link className="underline" to={ROUTES.SIGN_IN}>
              Log in
            </Link>
          </div>

          <div className="mt-2 text-xs text-gray-700">
            By using WISE you are agreeing to our&nbsp;
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_PRIVACY}
            >
              privacy policy
            </a>
            &nbsp;and&nbsp;
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_TERMS}
            >
              terms
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

const ERROR_CODES = {
  'auth/email-already-in-use':
    'You have already started an application or have an account. Please try to login with this email or use the password reset option.',
  'auth/invalid-email': 'Please enter a valid email address.',
  'auth/weak-password': 'Please use a stronger password.',
};

const doMembershipSignUp = (firebase, history, onUpdateAuthUser, user_uid, email) =>
  // create new user for individuals or invited company users
  firebase.auth.currentUser.getIdToken().then((token) => request(
    {
      url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/createUser`,
      method: 'POST',
      json: true,
      headers: { Authorization: `Bearer ${token}` },
      body: {
        uid: user_uid,
        email,
      },
    },
    (err, res, body) => {
      if (err) {
        return console.log(err);
      }
      console.log('createUser response', body);
      onUpdateAuthUser(body.user);
      history.push(ROUTES.APPLY);
    },
  ));
function SignUpForm({
  firebase, company, onUpdateAuthUser,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const defaultEmail = query.get('email');

  const schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address').required('This field is required'),
    passwordOne: yup
      .string()
      .required('This field is required')
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/, 'Must Contain at least 8 characters, one letter, and one number'),
    passwordTwo: yup
      .string()
      .required('This field is required')
      .oneOf([yup.ref('passwordOne'), null], 'Passwords must match'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    // console.log('Submit signupform', data);
    setIsLoading(true);
    data.email = data.email.trim().toLowerCase();

    firebase
      .doCreateUserWithEmailAndPassword(data.email, data.passwordOne)
      .then((authUser) => {
        identifyUser(firebase, authUser.user.uid);
        trackUserEvent(firebase, authUser.user.uid, 'user_membership_apply_started');

        return doMembershipSignUp(firebase, history, onUpdateAuthUser, authUser.user.uid, data.email);
      })
      .catch((error) => {
        if (error.code in ERROR_CODES) {
          error.message = ERROR_CODES[error.code];
        }
        // console.log('error on signupform', error);
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="email" className="form-text mt-2">
          Email address
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          {!defaultEmail ? (
            <input id="email" name="email" ref={register} type="email" autoComplete="email" className="form-input" />
          ) : (
            <input
              id="email"
              name="email"
              ref={register}
              type="email"
              autoComplete="email"
              className="form-input opacity-50"
              defaultValue={defaultEmail}
              readOnly
            />
          )}
          <p className="form-error">{errors.email?.message}</p>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="email" className="form-text">
          Create new password
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="passwordOne" name="passwordOne" ref={register} type="password" className="form-input" />
          <p className="form-error">{errors.passwordOne?.message}</p>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="email" className="form-text">
          Confirm password
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="passwordTwo" name="passwordTwo" ref={register} type="password" className="form-input" />
          <p className="form-error">{errors.passwordTwo?.message}</p>
        </div>
      </div>

      <div>
        <button type="submit" className="btn w-full mb-2">
          {isLoading && <LoaderIcon />}
          {!company ? 'Get Started' : 'Create Account'}
        </button>

        {!formState.isSubmitting && formState.isSubmitted && (
          <FlashMessage duration={30000} styleName="error">
            {errorMessage}
          </FlashMessage>
        )}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(SignUpPage);

export { doMembershipSignUp };

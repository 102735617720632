import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Logo from 'assets/wise-light-transparent.png';

const Error403Page = () => (
  <section className="py-12 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <Link to={ROUTES.HOME}>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="WISE" />
        </Link>

        <div className="mt-10 max-w-3xl mx-auto text-center text-md leading-6 text-gray-900">
          <p>Unfortunately the page you were looking for could not be found.</p>
          <p>It may be temporarily unavailable, moved or no longer exist.</p>

          <Link
            to={ROUTES.HOME}
            className="mt-6 mb-6 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-light hover:bg-primary-dark"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            Back to Home
          </Link>

          <p>
            Got questions? Send us an email at{' '}
            <a className="underline" href={`mailto:${ROUTES.EMAIL_ADDR_SUPPORT}`}>
              {ROUTES.EMAIL_ADDR_SUPPORT}
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Error403Page;

import React from 'react';

import UserProfileForm from 'pages/Account/UserProfileForm';
import PasswordChangeForm from 'pages/Account/PasswordChange';
import EmailChangeForm from 'pages/Account/EmailChange';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

const AccountPage = () => (
  <div className="px-4 sm:px-6 lg:px-8">

    <BreadcrumbsItem to={ROUTES.ACCOUNT} name="Account" showSeparator />

    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 divide-y divide-gray-200">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 xl:col-span-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">

          <UserProfileForm />

          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Account Settings</h3>
            </div>
            <EmailChangeForm />
            <PasswordChangeForm />
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default AccountPage;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { SignOutButton } from 'components/SignOut';
import useComponentVisible from 'utils/Dropdown';
import Breadcrumbs from 'components/Breadcrumb';
import MemberProductTour from 'components/MemberTour';
import { hasAdminRole, isPaidIndividual } from 'components/Session/withAuthorization';
import { compose } from 'recompose';
import history from 'components/History';
import * as ROUTES from 'constants/routes';
import * as USER from 'models/User';
import { withFirebase } from './Firebase';
import AdminProductTour from './AdminTour';

const TopNav = ({
  authUser, isMobileSidebarOn, toggleMobileSidebar, firebase, onUpdateAuthUser,
}) => (authUser ? (
  <TopNavAuth
    authUser={authUser}
    isMobileSidebarOn={isMobileSidebarOn}
    toggleMobileSidebar={toggleMobileSidebar}
    firebase={firebase}
    onUpdateAuthUser={onUpdateAuthUser}
  />
) : null);

const TopNavAuth = ({
  authUser, isMobileSidebarOn, toggleMobileSidebar, firebase, onUpdateAuthUser,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleSidebar = () => {
    toggleMobileSidebar(!isMobileSidebarOn);
  };

  // console.log('RENDERTOPNAV', authUser.completed_product_tour, authUser);
  const [isTourOpen, setTourOpen] = useState(!authUser.completed_product_tour && !hasAdminRole(authUser));
  const [isAdminTourOpen, setAdminTourOpen] = useState(
    !authUser.completed_admin_product_tour && hasAdminRole(authUser),
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [conditionalStep, setConditionalStep] = useState(0);

  useEffect(() => {
    const savedStep = JSON.parse(localStorage.getItem('savedStep'));
    if (hasAdminRole(authUser)) {
      if (history.location.pathname === ROUTES.COMPANY_MANAGE_TEAM && currentStep === 0 && savedStep === 4) {
        setConditionalStep(4);
      }

      if (currentStep === 3) {
        toggleMobileSidebar(false);
      }

      if (currentStep === 4) {
        toggleMobileSidebar(true);
        history.push(ROUTES.COMPANY_MANAGE_TEAM);
      }

      if (currentStep === 5) {
        toggleMobileSidebar(true);
        history.push(ROUTES.COMPANY_MANAGE_PROFILE);
      }

      if (currentStep === 6) {
        toggleMobileSidebar(false);
      }

      if (history.location.pathname === ROUTES.COMPANY_MANAGE_PROFILE && currentStep === 0) {
        setConditionalStep(5);
      }
    } else if (isPaidIndividual(authUser)) {
      if (history.location.pathname === ROUTES.PODS && currentStep === 0) {
        setConditionalStep(7);
      }
      if (currentStep === 7) {
        history.push(ROUTES.PODS);
      }
    } else {
      // if (history.location.pathname === ROUTES.REFERRAL && currentStep === 0) {
      //   setConditionalStep(5);
      // }

      // if (currentStep === 5) {
      //   history.push(ROUTES.REFERRAL);
      // }
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleTour = () => {
    if (hasAdminRole(authUser)) {
      history.push(ROUTES.COMPANY_MANAGE_TEAM);
      setAdminTourOpen(true);
      onUpdateAuthUser({ completed_admin_product_tour: false });
    } else {
      history.push(ROUTES.MEMBER_WELCOME);
      onUpdateAuthUser({ completed_product_tour: false });
      setTourOpen(true);
    }
    localStorage.removeItem('savedStep');
    toggleMobileSidebar(true);
  };

  return (
    <>
      <div className="relative flex-shrink-0 flex h-16 bg-white shadow-sm">
        {hasAdminRole(authUser) && (
          <AdminProductTour
            isTourOpen={isAdminTourOpen}
            setTourOpen={setAdminTourOpen}
            conditionalStep={conditionalStep}
            setStep={setCurrentStep}
          />
        )}
        {!hasAdminRole(authUser) && (
          <MemberProductTour
            isTourOpen={isTourOpen}
            setTourOpen={setTourOpen}
            conditionalStep={conditionalStep}
            setStep={setCurrentStep}
          />
        )}

        <button
          onClick={toggleSidebar}
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </button>
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex px-4 sm:px-6 lg:px-8">
            <Breadcrumbs
              container={(props) => <ol className="hidden md:flex items-center space-x-4">{props.children}</ol>}
              item={(props) => <li key={props.name}>{props.children}</li>}
            />
          </div>

          <div className="ml-4 flex items-center md:ml-6 space-x-2">
            <button
              onClick={() => {
                toggleTour();
              }}
              className="group flex items-center bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </button>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_ZENDESK}
              className="group flex items-center bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </a>

            <div ref={ref} className="ml-3 relative">
              <div>
                <button
                  onClick={setIsComponentVisible}
                  className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                  id="user-menu"
                  aria-haspopup="true"
                  data-tut="reactour__account"
                >
                  {authUser.photo_url ? (
                    <img className="h-8 w-8 rounded-full" src={authUser.photo_url} alt="" />
                  ) : (
                    <div className="h-8 w-8 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-gray-700 h-full w-full"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  )}

                  <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                    <span className="sr-only">Open user menu for </span>
                    {authUser.first_name}
                    {' '}
                    {authUser.last_name}
                  </span>
                  <svg
                    className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              {isComponentVisible && (
                // <Transition
                //   show={isComponentVisible}
                //   enter="transition ease-out duration-100"
                //   enterFrom="transform opacity-0 scale-95"
                //   enterTo="transform opacity-100 scale-100"
                //   leave="transition ease-out duration-100"
                //   leaveFrom="transform opacity-100 scale-100"
                //   leaveTo="transform opacity-0 scale-95"
                // >
                <div
                  className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <a
                    href={ROUTES.EXTERNAL_PAGE_COMMUNITY_STANDARD}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Community Standards
                  </a>
                  <Link
                    to={ROUTES.ACCOUNT}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Account
                  </Link>
                  {authUser.membership_subtype === USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_PAID && (
                    <Link
                      to={ROUTES.BILLING}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Billing
                    </Link>
                  )}
                  {authUser.membership_subtype === USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_FREE && (
                    <Link
                      to={ROUTES.PRICING}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Upgrade membership
                    </Link>
                  )}

                  {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && authUser.company_licenses
                    && authUser.company_licenses.includes(USER.ROLE_COMPANY_ADMIN) && (
                      <Link
                        to={ROUTES.BILLING}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Billing
                      </Link>
                  )}
                  <SignOutButton />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(TopNav);

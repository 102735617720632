import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

const AdminResourcesPage = () => {
  return (
    <div className='px-4 md:px-6 lg:px-8'>
      <div className='grid grid-cols-10 mb-8'>
        <div className='col-span-10 lg:col-span-9 xl:col-span-8 gap-y-6'>
          <BreadcrumbsItem to={ROUTES.COMPANY_RESOURCES} name='Resources' showSeparator={true} />

          <div className='col-span-2'>
            <div className='space-y-3 text-sm text-gray-600'>
              <div>
                As a Company Admin at a Partner Company, our goal is to enable you with everything you need in one place.
              </div>
              <div>
                Below you’ll find our partnership toolkits and other resources. Please download them, share them
                with your fellow Company Admins, and reference them as often as needed to take full advantage of your partnership
                benefits!
              </div>
            </div>

            <ul className='mt-3 text-sm list-disc text-gray-600 pl-8 space-y-4'>
              <li>
                <span className='font-bold'>Partner Toolkits</span>
                <span>
                  {' '}
                  - Your #1 resource for understanding and utilizing all of your partnership benefits, including marketing templates,
                  WISE logos, badge information, and much more. Be sure to use the correct one based on your partnership level!
                </span>
                <ul className='mt-3 text-sm list-disc text-gray-600 pl-8 space-y-2'>
                  <li>
                    <a href={ROUTES.EXTERNAL_PAGE_CORPORATE_TOOLKIT}
                       className='text-primary font-semibold underline' target='_blank' rel='noopener noreferrer'>
                      Corporate Partners Toolkit
                    </a>
                  </li>
                  <li>
                    <a href={ROUTES.EXTERNAL_PAGE_TEAM_TOOLKIT}
                       className='text-primary font-semibold underline' target='_blank' rel='noopener noreferrer'>
                      Team Partners Toolkit
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span className='font-bold'>
                  <a href={ROUTES.EXTERNAL_PAGE_COMPANY_BENEFITS}
                     className='text-primary font-bold underline' target='_blank' rel='noopener noreferrer'>
                    Membership Benefits One-Pager
                  </a>
                </span>
                <span>
                  {' '}
                  - Also linked in your partnership toolkit, this is what you can share with women internally to get them aware of and
                  interested in WISE membership.
                </span>
              </li>
              <li>
                <span className='font-bold'>Badge Guidelines & Installation Kit</span>
                <span>
                  {' '}
                  - Displaying  our partner badge is a key way for you to strengthen your employer brand by showcasing your support of
                  WISE, and you’ll find all the instructions you need to in the kit for your respective partnership level.
                </span>
                <ul className='mt-3 text-sm list-disc text-gray-600 pl-8 space-y-2'>
                  <li>
                    <a href={ROUTES.EXTERNAL_PAGE_CORPORATE_BADGE_KIT}
                       className='text-primary font-semibold underline' target='_blank' rel='noopener noreferrer'>
                      Corporate Partners
                    </a>
                  </li>
                  <li>
                    <a href={ROUTES.EXTERNAL_PAGE_TEAM_BADGE_KIT}
                       className='text-primary font-semibold underline' target='_blank' rel='noopener noreferrer'>
                      Team Partners
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(AdminResourcesPage);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { ToastContainer } from 'react-toastify';

import { withFirebase } from 'components/Firebase';
import { withAuthorization } from 'components/Session';
import { BreadcrumbsItem } from 'components/Breadcrumb';
import * as ROUTES from 'constants/routes';

import CompanyBasicInformationForm from './BasicInformationForm';
import TestimonialSection from './TestimonialSection';
import CompanyImagesSection from './CompanyImagesSection';
import ArticleSection from './ArticleSection';

const ManageCompanyPage = ({ firebase }) => {
  const company = useSelector((state) => state.companyState.company);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncDataCompany = async () => {
      const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
      dispatch({ type: 'SET_COMPANY', company: companyData });
    };
    asyncDataCompany();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <BreadcrumbsItem to={ROUTES.COMPANY_MANAGE_PROFILE} name="Manage Company" showSeparator />

      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 divide-y divide-gray-200">
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 xl:col-span-8 divide-y divide-gray-200">
          <div className="divide-y divide-gray-200">
            <CompanyBasicInformationForm company={company} />
            <TestimonialSection />
            <CompanyImagesSection />
            <ArticleSection />
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="fixed top-20 right-5"
      />
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(ManageCompanyPage);

import React from 'react';
//IMPORTANT: We must import React here. App will compile without it but db will not function without in prod

export const refreshCompanyData = async ({ firebase, authUser, setCompanyUsers, setCompany }) => {
  const company = await firebase.CompanyCollection.findById(authUser.company_id);
  setCompany(company);

  const companyUsers = await firebase.companyUsers(authUser.company_id);
  setCompanyUsers(companyUsers);
};

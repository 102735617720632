import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { IsScreenMobile } from 'utils/Widgets';
import { ACCOUNT_TYPE_CORPORATE, ACCOUNT_TYPE_TEAM } from 'models/Company';

import Testimonial from './Testimonial';
import ReactTooltip from "react-tooltip";

function TestimonialSection() {
  const company = useSelector((state) => state.companyState.company);

  const [showForm, setShowForm] = useState(false);
  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';
  const [usedCount, setUsedCount] = useState(company.testimonials_used_count);
  const [maxCount, setMaxCount] = useState(company.testimonials_max_count);

  useEffect(() => {
    if (!company.testimonials_used_count) {
      setUsedCount(0);
    } else { setUsedCount(company.testimonials_used_count); }

    if (!company.testimonials_max_count) {
      if (company.account_type === ACCOUNT_TYPE_TEAM) {
        setMaxCount(1);
      } else if (company.account_type === ACCOUNT_TYPE_CORPORATE) {
        setMaxCount(3);
      }
    }
  }, [company]);

  return (
    <div className="pt-6">

      <div id="testimonials" className="grid grid-cols-12">
        <h3 className="col-span-5 text-lg leading-6 font-medium text-gray-900" data-tut={`${tourClassPrefix}__testimonials`}>
          Testimonials
        </h3>
      </div>
      {company.account_type === ACCOUNT_TYPE_TEAM && (
        <p className="mt-3 text-sm text-gray-500 italic">
          Showcase your team's support of WISE, diversity, and empowering women in sales with testimonials.
          We recommend choosing an executive or leader on your team for this!
        </p>
      )}
      {company.account_type === ACCOUNT_TYPE_CORPORATE && (
        <p className="mt-3 text-sm text-gray-500 italic">
          This is your opportunity to showcase your team's support of WISE, diversity, and empowering women in sales with testimonials.
          We recommend including a mix of leaders/executives and individual contributors.
        </p>
      )}

      <button
        disabled={usedCount >= maxCount}
        type="submit"
        className="mt-3 w-40 btn mb-6"
        onClick={() => {
          setShowForm(!showForm);
        }}
      >
        <div
          data-tip="You are using the maximum of testimonials allowed"
          data-for="addTestimonial"
        >
          Add Testimonial
        </div>
      </button>
      {usedCount >= maxCount && (
        <ReactTooltip
          id="addTestimonial"
          place="top"
          backgroundColor="gray"
          effect="solid"
          multiline
        />
      )}
        {showForm && (
          <Testimonial setShowForm={setShowForm} />
        )}

        {company.testimonials && (company.testimonials.map((testimonial) => (<Testimonial key={testimonial.id} testimonial={testimonial} />
        )))}

    </div>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(TestimonialSection);

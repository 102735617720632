import React, { useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import * as ROUTES from 'constants/routes';
import * as COMPANY from 'models/Company';
import { withFirebase } from 'components/Firebase';
import Logo from 'assets/wise-light-transparent.png';
import FlashMessage from 'components/FlashMessage';
import { LoaderIcon } from 'utils/Widgets';
import { syncCompany } from 'utils/Segment';
import { compose } from 'recompose';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CompanyApplicationPage = ({ history, firebase }) => {
  let companyPlan; let
    planId;
  const query = useQuery();

  if (history.location.pathname.includes('/corporate/join')) {
    companyPlan = COMPANY.ACCOUNT_TYPE_CORPORATE;
    planId = 'WISE-Partner-Sponsorship-Corporate-Plan-USD-Yearly';
  } else {
    companyPlan = COMPANY.ACCOUNT_TYPE_TEAM;
    planId = 'WISE-Partner-Sponsorship-Team-Plan-USD-Yearly';
  }

  // Allow url params to override current plan
  if (query.get('plan')) {
    planId = query.get('plan');
  }

  sessionStorage.setItem('chargebee_plan_id', planId);

  return (
    <div className=" bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg px-4 sm:px-0">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="WISE" />
        {companyPlan === COMPANY.ACCOUNT_TYPE_TEAM && (
          <>
            <h3 className="mt-6 text-center text-xl font-extrabold text-gray-900">
              Thank you for your interest in being a WISE Team Partner.
            </h3>
            <p className="mt-8 text-md text-gray-500 leading-7">
              We are a global community building the next generation of female sales leaders by giving individuals and
              companies the knowledge and network they need to empower women in the sales profession to have fulfilling
              careers. The application process is short and simple, so please tell us more about yourself and your company
              below to get started.
            </p>
          </>
        )}

        {companyPlan === COMPANY.ACCOUNT_TYPE_CORPORATE && (
          <>
            <h3 className="mt-6 text-center text-xl font-extrabold text-gray-900">
              We are thrilled to have you join WISE as a Corporate Partner!
            </h3>
            <p className="mt-8 text-md text-gray-500 leading-7">
              WISE is a global community building the next generation of female sales leaders by giving individuals
              and companies the knowledge and network they need to empower women in the sales profession to have
              fulfilling careers.
            </p>
            <p className="mt-2 text-md text-gray-500 leading-7">
              After you fill out the information below and submit your payment, the WISE Team will finish setting up your
              account on the backend and be in touch soon to coordinate an onboarding call.
              {' '}
            </p>
          </>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="mt-6 grid grid-cols-1 gap-3">
            <CompanySignUpFormBase firebase={firebase} history={history} companyPlan={companyPlan} />
          </div>

          <div className="mt-2 text-xs text-gray-700">
            By using WISE you are agreeing to our
            {' '}
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_PRIVACY}
            >
              privacy policy
            </a>
            {' '}
            and
            {' '}
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_TERMS}
            >
              terms
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

function CompanySignUpFormBase({ firebase, history, companyPlan }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const headcountOptions = [
    { size: '1-49' },
    { size: '50-99' },
    { size: '100-499' },
    { size: '500-1000' },
    { size: '+1000' },
  ];
  const informationOptions = [
    { label: 'Online search' },
    { label: 'Social media' },
    { label: 'Personal referral' },
    { label: 'From a member of the WISE Team' },
    { label: 'Formative Search Partners (fka CloserIQ)' },
    { label: 'WISE Event' },
    { label: 'WISE Job Board' },
    { label: 'Other' },
  ];

  const companyEmailReg = /^([\w.+-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/;

  const schema = yup.object().shape({
    fullName: yup.string().trim().required('This field is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('This field is required')
      .matches(companyEmailReg, 'Please enter your work email address.'),
    companyName: yup.string().trim().required('This field is required'),
    jobTitle: yup.string().trim().required('This field is required'),
    companyUrl: yup.string().trim().required('This field is required'),
    totalHeadcount: yup.string().required('This field is required'),
    location: yup.string().trim().required('This field is required'),
    howDidYouHear: yup.string().required('This field is required'),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    const email = data.email.trim().toLowerCase();
    const first_name = data.fullName.split(' ').slice(0, -1).join(' ');
    const last_name = data.fullName.split(' ').slice(-1).join(' ');

    if (!data.companyUrl.startsWith('http')) {
      data.companyUrl = `https://${data.companyUrl}`;
    }

    const companyData = {
      company_name: data.companyName,
      applicant_name: data.fullName,
      applicant_firstname: first_name,
      applicant_lastname: last_name,
      applicant_email: email,
      applicant_title: data.jobTitle,
      company_url: data.companyUrl,
      total_headcount: data.totalHeadcount,
      location: data.location,
      how_did_you_hear: data.howDidYouHear,
      signup_time: firebase.currentTime(),
      account_status: COMPANY.ACCOUNT_STATUS_INCOMPLETE,
      account_type: COMPANY.ACCOUNT_TYPE_TEAM,
      admin_license_used_count: 1, // Set to 1 for the current user
      membership_license_used_count: 0,
      images_used_count: 0,
      testimonials_used_count: 0,
      articles_used_count: 0,
    };
    const checkoutUrl = ROUTES.COMPANY_CHECKOUT.replace(':companyPlan', companyPlan.toLowerCase());

    if (companyPlan === COMPANY.ACCOUNT_TYPE_CORPORATE) {
      companyData.account_type = COMPANY.ACCOUNT_TYPE_CORPORATE;
      companyData.admin_license_max_count = COMPANY.DEFAULT_CORPORATE_ADMIN_LICENSE_COUNT;
      companyData.membership_license_max_count = COMPANY.DEFAULT_CORPORATE_MEMBER_LICENSE_COUNT;
      companyData.images_max_count = COMPANY.DEFAULT_CORPORATE_IMAGES_COUNT;
      companyData.testimonials_max_count = COMPANY.DEFAULT_CORPORATE_TESTIMONIALS_COUNT;
      companyData.articles_max_count = COMPANY.DEFAULT_CORPORATE_ARTICLES_COUNT;
    } else {
      companyData.account_type = COMPANY.ACCOUNT_TYPE_TEAM;
      companyData.admin_license_max_count = COMPANY.DEFAULT_TEAM_ADMIN_LICENSE_COUNT;
      companyData.membership_license_max_count = COMPANY.DEFAULT_TEAM_MEMBER_LICENSE_COUNT;
      companyData.images_max_count = COMPANY.DEFAULT_TEAM_IMAGES_COUNT;
      companyData.testimonials_max_count = COMPANY.DEFAULT_TEAM_TESTIMONIALS_COUNT;
      companyData.articles_max_count = COMPANY.DEFAULT_TEAM_ARTICLES_COUNT;
    }

    firebase
      .getOrCreateCompany(companyData)
      .then((company) => {
        if (company.account_status === COMPANY.ACCOUNT_STATUS_SUBMITTED) {
          setErrorMessage('You have already applied recently. The WISE team will review and get back to you shortly.');
        } else if (company.account_status === COMPANY.ACCOUNT_STATUS_ACTIVE) {
          setErrorMessage('You already have an active WISE sponsorship. Please login to access your account.');
        } else if (company.account_status === COMPANY.ACCOUNT_STATUS_APP_DENIED) {
          setErrorMessage('You have already applied recently. The WISE team will review and get back to you shortly.');
        } else {
          syncCompany(company.id, 'company_sponsorship_apply_started', companyData);
          sessionStorage.setItem('company_id', company.id);
          sessionStorage.setItem('company_email', companyData.applicant_email);

          // set id to pass it to the checkout screen
          companyData.id = company.id;
          sessionStorage.setItem('companyAppData', JSON.stringify(companyData));
          history.push(checkoutUrl);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="fullName" className="form-text mt-2">
          What is your full name?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="fullName" name="fullName" ref={register} type="name" autoComplete="name" className="form-input" />
          <p className="form-error">{errors.fullName?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="email" className="form-text mt-2">
          What is your work email?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="email" name="email" ref={register} type="email" autoComplete="email" className="form-input" />
          <p className="form-error">{errors.email?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="companyName" className="form-text mt-2">
          What is your company name?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="companyName" name="companyName" ref={register} className="form-input" />
          <p className="form-error">{errors.companyName?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="jobTitle" className="form-text mt-2">
          What is your current job title?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="jobTitle" name="jobTitle" ref={register} className="form-input" />
          <p className="form-error">{errors.jobTitle?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="companyUrl" className="form-text mt-2">
          Please provide your company website.
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="companyUrl" name="companyUrl" ref={register} className="form-input" />
          <p className="form-error">{errors.companyUrl?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="totalHeadcount" className="form-text mt-2">
          What is the total headcount at your company?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select id="totalHeadcount" name="totalHeadcount" ref={register} className="form-input">
            <option hidden value="">
              Please select the total headcount
            </option>
            {headcountOptions.map((option) => (
              <option value={option.size} key={option.size}>
                {option.size}
              </option>
            ))}
          </select>
          <p className="form-error">{errors.totalHeadcount?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="location" className="form-text mt-2">
          Which state/city is your company headquartered in?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input id="location" name="location" ref={register} className="form-input" />
          <p className="form-error">{errors.location?.message}</p>
        </div>
      </div>

      <div className="sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="howDidYouHear" className="form-text mt-2">
          How did you hear about WISE?
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select id="howDidYouHear" name="howDidYouHear" ref={register} className="form-input">
            <option hidden value="">
              Please select an option
            </option>
            {informationOptions.map((option) => (
              <option value={option.label} key={option.label}>
                {option.label}
              </option>
            ))}
          </select>
          <p className="form-error">{errors.howDidYouHear?.message}</p>
        </div>
      </div>

      <div>
        <button type="submit" className="btn w-full mb-2">
          {isLoading && <LoaderIcon />}
          Continue
        </button>

        {!formState.isSubmitting && formState.isSubmitted && (
          <FlashMessage duration={30000} styleName="error">
            {errorMessage}
          </FlashMessage>
        )}
      </div>
    </form>
  );
}

export default compose(withRouter, withFirebase)(CompanyApplicationPage);

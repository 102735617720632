import { combineReducers } from 'redux';
import sessionReducer from './session';
import eventReducer from './event';
import companyReducer from './company';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  eventState: eventReducer,
  companyState: companyReducer,
});

export default rootReducer;

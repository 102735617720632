import React from 'react';
import Moment from 'react-moment';
import { withFirebase } from 'components/Firebase';

import * as USER from 'models/User';
import * as COMPANY from 'models/Company';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const LegacyBillingSection = ({ authUser, company }) => (
  <div>
    <div className="pb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Membership</h3>
      {authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL
        && (
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          You're currently on the annual WISE membership. Please contact your support@womeninsaleseverywhere.com if you need to make
          any changes to your membership.
        </p>
        )}
      {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER
        && (
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          You're currently on your company's partner membership. Please contact your company Admin if you need to make
          any changes to your membership.
        </p>
        )}
    </div>
    <div className="border-t border-gray-200 py-5">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Membership Type</dt>
          <dd className="mt-1 text-sm text-gray-900">

            {authUser.membership_type === USER.MEMBERSHIP_TYPE_INDIVIDUAL
              && (<span>Individual</span>)}
            {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && company.account_type === COMPANY.ACCOUNT_TYPE_TEAM
              && (<span>Team</span>)}
            {authUser.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && company.account_type === COMPANY.ACCOUNT_TYPE_CORPORATE
              && (<span>Corporate</span>)}

          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Membership Start Date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {authUser.membership_startdate && (
              <Moment format="MMM D, YYYY" unix>
                {authUser.membership_startdate.seconds}
              </Moment>
            )}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Membership End Date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {authUser.membership_enddate && (
              <Moment format="MMM D, YYYY" unix>
                {authUser.membership_enddate.seconds}
              </Moment>
            )}
          </dd>
        </div>
      </dl>
    </div>
  </div>

);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  company: state.companyState.company,
});

export default compose(connect(mapStateToProps), withFirebase)(LegacyBillingSection);

import React from 'react';

const BenefitsSection = () => (
  <div className="px-4 sm:px-8 lg:w-5/12 xl:px-24 2xl:pl-44 2xl:pr-24">
    <div className="max-w-lg mx-auto lg:mx-0">
      <h2 className="text-base font-semibold tracking-wide text-white uppercase">JOIN WISE</h2>
      <p className="mt-2 text-2xl font-extrabold text-white sm:text-3xl">MEMBERSHIP BENEFITS</p>
      <dl className="mt-12 space-y-6 lg:space-y-10">
        <div className="flex object-left">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left object-left">
            <dt className="text-lg leading-6 font-medium text-white">Peer-To-Peer Networking</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Unlimited access to WISE Pods, an easy way to network with other members.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Mentorship Matching</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Get paired with a mentor or mentee in our community based on your goals.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Slack Group</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Ask questions, share knowledge, and cultivate relationships with other members.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Live Events</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Attend member-only events and enjoy free admission to all public WISE events.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Job Board</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Explore and apply for open roles at our partner companies.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              aria-hidden="true"
              viewBox="0 0 640 512"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Expert Advice</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              From curated resources to our dedicated advice email, our goal is to equip you at every step of your
              career journey.
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
);

export default BenefitsSection;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import { toast } from 'react-toastify';

function RequirementsSection({ firebase }) {
  const company = useSelector((state) => state.companyState.company);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const dispatch = useDispatch();

  const requirements = [
    { label: 'Company name', condition: () => company.company_name !== null, href: '#company_name' },
    { label: 'Company website URL', condition: () => company.company_url, href: '#company_url' },
    { label: 'Careers page URL', condition: () => company.career_url, href: '#career_url' },
    { label: 'Short company description', condition: () => company.short_description, href: '#short_description' },
    { label: 'Long company description', condition: () => company.long_description, href: '#long_description' },
    { label: 'At least 1 testimonial', condition: () => company.testimonials?.length >= 1, href: '#testimonials' },
    { label: 'Company logo', condition: () => company.square_logo, href: '#square_logo' },
    { label: 'At least 4 company images', condition: () => company.images?.length >= 4, href: '#images' },
    { label: 'At least 1 featured article', condition: () => company.articles?.length >= 1, href: '#articles' },
  ];

  const [companyProgress, setCompanyProgress] = useState(company.profile_progress ? company.profile_progress : 0);
  const [completedProgress, setCompleteProgress] = useState(false);
  let completedTasks = 0;

  useEffect(() => {
    if (company.profile_progress < 100 || !company.profile_progress) {
      requirements.map((requirement) => {
        if (requirement.condition()) {
          completedTasks += 100 / requirements.length;
          setCompanyProgress(Math.round(completedTasks));
        }
      });

      firebase.updateCompany(authUser.company_id, {
        profile_progress: completedTasks.toFixed(2),
      }).then(async () => {
        const companyData = await firebase.CompanyCollection.findById(authUser.company_id);
        dispatch({ type: 'SET_COMPANY', company: companyData });
      });

      if (companyProgress >= 100) {
        setCompleteProgress(true);
      }
    }
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (completedProgress) {
      toast.success('Now you can go to your spotlight page', {
        autoClose: 6000,
        className: 'text-sm',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        delay: 500,
      });
    }
  }, [completedProgress]);

  return (
    <>
      {company.profile_progress < 100 && (
      <div className="pb-6">

        <div className="grid grid-cols-1 gap-5">
          <div
            className="relative bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2"
          >
            <div className="grid-cols-1">
              <div className="absolute bg-indigo-500 rounded-md p-3">
                <div className="h-6 w-6 text-white" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </div>
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Progress:</p>
              <div className="ml-16 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {companyProgress}
                  %
                </p>
              </div>

              <p className="text-md font-medium text-black my-2">Complete your company information:</p>
              {requirements.map((requirement) => (
                <div key={requirement.label} className="relative flex items-start my-0.5">
                  <div className="flex items-center h-5">
                    <input
                      id="requirements"
                      name="requirements"
                      type="checkbox"
                      readOnly
                      disabled
                      checked={requirement.condition()}
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <a href={requirement.href} className="font-medium text-gray-700 hover:underline">
                      {requirement.label}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
      )}
    </>

  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(RequirementsSection);

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from 'components/Firebase';
import * as USER from 'models/User';
import { refreshCompanyData } from 'components/RefreshCompanyData';
import TeamTableActions from './TeamTableActions';

const userLicenses = (user) => (
  <div>
    {user.membership_type === USER.MEMBERSHIP_TYPE_PARTNER && user.company_licenses
      && user.company_licenses.sort().map((membership) => (
        <div
          className="inline-flex items-center px-2.5 py-0.5 mr-0.5 sm:mb-0.5 md:0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
          key={membership}
        >
          {membership}
        </div>
      ))}
  </div>
);

function UserStatus({ status }) {
  const elementClass = {
    [USER.MEMBERSHIP_STATUS_ACTIVE]: 'bg-green-100 text-green-800',
  }[status] || 'bg-gray-100 text-gray-800';

  const statusDisplay = {
    [USER.MEMBERSHIP_STATUS_INVITED]: 'Invited',
    [USER.MEMBERSHIP_STATUS_APP_INCOMPLETE]: 'Invited',
    [USER.MEMBERSHIP_STATUS_APP_SUBMITTED]: 'Invited',
    [USER.MEMBERSHIP_STATUS_ACTIVE]: 'Active',
    [USER.MEMBERSHIP_STATUS_APP_DENIED]: 'Denied',
    [USER.MEMBERSHIP_STATUS_ENDED]: 'Inactive',
    [USER.MEMBERSHIP_STATUS_EXISTING_MEMBER]: 'Invited',
  }[status];

  return <span className={`px-2 inline-flex text-xs leading-5 rounded-full ${elementClass}`}>{statusDisplay}</span>;
}

class TeamTable extends Component {
  constructor(props) {
    super(props);
    this.getCompanyUsers = this.getCompanyUsers.bind(this);
    this.state = {
      showInactiveUsers: false,
    };
  }

  componentDidMount() {
    this.getCompanyUsers();
  }

  async getCompanyUsers() {
    if (!this.props.authUser.company_id) {
      return;
    }
    await refreshCompanyData(this.props);
  }

  getFilteredUsers() {
    const membershipStatus = this.state.showInactiveUsers
      ? [USER.MEMBERSHIP_STATUS_APP_DENIED, USER.MEMBERSHIP_STATUS_ENDED]
      : [USER.MEMBERSHIP_STATUS_INVITED, USER.MEMBERSHIP_STATUS_APP_SUBMITTED, USER.MEMBERSHIP_STATUS_ACTIVE];
    return this.props.companyUsers.filter((user) => membershipStatus.includes(user.membership_status));
  }

  render() {
    return (
      <div className="mt-4">
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 uppercase">
                  User
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-xs font-medium text-gray-500 xl:table-cell uppercase"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-xs font-medium text-gray-500 lg:table-cell uppercase"
                >
                  Membership type
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-medium text-gray-500 lg:table-cell uppercase">
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {this.getFilteredUsers().map((user) => (
                <tr key={user.email}>
                  <td className="w-full max-w-0 py-4 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none pl-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        {user.photo_url ? (
                          <img className="h-10 w-10 rounded-full" src={user.photo_url} alt="" />
                        ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-10 w-10 rounded-full text-gray-600"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clipRule="evenodd"
                              />
                            </svg>
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {user.first_name}
                          {' '}
                          {user.last_name}
                        </div>
                        <div className="text-sm text-gray-500 xl:hidden truncate">{user.current_title}</div>
                        <div className="text-sm text-gray-500 truncate">{user.email}</div>
                        <div className="text-sm lg:hidden text-gray-500 truncate">{userLicenses(user)}</div>
                        <div className="flex lg:hidden mt-2">
                          <UserStatus status={user.membership_status} />
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell">{user.current_title}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell xl:table-cell">{userLicenses(user)}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell xl:table-cell"><UserStatus status={user.membership_status} /></td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a className="text-indigo-600 hover:text-indigo-900">
                      <TeamTableActions user={user} onUserChanged={() => this.getCompanyUsers()} />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  companyUsers: state.companyState.companyUsers,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyUsers: (companyUsers) => dispatch({
    type: 'SET_COMPANY_USERS',
    companyUsers,
  }),
  setCompany: (company) => dispatch({
    type: 'SET_COMPANY',
    company,
  }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(TeamTable);

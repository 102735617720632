import * as EVENT from 'models/Event';

const isUserRegistered = (event, user) => (event.registration && user.uid in event.registration && event.registration[user.uid].status === 'registered');

const isBeforeEvent = (event) => (event.event_start_time && new Date() < event.event_start_time.seconds * 1000);

function eventStatusIsPreSignup(event) {
  return EVENT.EVENT_STATUS_PRESIGNUP === event.status;
}

function eventStatusIsSignupOpen(event) {
  return EVENT.EVENT_STATUS_SIGNUP_OPEN === event.status;
}

function eventStatusIsSignupClosed(event) {
  return [EVENT.EVENT_STATUS_SIGNUP_CLOSED, EVENT.EVENT_STATUS_COMPLETED, EVENT.EVENT_STATUS_CANCELLED].includes(event.status);
}

function eventIsPublished(event) {
  return [EVENT.EVENT_STATUS_PRESIGNUP, EVENT.EVENT_STATUS_SIGNUP_OPEN, EVENT.EVENT_STATUS_SIGNUP_CLOSED].includes(event.status);
}

function eventIsEnded(event) {
  return [EVENT.EVENT_STATUS_COMPLETED, EVENT.EVENT_STATUS_CANCELLED, EVENT.EVENT_STATUS_SPLIT].includes(event.status);
}

function eventIsFull(event) {
  return event.users_registered.length >= event.max_signups;
}

export {
  isUserRegistered, isBeforeEvent, eventIsPublished, eventIsEnded, eventStatusIsPreSignup,
  eventStatusIsSignupOpen, eventStatusIsSignupClosed, eventIsFull,
};

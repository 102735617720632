import React from 'react';

const CompanyBenefitsSection = () => (
  <div className="px-4 sm:px-8 lg:w-5/12 xl:px-24 2xl:pl-44 2xl:pr-24">
    <div className="max-w-lg mx-auto lg:mx-0">
      <h2 className="text-base font-semibold tracking-wide text-white uppercase">PARTNER WITH WISE</h2>
      <p className="mt-2 text-2xl font-extrabold text-white sm:text-3xl">PARTNERSHIP BENEFITS</p>
      <dl className="mt-12 space-y-6 lg:space-y-10">
        <div className="flex object-left">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left object-left">
            <dt className="text-lg leading-6 font-medium text-white">Memberships</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Support the professional development of the female sellers already on your team.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Company Spotlight Page</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Create a custom profile showcasing your company's culture, leadership, and open roles.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Partner Badge</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Validate your company's dedication to diversity by showcasing the badge across your careers page, Glassdoor, and more.
            </dd>
          </div>
        </div>

        <div className="flex">
          <div className="flex-shrink-0 h-6 w-6 lg:h-12 lg:w-12 flex items-center justify-center bg-indigo-500 rounded-md">
            <svg
              className="h-4 w-4 lg:h-6 lg:w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
              />
            </svg>
          </div>
          <div className="ml-4 text-left">
            <dt className="text-lg leading-6 font-medium text-white">Job Board</dt>
            <dd className="2xl:mt-2 text-base text-gray-300">
              Feature open sales roles across all locations on our publicly available job board.
            </dd>
          </div>
        </div>

      </dl>
    </div>
  </div>
);

export default CompanyBenefitsSection;

const request = require('request');
const moment = require('moment');

export function identifyUser(firebase, userId, userdata) {
  //segment seems to be blocked sometimes, do it on client and server side.
  if (firebase.auth.currentUser) {

    //must call it locally for page() to work
    window.analytics.identify(userId);

    //call it again from server side in case it's blocked on client
    firebase.auth.currentUser.getIdToken().then(function(token) {
      console.log("send segment user profile", userId);
      request({
          url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/processIdentify`,
          method: 'POST',
          json: true,
          headers: { 'Authorization': `Bearer ${token}` },
          body: {
            user_id: userId,
            timezone: moment.tz.guess(),
            userdata: userdata,
          },
        },
        (err, res, body) => {
          if (err) { return console.log(err); }
        });
    });
  }
}

export function trackUserEvent(firebase, userId, event, properties) {

  firebase.auth.currentUser.getIdToken().then(function(token) {
    console.log("send segment user event", userId, event);
    request({
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/processTrack`,
        method: 'POST',
        json: true,
        headers: { 'Authorization': `Bearer ${token}` },
        body: {
          user_id: userId,
          event: event,
          properties: properties,
        },
      },
      (err, res, body) => {
        if (err) { return console.log(err); }
      });
  });
}

export function syncCompany(companyId, event, companyData) {
  console.log("sync company profile", companyId);
  request({
      url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/public/processSyncCompany`,
      method: 'POST',
      json: true,
      body: {
        company_id: companyId,
        event: event,
        companydata: companyData,
      },
    },
    (err, res, body) => {
      if (err) { return console.log(err); }
    });
}

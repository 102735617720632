import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { PasswordForgetLink } from 'pages/PasswordForget';
import { withFirebase } from 'components/Firebase';
import { doMembershipSignUp } from 'pages/SignUp';
import * as ROUTES from 'constants/routes';
import * as USER from 'models/User';
import Logo from 'assets/wise-light-transparent.png';
import GoogleLogo from 'assets/logo-social-google.png';
import { LoaderIcon } from 'utils/Widgets';
import { identifyUser, trackUserEvent } from 'utils/Segment';

const SignInPage = ({ history, onUpdateAuthUser }) => {
  const userAlreadyExists = history.location.state?.userAlreadyExists;
  const userEmail = history.location.state?.email;

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {userAlreadyExists && (
          <div className="bg-blue-500 text-white text-sm font-bold px-4 py-3 mb-8" role="alert">
            You already have an account with WISE associated with {userEmail}. Please login below to access your account
            or reset your password
            <Link to={ROUTES.PASSWORD_FORGET}>
              {' '}
              <u>here.</u>
            </Link>
          </div>
        )}
        <a href={ROUTES.EXTERNAL_PAGE_HOME}>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="WISE" />
        </a>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Log in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Not a member yet?&nbsp;
          <Link to={ROUTES.SIGN_UP} className="font-medium text-primary hover:underline">
            Apply here!
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="grid grid-cols-1 gap-3">
            <SignInGoogle onUpdateAuthUser={onUpdateAuthUser} />
          </div>

          <div className="mt-6">
            <div className="relative mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or</span>
              </div>
            </div>

            <SignInForm onUpdateAuthUser={onUpdateAuthUser} />
          </div>

          <div className="mt-4 text-xs text-gray-700">
            By using WISE you are agreeing to our{' '}
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_PRIVACY}
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              className="hover:underline italic"
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.EXTERNAL_PAGE_TERMS}
            >
              terms
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const ERROR_CODE_ACCOUNT_NOT_FOUND = 'auth/user-not-found';
const ERROR_MSG_ACCOUNT_NOT_FOUND = 'The email you’ve entered doesn’t match any account.';
const ERROR_CODE_ACCOUNT_WRONG_PWD = 'auth/wrong-password';
const ERROR_MSG_ACCOUNT_WRONG_PWD = 'The password you’ve entered is incorrect.';
const ERROR_MSG_AUTH_ERROR_DEFAULT =
  "Incorrect email or password. Please contact support you're having trouble accessing your account.";

function SignInFormBase({ firebase, history, onUpdateAuthUser }) {
  const defaultEmail = history.location.state?.email;
  const userAlreadyExists = history.location.state?.userAlreadyExists;

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address').required('This field is required'),
    password: yup.string().required('This field is required'),
    remember_me: yup.bool(),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log('Submit SignInFormBase', data);
    setIsLoading(true);
    setErrorMessage('');
    data.email = data.email.trim().toLowerCase();

    firebase
      .doSignInWithEmailAndPassword(data.email, data.password, data.remember_me)
      .then((userCredential) => {
        if (userAlreadyExists) {
          const authUser = userCredential;
          identifyUser(firebase, authUser.user.uid);
          trackUserEvent(firebase, authUser.user.uid, 'user_membership_apply_started');

          doMembershipSignUp(firebase, history, onUpdateAuthUser, authUser.user.uid, data.email);
        } else {
          history.push(ROUTES.HOME);
        }
      })
      .catch((error) => {
        console.log(error);
        switch (error.code) {
          case ERROR_CODE_ACCOUNT_NOT_FOUND:
            error.message = ERROR_MSG_ACCOUNT_NOT_FOUND;
            break;
          case ERROR_CODE_ACCOUNT_WRONG_PWD:
            error.message = ERROR_MSG_ACCOUNT_WRONG_PWD;
            break;
          default:
            error.message = ERROR_MSG_AUTH_ERROR_DEFAULT;
        }

        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email Address
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            ref={register}
            type="email"
            autoComplete="email"
            required
            className={
              'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-light focus:border-primary-light sm:text-sm' +
              (defaultEmail && ' opacity-50')
            }
            defaultValue={defaultEmail}
            readOnly={defaultEmail ? true : false}
          />
        </div>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            ref={register}
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-light focus:border-primary-light sm:text-sm"
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            ref={register}
            id="remember_me"
            name="remember_me"
            type="checkbox"
            className="h-4 w-4 text-primary focus:ring-primary-light border-gray-300 rounded"
          />
          <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div>

        <div className="text-sm">
          <PasswordForgetLink />
        </div>
      </div>

      <div>
        <button type="submit" className="w-full btn">
          {isLoading && <LoaderIcon />}
          Log in
        </button>
        {!formState.isSubmitting && formState.isSubmitted && (
          <p className="mt-2 text-sm text-red-700">{errorMessage}</p>
        )}
      </div>
    </form>
  );
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onSubmit = (event) => {
    const defaultEmail = this.props.history.location.state?.email;
    const userAlreadyExists = this.props.history.location.state?.userAlreadyExists;

    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        const user = this.props.firebase.user(socialAuthUser.user.uid);
        user.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            //update login time if user exists
            user.set(
              {
                last_login_time: this.props.firebase.currentTime(),
              },
              { merge: true }
            );

            if (userAlreadyExists && socialAuthUser.user.email === defaultEmail) {
              identifyUser(this.props.firebase, socialAuthUser.user.uid);
              trackUserEvent(this.props.firebase, socialAuthUser.user.uid, 'user_membership_apply_started');

              user.set(
                {
                  email: socialAuthUser.user.email,
                  first_login_time: this.props.firebase.currentTime(),
                  last_login_time: this.props.firebase.currentTime(),
                  membership_type: USER.MEMBERSHIP_TYPE_PARTNER,
                  membership_subtype: USER.MEMBERSHIP_SUBTYPE_PARTNER_PAID,
                },
                { merge: true }
              );

              doMembershipSignUp(
                this.props.firebase,
                this.props.history,
                this.props.onUpdateAuthUser,
                socialAuthUser.user.uid,
                socialAuthUser.user.email
              );
            }
          } else {
            //update all if user doesn't exists
            user.set(
              {
                email: socialAuthUser.user.email,
                first_login_time: this.props.firebase.currentTime(),
                last_login_time: this.props.firebase.currentTime(),
                membership_status: USER.MEMBERSHIP_STATUS_APP_INCOMPLETE,
                membership_type: USER.MEMBERSHIP_TYPE_INDIVIDUAL,
                membership_subtype: USER.MEMBERSHIP_SUBTYPE_INDIVIDUAL_FREE,
              },
              { merge: true }
            );
            // this.props.firebase.createNewUser(socialAuthUser.user.uid, socialAuthUser.user.email);
            identifyUser(this.props.firebase, socialAuthUser.user.uid);
            trackUserEvent(this.props.firebase, socialAuthUser.user.uid, 'user_membership_apply_started');
          }
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <img className="h-5 w-5 mr-2" src={GoogleLogo} alt="Log in with Google" />
          {this.props.label ? this.props.label : 'Log in with google'}
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(SignInPage);

export { SignInForm, SignInGoogle };

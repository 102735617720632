import React from 'react';
import Tour from 'reactour';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from 'components/Firebase';
import { IsScreenMobile } from 'utils/Widgets';
import { isFreemiumIndividual } from './Session';

const MemberProductTour = ({
  authUser,
  onUpdateAuthUser,
  firebase,
  isTourOpen,
  setTourOpen,
  setStep,
  conditionalStep,
}) => {
  const accentColor = '#002266';

  const closeTour = () => {
    if (!authUser.completed_product_tour) {
      firebase.updateUser({ uid: authUser.uid, completed_product_tour: true });
      onUpdateAuthUser({ completed_product_tour: true });
    }
    setTourOpen(false);
    localStorage.removeItem('savedStep');
  };

  return (
    <div>
      <Tour
        onRequestClose={closeTour}
        steps={getTourConfig(authUser)}
        isOpen={isTourOpen}
        className="helper"
        rounded={5}
        accentColor={accentColor}
        showCloseButton={false}
        closeWithMask={false}
        showNavigationNumber
        lastStepNextButton={<span className="btn">Done</span>}
        getCurrentStep={setStep}
        goToStep={conditionalStep}
        disableKeyboardNavigation={['esc']}
      />
    </div>
  );
};

const getTourConfig = (authUser) => {
  // support desktop vs mobile versions of the sidebar
  const tourClassPrefix = IsScreenMobile() ? 'reactour_m' : 'reactour';

  const paid_tour = [
    {
      position: 'center',
      content: () => (
        <div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Fevent_product_tour_01.jpeg?alt=media&token=b6c68a24-2a56-4537-a290-1ebe68216c4a"
              alt="WISE Product Tour"
            />
          </div>
          <div className="mt-4 mb-4 text-md">
            Welcome to WISE! You've joined a special group of women from around the world to help support you and your
            career.
          </div>
          <div>
            Please continue for a brief 30 second tour of the WISE Portal so you can get the most out of your membership
            benefits!
          </div>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__pods"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900 place-self-center">Networking</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Sign up for sessions to network with your peers. You can register for, cancel and track all your sessions
            here.
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__mentorship"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Mentorship</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Fill out a simple form to receive a curated match with a mentor or mentee based on what you're looking for!
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__slack"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Slack Group</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Learn about the channels in the Slack group and how to use each of them - be sure to introduce yourself in
            the "welcome-intro" channel ASAP!
          </p>
        </div>
      ),
    },

    {
      selector: `[data-tut="${tourClassPrefix}__events"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Events</p>
          </div>
          <p className="mt-8 text-md leading-6">
            RSVP for upcoming WISE events - you can count on at least a couple each month!
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__jobboard"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Job Board</p>
          </div>
          <p className="mt-8 text-md leading-6">Browse current open sales roles at all WISE partner companies.</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__account"]',
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Profile Settings</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Instantly review your membership details, change your password, update your bio, review our Community
            Standards, and more!
          </p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__upcomingPods"]',
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Start Networking</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Check out upcoming Workshops and sign up for a future Pod cohort!
          </p>
        </div>
      ),
      mutationObservables: ['[data-tut="reactour__upcomingPods"]'],
    },
  ];

  const freemium_tour = [
    {
      position: 'center',
      content: () => (
        <div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Fevent_product_tour_01.jpeg?alt=media&token=b6c68a24-2a56-4537-a290-1ebe68216c4a"
              alt="WISE Product Tour"
            />
          </div>
          <div className="mt-4 mb-4 text-md">
            Welcome to WISE! You've joined a special group of women from around the world to help support you and your
            career.
          </div>
          <div>
            Please continue for a brief 30 second tour of the WISE Portal so you can get the most out of your membership
            benefits!
          </div>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__slack"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Slack Group</p>
          </div>

          <p className="mt-8 text-md leading-6">
            Learn about the channels in the Slack group and how to use each of them - be sure to introduce yourself in
            the "welcome-intro" channel ASAP!
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__events"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Events</p>
          </div>
          <p className="mt-8 text-md leading-6">
            RSVP for upcoming WISE events - you can count on at least a couple each month!
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__jobboard"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Job Board</p>
          </div>
          <p className="mt-8 text-md leading-6">Browse current open sales roles at all WISE partner companies.</p>
        </div>
      ),
    },
    {
      selector: `[data-tut="${tourClassPrefix}__paid"]`,
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900 place-self-center" />
          </div>
          <p className="mt-8 text-md leading-6">Upgrade membership to access this benefit!</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__account"]',
      content: () => (
        <div className="relative">
          <div className="flex items-center mt-2">
            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Profile Settings</p>
          </div>
          <p className="mt-8 text-md leading-6">
            Instantly review your membership details, change your password, update your bio, review our Community
            Standards, and more!
          </p>
        </div>
      ),
    },
  ];

  if (isFreemiumIndividual(authUser)) {
    return freemium_tour;
  }
  return paid_tour;
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthUser: (user) => dispatch({ type: 'UPDATE_AUTH_USER', user }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFirebase)(MemberProductTour);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Sentry from '@sentry/react';

import { withFirebase } from 'components/Firebase';
import { identifyUser } from 'utils/Segment';

const moment = require('moment');

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    // constructor(props) {
    //   super(props);
    //   //This may contain outdated user data and will cause issues such as showing Product Tour twice
    //   // this.props.onSetAuthUser(JSON.parse(localStorage.getItem('authUser')));
    // }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        async (authUser) => {
          identifyUser(this.props.firebase, authUser.uid);
          Sentry.setUser({ email: authUser.email });
          // localStorage.setItem('authUser', JSON.stringify(authUser));
          this.props.onSetAuthUser(authUser);

          if (authUser.company_id) {
            const company = await this.props.firebase.CompanyCollection.findById(authUser.company_id);
            const companyUsers = await this.props.firebase.companyUsers(authUser.company_id);

            this.props.setCompany(company);
            this.props.setCompanyUsers(companyUsers);
          }

          //update last_login time if more than 1d
          //initially this will be set by create function so only update if there's a past timestamp
          //otherwise this may create user record in firebase before the createUser function
          if (authUser.last_login_time && authUser.last_login_time.seconds < moment().add(-1, 'day').unix()) {
            this.props.firebase.user(authUser.uid).set(
              {
                last_login_time: this.props.firebase.currentTime(),
              },
              { merge: true }
            );
          }
        },
        () => {
          localStorage.clear();
          this.props.onSetAuthUser(null);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser }),
    setCompany: (company) => dispatch({ type: 'SET_COMPANY', company }),
    setCompanyUsers: (companyUsers) => dispatch({ type: 'SET_COMPANY_USERS', companyUsers }),
  });

  return compose(withFirebase, connect(null, mapDispatchToProps))(WithAuthentication);
};

export default withAuthentication;

import React from 'react';

const TestimonialQuote = (testimonial) => (
  <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
    <div className="relative text-lg font-medium text-white md:flex-grow">
      <svg
        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600"
        fill="currentColor"
        viewBox="0 0 32 32"
      >
        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
      </svg>
      <p className="relative">
        {testimonial.quote}
      </p>
    </div>
    <footer className="mt-6">
      <div className="flex">
        <div className="h-12 w-12 flex-shrink-0 inline-flex rounded-full border-2 border-white">
          <img
            className="h-12 w-12 rounded-full"
            src={testimonial.profileUrl}
            alt=""
          />
        </div>
        <div className="ml-4 text-left">
          <div className="text-base font-medium text-white">{testimonial.author}</div>
          <div className="text-base font-medium text-indigo-200">{testimonial.title}</div>
        </div>
      </div>
    </footer>
  </blockquote>
);

export const TestimonialSalesloft = () => {
  const data = {
    quote: 'The WISE team has been able to build genuine relationships with women in sales at all levels -- from individual contributors at startups to VPs at Fortune 500 companies. Because of their vertical focus on sales and commitment to creating spaces that foster empowerment and mentorship, they’ve cultivated an incredibly engaged and enthusiastic community that we are proud to partner with!',
    author: 'Salesloft',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_logo_salesloft.png?alt=media&token=7aa5aa22-503b-4d9a-b027-77bc106db252',
    title: 'WISE Partner since 2019',
  };

  return TestimonialQuote(data);
};

// export const TestimonialBox = () => {
//   const data = {
//     quote: 'Our partnership with WISE has enabled us to showcase and support our female sales leaders with speaking opportunities, helped us strengthen our brand as a destination employer for women, and allowed us to support the female sellers at Box with WISE\'s membership benefits.',
//     author: 'BOX',
//     profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_logo_box.png?alt=media&token=51cdc452-25f8-469d-9b23-49e303f1e9e8',
//     title: 'WISE Partner since 2020',
//   };
//
//   return TestimonialQuote(data);
// };

export const TestimonialRapid7 = () => {
  const data = {
    quote: 'Over the past two years or so of our partnership, many of the women on our sales team have directly benefited from the programming WISE offers, whether it be our managers finding long-lasting mentors, our individual contributors learning different sales skills from peers in the community, or having both newer and experienced leaders speak on panels.',
    author: 'Rapid7',
    profileUrl: 'https://firebasestorage.googleapis.com/v0/b/wise-prod-566a3.appspot.com/o/assets%2Ftestimonial_logo_rapid7.png?alt=media&token=14ea1eac-3aee-4d6d-ae95-ed646bbaa10b',
    title: 'WISE Partner since 2020',
  };

  return TestimonialQuote(data);
};

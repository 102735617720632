import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';

import * as EVENT from 'models/Event';
import { eventIsFull } from 'utils/Event';

function Event({ event }) {
  const renderStatus = (status) => {
    switch (status) {
      case EVENT.EVENT_STATUS_PRESIGNUP:
        return 'Open';
      case EVENT.EVENT_STATUS_SIGNUP_OPEN:
        return 'Open';
      case EVENT.EVENT_STATUS_SIGNUP_CLOSED:
        return 'Sign Up Closed';
      default:
        return 'Ended';
    }
  };

  return (
    <a className="group" href={`/pods/${event.id}`}>
      <div key={event.id} className="w-full lg:flex">
        <div className="h-48 h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l overflow-hidden">
          <img className="w-full h-full object-cover object-center" src={event.image_url} alt="wise banner" />
        </div>

        <div className="border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 w-full justify-between leading-normal group-hover:bg-gray-100">
          <div className="mb-4 space-y-2">
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              <Moment format="ddd, MMM D, YYYY, h:mm A zz" unix>
                {event.event_start_time.seconds}
              </Moment>
            </div>

            <div className="block font-bold text-xl leading-tight font-medium text-black">{event.title}</div>

            <div>
              {[EVENT.EVENT_STATUS_PRESIGNUP, EVENT.EVENT_STATUS_SIGNUP_OPEN].includes(event.status) &&
                !eventIsFull(event) && (
                  <>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 bg-indigo-100 text-indigo-800">
                      <FontAwesomeIcon className="mr-2" icon="calendar" />
                      {renderStatus(event.status)}
                    </span>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                      <FontAwesomeIcon className="mr-2" icon="users" />
                      {event.min_group_size} - {event.max_group_size}
                    </span>
                  </>
                )}

              {[EVENT.EVENT_STATUS_PRESIGNUP, EVENT.EVENT_STATUS_SIGNUP_OPEN].includes(event.status) &&
                eventIsFull(event) && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 bg-red-50 text-red-700">
                    <FontAwesomeIcon className="mr-2" icon="calendar" />
                    Full
                  </span>
                )}

              {[EVENT.EVENT_STATUS_SIGNUP_CLOSED, EVENT.EVENT_STATUS_COMPLETED].includes(event.status) && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 bg-red-50 text-red-700">
                  <FontAwesomeIcon className="mr-2" icon="calendar" />
                  {renderStatus(event.status)}
                </span>
              )}
            </div>

            {/* <p className="text-grey-darker text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.</p> */}
          </div>
        </div>
      </div>
    </a>
  );
}

export default Event;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { withFirebase } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import Logo from 'assets/wise-light-transparent.png';
import { LoaderIcon } from 'utils/Widgets';

const PasswordForgetPage = () => (
  <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Link to={ROUTES.HOME}>
        <img className="mx-auto h-12 w-auto" src={Logo} alt="WISE" />
      </Link>
    </div>

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <PasswordForgetForm />

        <div className="mt-4 text-sm text-gray-700">
          Have your password?{' '}
          <Link className="underline" to={ROUTES.SIGN_IN}>
            Log in
          </Link>
        </div>
      </div>
    </div>
  </div>
);

function PasswordForgetFormBase({ firebase, history }) {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address').required('This field is required'),
  });

  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log('Submit passwordforgetform', data);
    setIsLoading(true);

    firebase
      .doPasswordReset(data.email)
      .then(() => {
        //show message
        setErrorMessage('');
        setSuccessMessage('You will receive an email shortly with instructions to reset your password.');
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log('error on passwordforgetform', error);
        setSuccessMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-8">
      <div className="col-span-1 sm:col-span-2">
        <label htmlFor="email" className="form-text">
          Email address
        </label>
        <input type="text" name="email" ref={register} className="form-input" />
        {errors.email?.message && <p className="form-error">{errors.email?.message}</p>}
      </div>

      <div className="col-span-1 sm:col-span-2">
        {!formState.isSubmitting && formState.isSubmitted && <div className="form-success">{successMessage}</div>}

        {!formState.isSubmitting && formState.isSubmitted && <div className="form-error">{errorMessage}</div>}

        <button type="submit" className="btn w-full">
          {isLoading && <LoaderIcon />}
          Continue
        </button>
      </div>
    </form>
  );
}

const PasswordForgetLink = () => (
  <p>
    <Link className="font-medium text-primary hover:text-primary-dark" to={ROUTES.PASSWORD_FORGET}>
      Forgot Password?
    </Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };

import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import CurrencyFormat from 'react-currency-format';

import * as ROUTES from 'constants/routes';
import * as COMPANY from 'models/Company';

import CBCheckoutButton from 'components/Checkout';
import history from 'components/History';
import { useParams } from 'react-router-dom';
import { TestimonialSalesloft, TestimonialRapid7 } from './CompanyTestimonials';
import request from 'request';
import { LoaderIcon } from 'utils/Widgets';


const Testimonials = () => (
  <div>
    <div className=''>
      <TestimonialSalesloft />
    </div>

    <div className='hidden lg:block py-8'>
      <TestimonialRapid7 />
    </div>
  </div>
);

const RenderPlanPeriod = (plan) => {
  if (plan.period_unit === 'month' && plan.period === 3) {
    return 'Quarter';
  } else if (plan.period_unit === 'year' && plan.period === 1) {
    return 'Year';
  } else if (plan.period_unit === 'month' && plan.period === 1) {
    return 'Month';
  }
}

const AnnualPlan = () => {
  const planId = sessionStorage.getItem('chargebee_plan_id');
  const [plan, setPlan] = useState('');
  // const [planId, setPlanId] = useState('');
  const companyData = JSON.parse(sessionStorage.getItem('companyAppData'));
  const { companyPlan } = useParams();
  if (!companyData) {
    const route = ROUTES.COMPANY_TEAM_APPLY.replace(':companyPlan', companyPlan.toLowerCase());
    history.push(route);
  }

  useEffect(() => {
    if (!plan) {
      getPlan();
    }
  }, []);

  const getPlan = async () => {
    request({
        url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/api/public/getCBPlan`,
        method: 'POST',
        json: true,
        body: { plan_id: planId }
      },
      (err, res, body) => {
        if (err) {
          console.log(err);
        } else {
          console.log("loaded plan", body.item_price);
          setPlan(body.item_price);
        }
      });
  };

  const benefitsTeam = [
    {
      title: 'Memberships',
      description: 'Support the professional development of the female sellers already on your team.'
    },
    {
      title: 'Company Spotlight Page',
      description: 'Create a custom profile showcasing your company\'s culture, leadership, and open roles.'
    },
    {
      title: 'Partner Badge',
      description: 'Validate your company\'s dedication to diversity by showcasing the badge across your careers page, Glassdoor, and more.'
    },
    {
      title: 'Job Board',
      description: 'Feature open sales roles across all locations on our publicly available job board.'
    }
  ];
  const benefitsCorporate = [
    {
      title: 'Memberships',
      description: 'Support the professional development of the female sellers already on your team.'
    },
    {
      title: 'Company Spotlight Page',
      description: 'Create a custom profile showcasing your company\'s culture, leadership, and open roles.'
    },
    {
      title: 'Speaker slots at virtual events and fireside chats',
      description: 'Give your female sales leaders the opportunity to showcase their expertise and speak at one of our events.'
    },
    {
      title: 'Job Board',
      description: 'Feature open sales roles across all locations on our publicly available job board.'
    }
  ];
  let benefits;

  if (companyPlan === COMPANY.ACCOUNT_TYPE_CORPORATE.toLowerCase()) {
    // planId = 'WISE-Partner-Sponsorship-Corporate-Plan-USD-Yearly';
    benefits = benefitsCorporate;
  } else {
    // planId = 'WISE-Partner-Sponsorship-Team-Plan-USD-Yearly';
    benefits = benefitsTeam;
  }

  return (
    <div className='h-full'>
      {plan && (<div className='space-y-4 sm:space-y-0 sm:grid sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-1'>
        <div className='border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200'>
          <div className='p-6'>
            {companyPlan === COMPANY.ACCOUNT_TYPE_TEAM.toLowerCase() && (
              <h2 className='text-lg leading-6 font-medium text-gray-900'>WISE Team Plan</h2>
              )}
            {companyPlan === COMPANY.ACCOUNT_TYPE_CORPORATE.toLowerCase() && (
              <h2 className='text-lg leading-6 font-medium text-gray-900'>WISE Corporate Plan</h2>
            )}
            <p className='mt-4 text-sm text-gray-500' />
            {plan && (<p className='mt-8'>
              <span className='text-4xl font-extrabold text-gray-900'>
                <CurrencyFormat value={plan.price / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              </span>
              <span className='text-base font-medium text-gray-500'>&nbsp;/&nbsp;{RenderPlanPeriod(plan)}</span>
            </p>)}
          </div>
          {/* )} */}
          <div className='pt-6 pb-8 px-6'>
            <h3 className='text-xs font-medium text-gray-900 tracking-wide uppercase'>Partnership benefits</h3>
            <ul className='mt-6 space-y-4'>
              {benefits.map((feature) => (
                <li key={feature.title} className='flex space-x-3'>
                  <CheckIcon className='flex-shrink-0 h-5 w-5 text-green-500' aria-hidden='true' />
                  <span className='text-sm text-gray-500'>
                    <b>
                      {feature.title}
                      :
                    </b>
                    {' '}
                    {feature.description}
                  </span>
                </li>
              ))}
            </ul>

            <CBCheckoutButton planId={planId} company={companyData} companyPlan={companyPlan} />
          </div>
        </div>
      </div>)}

      {!plan && (
        <div className='flex justify-center items-center mt-8'>
        <LoaderIcon dark />
        </div>
      )}
    </div>
  );
};

const CompanyCheckoutPage = () => {
  return (
    <div className='relative'>
      <div className='w-full lg:absolute lg:inset-0 lg:min-h-screen mx-auto'>
        <div
          className='h-full lg:inset-y-0 lg:left-0 lg:w-5/12 bg-primary px-4 sm:px-8 xl:px-24 2xl:pl-44 2xl:pr-24 pt-16 pb-8 sm:pt-8 sm:pb-16 md:pt-8 md:pb-16 lg:pt-16 lg:pb-8 xl:pt-16 xl:pb-8'
        >
          <Testimonials />
        </div>
      </div>
      <div
        className='relative h-full pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'
      >
        <div className='lg:col-start-2'>
          <div className='max-w-xl mx-auto lg:max-w-lg lg:ml-auto'>
            <AnnualPlan />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCheckoutPage;

export const HOME = '/';
export const SIGN_IN = '/login';
export const SIGN_UP = '/signup';
export const SIGN_OUT = '/logout';

export const ONBOARDING = '/account_setup';
export const APPLY = '/join/apply';
export const PRICING = '/join/plans';
export const CHECKOUT = '/join/checkout/:interval/:interval_count';
export const CHECKOUT_COMPLETE = '/join/completed';
export const UPGRADE_COMPLETE = '/join/upgraded';
export const MEMBERSHIP_DENIED = '/join/status';

export const MEMBER_WELCOME = '/welcome';
export const REFERRAL = '/referral';
export const ACCOUNT = '/account';
export const MENTORSHIP = '/mentorship';
export const SLACK = '/slack';
export const PASSWORD_FORGET = '/reset_password';
export const PODS = '/pods';
export const BILLING = '/billing';
export const POD_DETAILS = '/pods/:id';
export const RESOURCES = '/resources';

// Company signup and features
export const COMPANY_CORPORATE_JOIN = '/corporate/join';
export const COMPANY_TEAM_APPLY = '/team/apply';
export const COMPANY_CHECKOUT = '/:companyPlan/checkout';
export const COMPANY_CHECKOUT_COMPLETE = '/:companyPlan/checkout/completed';
export const COMPANY_MANAGE_TEAM = '/company/team';
export const COMPANY_MANAGE_PROFILE = '/company/edit';
export const COMPANY_PUBLIC_PAGE = '/companies/:publicPageId';
export const COMPANY_RESOURCES = '/company/resources';

export const ADMIN_ONLY_PAGES = [COMPANY_MANAGE_TEAM, COMPANY_MANAGE_PROFILE];
export const MEMBER_ONLY_PAGES = [PODS];
export const PAID_MEMBER_ONLY_PAGES = [MENTORSHIP, PODS];

export const EXTERNAL_PAGE_EVENT = 'https://womeninsaleseverywhere.com/events/';
export const EXTERNAL_PAGE_HOME = 'https://womeninsaleseverywhere.com/';
export const EXTERNAL_PAGE_SLACK = 'https://wiseforum.slack.com';
export const EXTERNAL_PAGE_SLACK_INVITE = 'https://joinwise.co/slack';
export const EXTERNAL_PAGE_JOBS = 'http://jobs.womeninsaleseverywhere.com/';
export const EXTERNAL_PAGE_ZENDESK = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us';
export const EXTERNAL_PAGE_MENTOR_TYPEFORM = 'https://form.typeform.com/to/eGM84Fdw';
export const EXTERNAL_PAGE_TERMS = 'https://womeninsaleseverywhere.com/terms';
export const EXTERNAL_PAGE_PRIVACY = 'https://womeninsaleseverywhere.com/privacy';
export const EXTERNAL_PAGE_COMMUNITY_STANDARD = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us/categories/360005284651-Community-Standards';
export const EXTERNAL_PAGE_COHORT_FAQ = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us/categories/360005290412-Cohorts-FAQ';
export const EXTERNAL_PAGE_REIMBURSEMENT_FAQ = 'https://docs.google.com/document/d/1CiZJlBV2U1hsWy5wCcH_cy5Zw47XgE6eUOA8kc41PjM/preview';
export const EXTERNAL_PAGE_MEMBERSHIP_FAQ = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us/categories/360003978472-Membership-FAQ';
export const EXTERNAL_PAGE_ZENDESK_ACTIVATE_MEMBERSHIP = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us/articles/4412387396756-How-do-I-activate-my-membership-if-my-company-is-a-WISE-sponsor-';
export const EXTERNAL_PAGE_ZENDESK_PARTNERSHIP_FAQ = 'https://womeninsaleseverywhere.zendesk.com/hc/en-us/categories/360004018891-Partnership-FAQ';

// company resources links
export const EXTERNAL_PAGE_CORPORATE_TOOLKIT = 'https://joinwise.co/corporate-toolkit';
export const EXTERNAL_PAGE_TEAM_TOOLKIT = 'https://joinwise.co/team-toolkit';
export const EXTERNAL_PAGE_COMPANY_BENEFITS = 'https://joinwise.co/partner-members';
export const EXTERNAL_PAGE_CORPORATE_BADGE_KIT = 'https://formativesp.docsend.com/view/qc83wf42vt2znq4t';
export const EXTERNAL_PAGE_TEAM_BADGE_KIT = 'https://formativesp.docsend.com/view/3vnbupdibafmvcs7';

// members resources links
export const EXTERNAL_PAGE_MEMBERSHIP_TOOLKIT = 'https://formativesp.docsend.com/view/ew6qbxdywb7vmu8s';
export const EXTERNAL_PAGE_MENTEE_INFO = 'https://drive.google.com/file/d/1nAdKZNPif0ASf3hn0s8G-E2tABkaL9yx/view';
export const EXTERNAL_PAGE_MENTOR_INFO = 'https://drive.google.com/file/d/1EWjgMxWvdEpRdPhPUUh6t-KcBzV8eoAG/view';
export const EXTERNAL_PAGE_MEMBERS_BENEFITS = 'https://drive.google.com/file/d/1fUaRHASHvbzcdsZs3Zpy3JbQ_kpWAZtq/view';
export const EXTERNAL_PAGE_GOODREADS = 'https://www.goodreads.com/user/show/149634708-wise-recommended-reads';
export const EXTERNAL_PAGE_SPOTIFY = 'https://open.spotify.com/playlist/6sV1FFLjRPAKufFh2vhkgx?si=86132ac4feb54449&nd=1';
export const EXTERNAL_PAGE_YOUTUBE = 'https://www.youtube.com/channel/UCqKLmVb7h0SNK4auhrgZUmw';
export const EXTERNAL_PAGE_WISE_BLOG = 'https://womeninsaleseverywhere.com/blog/';
export const EXTERNAL_PAGE_CIQ_BLOG = 'https://www.formativesp.com/insights';

export const EMAIL_ADDR_SUPPORT = 'support@womeninsaleseverywhere.com';
